export default {
    // set up global error handling/logging for application
    InitAxios(api) {
        // interceptor for error handling globally
        api.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser
                    // and an instance of http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
                throw error;
            }
        );
    },
    // InitVue(app) {
    //     app.config.errorHandler = (err, vm, info) => {
    //         // `info` is a Vue-specific error info, e.g. which lifecycle hook
    //         // the error was found in. Only available in 2.2.0+
    //         ErrorLog(`Vue Error at [${info}] > ${err}`);
    //     };
    // },
};
// // private function to log client errors to server
// function ErrorLog(msg) {
//     // new instance to avoid global error handler (since we're already here)
//     let instance = axios.create();
//     instance.post(`${this.$apiroot}log/error`, JSON.stringify(msg));
// }
