<template>
    <div v-if="props.data" class="w-full bg-aboggray-100 rounded-lg p-4">
        <div class="w-full px-2 mb-6">
            <div class="float-right text-xl text-abogred-600 font-bold">{{ display.SlideID }}</div>
            <!-- <label class="block uppercase text-xs font-bold">
                {{ formDefinition[spec][sectionId].title }}
            </label> -->
            <h1 class="font-head text-xl font-bold">{{ display.CategoryDescription }}</h1>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full px-2 mb-6 lg:w-2/12">
                <label class="block uppercase text-xs font-bold mb-2">
                    {{ daysInHospitalLabel }}
                </label>
                <span class="px-4">{{ display.DaysInHospital }}</span>
            </div>
            <div class="w-full px-2 mb-6 lg:w-1/12">
                <label class="block uppercase text-xs font-bold mb-2 whitespace-nowrap"> Age </label>
                <span class="px-4">{{ display.PatientAge }}</span>
            </div>
            <div v-if="(spec == 'OBGYN' && sectionId == 2) || spec == 'MFM'" class="w-full px-2 mb-6 lg:w-1/6">
                <label class="block uppercase text-xs font-bold mb-2" style="white-space: nowrap"> Gestational Age </label>
                <span class="px-4">{{ display.GestationalAgeText }}</span>
            </div>
            <div class="w-full px-2 mb-6 lg:w-1/12">
                <label class="block uppercase text-xs font-bold mb-2"> Gravida </label>
                <span class="px-4">{{ display.Gravida }}</span>
            </div>
            <div class="w-full px-2 mb-6 lg:w-1/12">
                <label class="block uppercase text-xs font-bold mb-2"> Para </label>
                <span class="px-4">{{ display.Para }}</span>
            </div>
            <div class="w-full px-2 mb-6 lg:w-1/6">
                <label class="block uppercase text-xs font-bold mb-2 whitespace-nowrap"> Collection TimeFrame </label>
                <span class="px-4 whitespace-nowrap">{{ casetimeframe }}</span>
            </div>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full px-2 mb-6 lg:w-1/2">
                <label class="block uppercase text-xs font-bold mb-2 relative">
                    {{ formDefinition[spec][sectionId][1].Label }}
                </label>
                <p class="px-4 whitespace-pre-wrap overflow-auto">{{ display[formDefinition[spec][sectionId][1].Field] }}</p>
            </div>
            <div class="w-full px-2 mb-6 lg:w-1/2">
                <label class="block uppercase text-xs font-bold mb-2 relative">
                    {{ formDefinition[spec][sectionId][2].Label }}
                </label>
                <p class="px-4 whitespace-pre-wrap overflow-auto">{{ display[formDefinition[spec][sectionId][2].Field] }}</p>
            </div>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full px-2 mb-6 lg:w-1/2">
                <label class="block uppercase text-xs font-bold mb-2 relative">
                    {{ formDefinition[spec][sectionId][3].Label }}
                </label>
                <p class="px-4 whitespace-pre-wrap overflow-auto">{{ display[formDefinition[spec][sectionId][3].Field] }}</p>
            </div>
            <div v-if="spec == 'OBGYN' && sectionId != 2" class="w-full px-2 mb-6 lg:w-1/2">
                <label class="block uppercase text-xs font-bold mb-2 relative">
                    {{ formDefinition[spec][sectionId][4].Label }}
                </label>
                <p class="px-4 whitespace-pre-wrap overflow-auto">{{ display[formDefinition[spec][sectionId][4].Field] }}</p>
            </div>
            <div v-else class="w-full px-2 mb-6 lg:w-1/2">
                <label class="block uppercase text-xs font-bold mb-2 relative"> Newborn Information </label>

                <table class="w-full text-center">
                    <tr>
                        <th></th>
                        <th v-if="spec == 'OBGYN'">Peri Death</th>
                        <th>Weight</th>
                        <th>Apgar 1</th>
                        <th>Apgar 5</th>
                        <th>Nights</th>
                        <th>Complications</th>
                    </tr>
                    <tr v-if="display.PeriDeath1 != ''">
                        <td>#1</td>
                        <td v-if="spec == 'OBGYN'">{{ display.PeriDeath1 }}</td>
                        <td>{{ display.NewbornWeight1 }}</td>
                        <td>{{ display.NewbornApgar11 }}</td>
                        <td>{{ display.NewbornApgar51 }}</td>
                        <td>{{ display.NewbornDays1 }}</td>
                        <td>{{ display.ComplicationsNewborn1 }}</td>
                    </tr>
                    <tr v-if="display.PeriDeath2 != ''">
                        <td>#2</td>
                        <td v-if="spec == 'OBGYN'">{{ display.PeriDeath2 }}</td>
                        <td>{{ display.NewbornWeight2 }}</td>
                        <td>{{ display.NewbornApgar12 }}</td>
                        <td>{{ display.NewbornApgar52 }}</td>
                        <td>{{ display.NewbornDays2 }}</td>
                        <td>{{ display.ComplicationsNewborn2 }}</td>
                    </tr>
                    <tr v-if="display.PeriDeath3 != ''">
                        <td>#3</td>
                        <td v-if="spec == 'OBGYN'">{{ display.PeriDeath3 }}</td>
                        <td>{{ display.NewbornWeight3 }}</td>
                        <td>{{ display.NewbornApgar13 }}</td>
                        <td>{{ display.NewbornApgar53 }}</td>
                        <td>{{ display.NewbornDays3 }}</td>
                        <td>{{ display.ComplicationsNewborn3 }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, computed } from "vue";
const props = defineProps(["data"]);
const display = computed(() => JSON.parse(props.data));
const spec = computed(() => display.value.Spec);
const sectionId = computed(() => display.value.C_Type);

const formDefinition = reactive({
    OBGYN: {
        1: {
            //Gynecological
            title: "Gynecological",
            1: { Header: "Preoperative Diagnosis", Field: "Text1", Label: "DIAGNOSIS PREOPERATIVE OR ADMISSION", Limit: "200" },
            2: { Header: "Treatment", Field: "Text2", Label: "TREATMENT", Limit: "300" },
            3: { Header: "Surgical Pathology", Field: "Text3", Label: "SURGICAL PATHOLOGY", Limit: "200" },
            4: { Header: "Complications", Field: "Text4", Label: "COMPLICATIONS (INCLUDE BLOOD TRANSFUSIONS)", Limit: "200" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: true, Para: true },
        },
        2: {
            //Obstetrical
            title: "Obstetrical",
            1: { Header: "Complications Antepartum", Field: "Text1", Label: "COMPLICATIONS ANTEPARTUM", Limit: "200" },
            2: { Header: "Complications Delivery/Postpartum", Field: "Text4", Label: "COMPLICATIONS DELIVERY/POSTPARTUM", Limit: "200" },
            3: { Header: "Treatment", Field: "Text2", Label: "OPERATIVE PROCEDURES / TREATMENT", Limit: "300" },
            4: { Header: "", Field: "", Label: "", Limit: "" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: true, Para: true },
        },
        3: {
            //Office Practice
            title: "Office Practice",
            1: { Header: "Problem", Field: "Text1", Label: "PROBLEM", Limit: "200" },
            2: { Header: "Diagnostics Procedures", Field: "Text3", Label: "DIAGNOSTICS PROCEDURES", Limit: "200" },
            3: { Header: "Treatment", Field: "Text2", Label: "TREATMENT", Limit: "300" },
            4: { Header: "Result", Field: "Text4", Label: "RESULT", Limit: "200" },
            validate: { Hospital: false, DaysInHospital: true, Gravida: true, Para: true },
        },
    },
    MFM: {
        1: {
            //Medical Complications of Pregnancy
            title: "Medical Complications of Pregnancy",
            1: { Header: "Complications Antepartum", Field: "Text1", Label: "COMPLICATIONS ANTEPARTUM", Limit: "200" },
            2: { Header: "Complications Delivery", Field: "Text2", Label: "COMPLICATIONS DELIVERY & PUERPERIUM", Limit: "200" },
            3: { Header: "Treatment", Field: "Text3", Label: "OPERATIVE PROCEDURES AND/OR TREATMENT", Limit: "300" },
            4: { Header: "", Field: "", Label: "", Limit: "" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: true, Para: true },
        },
        2: {
            //Obstetrical and Surgical Complications
            title: "Obstetrical and Surgical Complications",
            1: { Header: "Complications Antepartum", Field: "Text1", Label: "COMPLICATIONS ANTEPARTUM", Limit: "200" },
            2: { Header: "Complications Delivery", Field: "Text2", Label: "COMPLICATIONS DELIVERY & PUERPERIUM", Limit: "200" },
            3: { Header: "Treatment", Field: "Text3", Label: "OPERATIVE PROCEDURES AND/OR TREATMENT", Limit: "300" },
            4: { Header: "", Field: "", Label: "", Limit: "" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: true, Para: true },
        },
        3: {
            //Genetics/Fetal Disorders/Fetal Anomalies
            title: "Genetics/Fetal Disorders/Fetal Anomalies",
            1: { Header: "Complications Antepartum", Field: "Text1", Label: "COMPLICATIONS ANTEPARTUM", Limit: "200" },
            2: { Header: "Complications Delivery", Field: "Text2", Label: "COMPLICATIONS DELIVERY & PUERPERIUM", Limit: "200" },
            3: { Header: "Treatment", Field: "Text3", Label: "OPERATIVE PROCEDURES AND/OR TREATMENT", Limit: "300" },
            4: { Header: "", Field: "", Label: "", Limit: "" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: true, Para: true },
        },
    },
    NONE: {
        0: {},
    },
});

const daysInHospitalLabel = computed(() => {
    if ((spec.value == "OBGYN" && sectionId.value == 3) || (spec.value == "URPS" && sectionId.value == 2)) {
        return "Visits";
    } else {
        return "Nights In Hospital";
    }
});

const casetimeframe = computed(() => {
    // the casetimeframe could be added the the case JSON instead
    if (display.value.Spec == "OBGYN") {
        switch (display.value.CaseTimeframe) {
            case 1:
                return "12 Months";
            case 2:
                return "18 Months";
            case 3:
                return "Senior Resident";
            case 4:
                return "Fellowship";
            default:
                return "";
        }
    } else if (display.value.Spec == "ONC") {
        return display.value.CaseTimeframe == new Date().getFullYear() - 1 ? "12 Months" : "24 Months";
    } else {
        switch (display.value.CaseTimeframe) {
            case 1:
                return "12 Months";
            case 2:
                return "24 Months";
            case 3:
                return "18 Months";
            default:
                return "";
        }
    }
});
</script>
