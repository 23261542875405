<template>
    <div v-if="loading" class="text-center">
        <div class="text-2xl">Loading Grades</div>
        <AbogLoading class="w-40" />
    </div>
    <div v-else-if="loaderror" class="text-center p-4">
        <h3 class="text-lg font-bold">Error Loading Grades</h3>
        <p>Please reload to try again. If errors persist contact your exam administrator.</p>
    </div>
    <div v-else-if="grades.length > 0">
        <div class="px-4 relative text-right">
            <i class="fa cursor-pointer text-2xl" :class="collapsePanel ? 'fa-angles-left' : 'fa-angles-right'" @click="collapsePanel= !collapsePanel" />
        </div>
        <div :class="collapsePanel ? 'w-full':'md:relative'" >
            <div :class="collapsePanel ? 'hidden w-0':'w-full md:w-1/3 md:absolute p-4 md:right-0 md:top-0'" >
                <p class="mb-4">Please refer to the following description of grades</p>
                <p v-for="gradeKey in gradeKeys" :key="gradeKey.Score" class="mb-2">
                    <strong>{{ gradeKey.Description }}</strong>
                    <br />
                    <span v-html="gradeKey.DetailedNotes"></span>
                </p>
            </div>
            <GradeInput :grades="grades" :gradeKeys="gradeKeys" :editable="editable" @saveGrade="saveGrade" :class="collapsePanel ? 'w-full' :'w-full md:w-2/3'" />
        </div>
        <div class="m-12 flex items-center">
            <p v-if="submitted">Grades have already been submitted for this candidate</p>
            <p v-else-if="saved">Grades have been submitted successfully</p>
            <button v-else class="blue-button whitespace-nowrap" @click="lockGrades" :disabled="savingAny || failedAny">Submit Scores</button>
            <span v-if="savingAny" class="ml-4">Cannot submit while scores are saving... Please wait.</span>
            <span v-if="failedAny" class="ml-4">Error saving grades. Some scores require re-entry.</span>
        </div>
    </div>
    <div v-else>
        <h3 class="text-center p-4 text-lg font-bold">Grading is not available for this schedule.</h3>
    </div>
</template>
<script setup>
import { ref, onMounted, computed, inject } from "vue";
import AbogLoading from "@/pages/components/AbogLoading";
import GradeInput from "@/pages/components/GradeInput";
import AbogAlert from "@/pages/components/AbogAlert";
const axios = inject("axios");

const props = defineProps(["schedule"]);

const gradeKeys = ref([]);
const grades = ref([]);
const saved = ref(false);
const loading = ref(true);
const loaderror = ref(false);
const collapsePanel = ref(false);
const submitted = computed(() => props.schedule.GradesSubmittedOn !== null);
const editable = computed(() => !submitted.value && !saved.value);
const failedAny = computed(() => {
    return grades.value.some((g) => g.hasErrors);
});
const savingAny = computed(() => {
    return grades.value.some((g) => g.isSaving);
});

onMounted(() => {
    if (!props.schedule.CandidateID) {
        loading.value = false;
        return; // no candidate, no grades
    }
    loading.value = true;
    Promise.all([
        axios
            .get("grades/scale")
            .then((response) => {
                gradeKeys.value = response.data;
            })
            .catch(() => {
                console.log(`error - grades/scale`);
                loaderror.value = true;
            }),
        axios
            .get(`schedule/${props.schedule.ID}/grades`)
            .then((response) => {
                // sort by id, with nulls at bottom
                response.data.sort((a, b) => {
                    if (a.GradeCustomIndex > b.GradeCustomIndex) return 1;
                    if (a.GradeCustomIndex < b.GradeCustomIndex) return -1;
                    if (a.CaseDescription === "Case List" && b.CaseDescription !== "Case List") return 1;
                    if (a.CaseDescription !== "Case List" && b.CaseDescription === "Case List") return -1;
                    if (a.CaseDescription > b.CaseDescription) return 1;
                    if (a.CaseDescription < b.CaseDescription) return -1;
                    if (a.CaseQuestionID === null && b.CaseQuestionID !== null) return 1;
                    if (a.CaseQuestionID !== null && b.CaseQuestionID === null) return -1;
                    if (a.CaseQuestionIndex > b.CaseQuestionIndex) return 1;
                    if (a.CaseQuestionIndex < b.CaseQuestionIndex) return -1;
                    return 0;
                });
                // init UI flags
                let prevcase = null;
                let casecolor = true;
                response.data.forEach((g) => {
                    if (g.CaseDescription !== prevcase) {
                        casecolor = !casecolor;
                        g.firstCaseRow = true;
                    }
                    prevcase = g.CaseDescription;
                    g.caseColor = casecolor;
                    g.hasErrors = false;
                    g.isSaving = false;
                });
                grades.value = response.data;
            })
            .catch(() => {
                console.log(`error - schedule/${props.schedule.ID}/grades`);
                loaderror.value = true;
            }),
    ]).finally(() => {
        loading.value = false;
    });
});

function saveGrade(grade) {
    const gradeToUpdate = grades.value.find((g) => g.CaseID === grade.CaseID && g.CaseQuestionID === grade.CaseQuestionID);
    if (!gradeToUpdate) {
        AbogAlert.error("Unable to update grade.");
        return;
    }
    gradeToUpdate.hasErrors = false;
    gradeToUpdate.isSaving = true;
    axios
        .put(`schedule/${props.schedule.ID}/grade`, grade)
        .then(() => {
            gradeToUpdate.Score = grade.Score;
            gradeToUpdate.Notes = grade.Notes;
        })
        .catch((error) => {
            AbogAlert.error(error.response.data);
            gradeToUpdate.hasErrors = true;
            // gradeToUpdate.Score = null;
            // gradeToUpdate.Note = "";
        })
        .finally(() => {
            gradeToUpdate.isSaving = false;
        });
}
function lockGrades() {
    // make sure everything has a value
    const missingGrades = grades.value.filter((g) => g.Score === null);
    if (missingGrades.length > 0) {
        missingGrades.forEach((g) => {
            g.hasErrors = true;
        });
        AbogAlert.error("All sections must be scored before grades can be submitted.");
        return;
    }
    const missingNotes = grades.value.filter((g) => noteRequired(g.Score) && !g.Note);
    if (missingNotes.length > 0) {
        missingNotes.forEach((g) => {
            g.hasErrors = true;
        });
        const notesRequired = gradeKeys.value.filter((k) => k.NoteRequired).map((k) => k.Description);
        AbogAlert.error(`Any scores of ${notesRequired} require a comment.`);
        return;
    }

    AbogAlert.confirm("This will lock your scores for this candidate. Are you sure you want to submit?").then(() => {
        setAllSaving(true);
        axios
            .post(`schedule/${props.schedule.ID}/grades/submit`)
            .then(() => {
                saved.value = true;
                AbogAlert.success("Save Successful");
            })
            .catch(() => {
                AbogAlert.error("Something went wrong submitting your grades. Please try again.");
            })
            .finally(() => {
                setAllSaving(false);
            });
    });
}
function setAllSaving(isSaving) {
    grades.value.forEach((grade) => {
        grade.isSaving = isSaving;
    });
}
function noteRequired(score) {
    const key = gradeKeys.value.find((k) => k.Score === score);
    return key.NoteRequired;
}
</script>
