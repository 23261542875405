<template>
    <div class="w-full md:w-1/2 min-w-min">
        <h2 class="font-bold text-2xl mb-4">Documents</h2>
        <div>
            <label class="blue-button inline-block" :class="uploading ? 'opacity-50 pointer-events-none' : 'cursor-pointer'">
                <i class="fa fa-file-import" />
                Upload
                <input type="file" @change="uploadDoc" hidden :disabled="uploading" />
            </label>
            <AbogLoading v-if="uploading" class="w-6 ml-4" />
        </div>
        <div class="my-4">
            <div class="p-2 bg-abogblue-400 text-white border-b">
                <label class="mr-2 font-bold">File Name</label>
            </div>
            <AuthFile v-for="doc in documents" :key="doc.ID" class="p-2 border-t text-abogblue-300" :file="doc" :showdelete="true" @deleted="deleteDoc" />
            <!-- :class="{ 'bg-aboggray-100': doc.ID === draggedid }" draggable="true" @dragstart="dragstart(doc.ID)" @dragend="dragend()" @dragover="dragover(doc.ID)" -->
        </div>
    </div>
</template>
<script setup>
import { inject, ref, onMounted } from "vue";
import AbogLoading from "@/pages/components/AbogLoading";
import AuthFile from "@/pages/components/AuthFile";
import AbogAlert from "../components/AbogAlert";
const axios = inject("axios");
const documents = ref([]);
const uploading = ref(false);

onMounted(() => {
    axios.get("documents").then((response) => {
        response.data.sort((a, b) => {
            return a.FileName.toUpperCase() > b.FileName.toUpperCase() ? 1 : a.FileName.toUpperCase() < b.FileName.toUpperCase() ? -1 : 0;
        });
        // response.data.sort((a, b) => a.SortOrder - b.SortOrder);
        documents.value = response.data;
    });
});

function uploadDoc(e) {
    uploading.value = true;
    const file = e.target.files[0];
    axios
        .post(`document?filename=${file.name}`, file)
        .then((response) => {
            AbogAlert.success("The file upload was successful");
            e.target.value = null;
            const idx = documents.value.findIndex((d) => d.FileName.toUpperCase() > response.data.FileName.toUpperCase());
            documents.value.splice(idx, 0, response.data);  
        })
        .finally(() => (uploading.value = false));
}

function deleteDoc(id) {
    const idx = documents.value.findIndex((d) => d.ID === id);
    documents.value.splice(idx, 1);
}

// const draggedid = ref(0);
// function dragstart(id) {
//     draggedid.value = id;
// }
// function dragend() {
//     draggedid.value = 0;
//     // save new sort order to database
//     // documents.value.forEach((doc, i) => {
//     //     if (doc.SortOrder !== i) {
//     //         axios.put("document", doc);
//     //     }
//     // });
// }
// function dragover(id) {
//     const aidx = documents.value.findIndex((d) => d.ID === id);
//     const bidx = documents.value.findIndex((d) => d.ID === draggedid.value);
//     // swap a and b index
//     [documents.value[aidx], documents.value[bidx]] = [documents.value[bidx], documents.value[aidx]];
// }
</script>
