<template>
    <div class="w-full h-full">
        <div class="mb-4">
            <label class="select-arrow text-lg">
                <span class="mr-2">Filter:</span>
                <select class="font-bold" v-model="view">
                    <option class="font-normal">All</option>
                    <option class="font-normal">Current Hour</option>
                    <option class="font-normal">Current Day</option>
                </select>
            </label>
        </div>
        <div class="flex grid grid-cols-3 gap-4 h-full w-full mx-4">
            <div class="mx-2 h-full text-2xl">
                Unresolved
                <div class="drop-zone w-full items-center border-2 border-slate-300 text-lg overflow-y-auto boxheight" @drop="onDrop($event, 1)" @dragenter.prevent @dragover.prevent>
                    <div v-for="item in getList(1)" :key="item.ID_HML" class="drag-el bg-abogred-100 border m-2 px-4 py-2" draggable="true" @dragstart="startDrag($event, item)">
                        <p>{{ item.FullName }} {{ item.Options }} {{ item.RoomNumber }}</p>
                        <p>{{ formatDate(item.OpenedDate) }}</p>
                    </div>
                </div>
            </div>
            <div class="mx-2 h-full text-2xl">
                Pending
                <div class="drop-zone w-full items-center border-2 border-slate-300 text-lg overflow-y-auto boxheight" @drop="onDrop($event, 2)" @dragenter.prevent @dragover.prevent>
                    <div v-for="item in getList(2)" :key="item.ID_HML" class="drag-el bg-abogyellow-100 border m-2 px-4 py-2" draggable="true" @dragstart="startDrag($event, item)">
                        <p>{{ item.FullName }} {{ item.Options }} {{ item.RoomNumber }}</p>
                        <p>{{ formatDate(item.OpenedDate) }}</p>
                    </div>
                </div>
            </div>
            <div class="mx-2 h-full text-2xl">
                Resolved
                <div class="drop-zone w-full items-center border-2 border-slate-300 text-lg overflow-y-auto boxheight" @drop="onDrop($event, 3)" @dragenter.prevent @dragover.prevent>
                    <div v-for="item in getList(3)" :key="item.ID_HML" class="drag-el bg-aboggreen-100 rounded border m-2 px-4 py-2" draggable="true" @dragstart="startDrag($event, item)">
                        <p>{{ item.FullName }} {{ item.Options }} {{ item.RoomNumber }}</p>
                        <p>{{ formatDate(item.ClosedDate) }}</p>
                    </div>
                    <div v-for="item in getList(4)" :key="item.ID_HML" class="drag-el bg-aboggray-100 rounded border m-2 px-4 py-2">
                        <p>{{ item.FullName }} {{ item.Options }} {{ item.RoomNumber }} {{ item.Status }}</p>
                        <p>{{ formatDate(item.ClosedDate) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { inject, ref, onMounted, onUnmounted, computed } from "vue";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import AbogDate from "@/pages/components/AbogDate";
import Token from "@/token";
const axios = inject("axios");
const messages = ref([]);
const view = ref("All");
const helpMessageStatus = ref([]);
const timeslots = ref([]);

onMounted(() => {
    connectWebSocket();
    loadTimeSlots();
    loadMessages();
    loadMessageStatuses();
});
onUnmounted(() => {
    closeWebSocket();
});
function loadMessages() {
    axios.get("HelpMessages").then((response) => {
        messages.value = response.data;
    });
}
function loadMessageStatuses() {
    axios.get("HelpMessages/statuses").then((response) => {
        helpMessageStatus.value = response.data;
    });
}
function loadTimeSlots() {
    axios.get("timeslots").then((response) => {
        response.data.forEach((s) => {
            s.StartTime = s.StartTime.slice(0, -3);
            s.EndTime = s.EndTime.slice(0, -3);
        });
        timeslots.value = response.data;
    });
}
function formatDate(date) {
    return new AbogDate(date).toABOGDateTimeString();
}

///filters////
//Messages by day
const messagesByDay = computed(() => {
    const now = ref(new AbogDate());
    let arr = [];
    let data = JSON.parse(JSON.stringify(messages.value));
    let nowdate = now.value.toABOGDateString();
    data.forEach((obj) => {
        let mdate = new AbogDate(obj.OpenedDate).toABOGDateString();
        if (mdate == nowdate) {
            arr.push(obj);
        }
    });
    return arr;
});

//Messages by day and time
const messagesInCurrentSlot = computed(() => {
    const now = ref(new AbogDate());
    let arr = [];
    let data = JSON.parse(JSON.stringify(messages.value));
    let nowtime = now.value.getHours().toString().padStart(2, "0") + ":" + now.value.getMinutes().toString().padStart(2, "0");
    let nowdate = now.value.toABOGDateString();
    const slot = timeslots.value.find((ts) => ts.StartTime <= nowtime && ts.EndTime >= nowtime);
    if (slot) {
        data.forEach((obj) => {
            let mdate = new AbogDate(obj.OpenedDate).toABOGDateString();
            let mtime = new AbogDate(obj.OpenedDate).getHours().toString().padStart(2, "0") + ":" + new AbogDate(obj.OpenedDate).getMinutes().toString().padStart(2, "0");
            if (mdate == nowdate && slot.StartTime <= mtime && mtime <= slot.EndTime) {
                arr.push(obj);
            }
        });
        return arr;
    }
    return [];
});

function getList(status) {
    if (view.value == "Current Hour") {
        return messagesInCurrentSlot.value.filter((item) => item.ID_HMS == status);
    } else if (view.value == "Current Day") {
        return messagesByDay.value.filter((item) => item.ID_HMS == status);
    } else return messages.value.filter((item) => item.ID_HMS == status);
}

function startDrag(event, item) {
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("itemID", item.ID_HML);
}

function onDrop(event, list) {
    const itemID = event.dataTransfer.getData("itemID");
    const item = messages.value.find((item) => {
        return item.ID_HML == itemID;
    });
    if (item.ID_HMS !== list) {
        item.ID_HMS = list;
        let data = {
            ID_HML: item.ID_HML,
            ID_HMS: item.ID_HMS,
            RoomNumber: item.RoomNumber,
            FullName: item.FullName,
        };
        const tokenpayload = Token.payload();
        axios.post("HelpMessage", data).then(() => {
            loadMessages();
            sendWebSocket({ command: `${data.ID_HMS}`, exam: tokenpayload.ExamName, room: data.RoomNumber, FullName: data.FullName }, data);
        });
    }
}

const client = new WebPubSubClient({
    getClientAccessUrl: async () => {
        return (await axios.get("authentication/pubsub")).data;
    },
});

function connectWebSocket() {
    client.start();
    client.on("group-message", (event) => {
        const wsdata = event.message.data;
        if (wsdata.command === "message") {
            loadMessages();
        }
        if (wsdata.command === "canceled") {
            loadMessages();
        }
    });
    client.joinGroup("admin");
}

function sendWebSocket(wsdata, data) {
    client.sendToGroup(data.FullName.concat(" ", data.RoomNumber), wsdata, "json");
}

function closeWebSocket() {
    client.stop();
}
</script>
<style scoped>
.boxheight {
    max-height: 80vh;
    min-height: 80vh;
}
</style>
