<template>
    <nav class="flex justify-between p-4 mb-0 align-middle">
        <div>
            <RouterLink v-if="exam.OrganizationID" :to="`/`">
                <AuthMedia :src="`organization/${exam.OrganizationID}/logo`" :alt="exam.OrganizationName" class="h-4 sm:h-10 mx-2" />
            </RouterLink>
        </div>
        <div v-if="Token.payload() && !exam.Loading" class="text-center">
            <div>{{ userTitle }}</div>
            <div v-if="Token.payload().role === 'Room' || (exam.Exams.length === 1 && exam.Exams[0].ExamName === Token.payload().ExamName)">{{ calcExamTitle(exam.Exams[0]) }}</div>
            <div v-else class="select-arrow">
                <select @change="changeExam">
                    <option v-if="!exam.Exams.some((e) => e.ExamName === Token.payload().ExamName)" :selected="true" value="">This exam is no longer active. Please change exams or you will be logged out</option>
                    <option v-for="e in exam.Exams" :key="e.ID" :selected="e.ExamName === Token.payload().ExamName" :value="e.ExamName">{{ calcExamTitle(e) }}</option>
                </select>
            </div>
        </div>
        <div v-if="Token.payload()" class="relative">
            <button v-if="Token.payload().role !== 'Examiner'" @click="logout" class="menulink"><i class="fas fa-sign-out-alt text-center w-6" /> Logout</button>
            <!-- no need for a manu with only 1 page -->
            <button v-else @click="showmenu = true" class="text-2xl px-2 z-50">
                <i class="fas fa-bars text-center w-6" />
            </button>
            <Transition name="slide-fade">
                <div v-if="showmenu" class="absolute right-0 top-0 w-40 bg-aboggray-100 rounded-lg z-50">
                    <div class="float-right">
                        <button @click="showmenu = false" class="px-2 text-2xl">
                            <i class="fas fa-times text-center w-6" />
                        </button>
                    </div>
                    <!-- <RouterLink v-if="checkPermissions('dashboard')" to="/dashboard" @click="showmenu = false" class="menulink"> <i class="fas fa-chart-bar text-center w-6" /> Dashboard </RouterLink> -->
                    <RouterLink v-if="checkPermissions('schedule')" to="/Schedule" @click="showmenu = false" class="menulink"> <i class="fa fa-calendar-days text-center w-6" /> Schedule </RouterLink>
                    <RouterLink v-if="checkPermissions('documents')" to="/Documents" @click="showmenu = false" class="menulink"> <i class="fa fa-briefcase text-center w-6" /> Documents </RouterLink>
                    <!-- <RouterLink to="/examroom" @click="showmenu = false" class="menulink"> <i class="fa fa-desktop text-center w-6" /> Exam Room </RouterLink> -->
                    <button @click="logout" class="menulink"><i class="fas fa-sign-out-alt text-center w-6" /> Logout</button>
                </div>
            </Transition>
        </div>
        <div v-if="showmenu" class="fixed left-0 top-0 w-full h-full" @click="showmenu = false"></div>
    </nav>
</template>
<script setup>
import { ref, computed } from "vue";
import { useExamStore } from "@/pages/components/ExamStore";
import AuthMedia from "@/pages/components/AuthMedia";
import AbogDate from "./AbogDate";
import Token from "@/token";

const exam = useExamStore();
const emit = defineEmits(["logout", "changeExam"]);

const showmenu = ref(false);

function logout() {
    showmenu.value = false;
    emit("logout");
}

function checkPermissions(route) {
    const payload = Token.payload();
    return payload && payload.role && ((route === "dashboard" && payload.role.includes("Admin")) || (route === "schedule" && payload.role.includes("Examiner")) || (route === "documents" && payload.role.includes("Examiner")));
}

function changeExam(e) {
    emit("changeExam", e.target.value);
}

function calcExamTitle(e) {
    if (!e) return "";
    return `${e.ExamType} (${new AbogDate(e.StartDate).toABOGDateString()} - ${new AbogDate(e.EndDate).toABOGDateString()})`;
}

const userTitle = computed(() => {
    return exam.UserName;
});
</script>
<style scoped>
/* special classes for the vue built in transform component */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.2s ease;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

.menulink {
    @apply block p-2 w-full text-left whitespace-nowrap;
}
</style>
