<template>
    <div class="w-full bg-aboggray-100 rounded-lg p-4">
        <div class="font-bold text-right">{{ casetype }}</div>
        <div class="flex" :class="{ 'flex-col': fullscreenmedia }">
            <div v-if="data" class="w-full pr-2 whitespace-pre-wrap" v-html="data"></div>

            <div v-if="media.length > 0" class="relative pl-2" :class="{ 'order-first w-full': fullscreenmedia }">
                <AuthMedia
                    v-for="med in media"
                    :key="med.ID"
                    :src="med.URL"
                    @click="toggleMediaAndCase(med.ID)"
                    class="object-contain"
                    :class="{ 'w-full fullscreenhmax': med.ID === fullscreenmedia, 'w-40 h-40': !fullscreenmedia, hidden: fullscreenmedia && med.ID !== fullscreenmedia }"
                />

                <div v-if="fullscreenmedia" @click="toggleMediaAndCase(fullscreenmedia)" class="z-20 absolute top-0 right-0 w-10 h-10 p-2 font-bold cursor-pointer"><i class="text-2xl fa fa-times" /></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AuthMedia from "@/pages/components/AuthMedia";

const props = defineProps(["data", "casetype", "fullscreenmedia", "media"]);

const emit = defineEmits(["sendfullscreenrequest"]);

function toggleMediaAndCase(mediaID) {
    emit("sendfullscreenrequest", mediaID, mediaID !== props.fullscreenmedia);
}
</script>

<style scoped>
.fullscreenhmax {
    max-height: 70vh;
}
</style>
