<template>
    <div class="flex">
        <div class="flex-none w-32 flex flex-col">
            <RouterLink
                v-for="link in links"
                :key="link.name"
                :to="{ name: link.name }"
                active-class="highlight"
                class="flex flex-col text-center mb-2 hover:opacity-100"
                :class="{ 'opacity-75': activepage !== 'adminnav' && activepage !== link.name }"
            >
                <i class="text-2xl fa" :class="link.icon"></i> {{ link.text }}
            </RouterLink>
        </div>
        <RouterView class="p-2 min-w-0" />
    </div>
</template>
<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const links = [
    { name: "AdminDashboard", text: "Dashboard", icon: "fa-gauge" },
    { name: "AdminExaminers", text: "Examiners", icon: "fa-user-doctor" },
    { name: "AdminCandidates", text: "Candidates", icon: "fa-graduation-cap" },
    { name: "AdminCases", text: "Cases", icon: "fa-book" },
    { name: "AdminSchedule", text: "Schedule", icon: "fa-calendar-days" },
    { name: "AdminDocuments", text: "Documents", icon: "fa-file-lines" },
    { name: "AdminGrades", text: "Grades", icon: "fa-clipboard-check" },
    { name: "AdminMessages", text: "Messages", icon: "fa-solid fa-envelope" },
    { name: "AdminBlueprint", text: "Blueprint", icon: "fa-solid fa-scroll" },
    { name: "AdminSettings", text: "Settings", icon: "fa-solid fa-gear" },
];
const activepage = computed(() => route.name);
</script>
<style scoped>
.highlight {
    /*
    aboggreen-300: #3fb2a4
    abogblue-200: #498ac8
    abogblue-300: #2e6498
    */
    /* text-shadow: #498ac8 0 0 10px; */ /* color | offset-x | offset-y | blur-radius */
    /* background: radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(255, 255, 255, 0) 100%); */
    background: linear-gradient(90deg, #498ac8 0%, rgba(255, 255, 255, 0) 5%);
}
</style>
