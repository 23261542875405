class Token {
    static get nonAuthPages() {
        return ["/login", "/settoken", "/forgotpw", "/changepw", "/changepw/"];
    }

    static get() {
        return localStorage.getItem("jwt");
    }

    static set(token) {
        localStorage.setItem("jwt", token);
    }

    static clear() {
        localStorage.removeItem("jwt");
    }

    static payload() {
        const jwt = this.get(); //localStorage.getItem("jwt");
        if (jwt) {
            const base64Url = jwt.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split("")
                    .map((c) => {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );
            const payload = JSON.parse(jsonPayload);
            if (new Date(payload.exp * 1000) > new Date()) {
                return payload;
            }
        }
        return null;
    }
}
export default Token;
