import { defineStore } from "pinia";
import { ref } from "vue";

export const useExamStore = defineStore("exam", () => {
    // state
    const OrganizationID = ref(0);
    const OrganizationName = ref("");
    // const ExamType = ref("");
    const Exams = ref([]);
    const Days = ref([]);
    const CurrentDay = ref("");
    const UserName = ref("");
    const Loading = ref(false);
    const Failed = ref(false);

    // let examloaded = false;
    let examsloaded = false;
    let daysloaded = false;
    let userloaded = false;
    let orgloaded = false;

    // getters
    // const samplegetter = computed(() => FullName.value.length);
    // actions
    function refresh() {
        Loading.value = true;
        Failed.value = false;
        // call axios to refresh data. requires api to be set with pinia.use(() => ({ api }));
        // this.api
        //     .get("exam")
        //     .then((response) => {
        //         ExamType.value = response.data.ExamType;
        //     })
        //     .catch(() => {
        //         ExamType.value = "";
        //     })
        //     .finally(() => {
        //         examloaded = true;
        //         checkLoadFinish();
        //     });
        this.api
            .get("exams")
            .then((response) => {
                Exams.value = response.data;
            })
            .catch(() => {
                Exams.value = "";
                Failed.value = true;
            })
            .finally(() => {
                examsloaded = true;
                checkLoadFinish();
            });
        this.api
            .get("days")
            .then((response) => {
                Days.value = response.data.map((d) => d.Date);
                const cd = response.data.find((d) => d.IsCurrent);
                if (cd) CurrentDay.value = cd.Date;
            })
            .catch(() => {
                Days.value = [];
                CurrentDay.value = "";
                Failed.value = true;
            })
            .finally(() => {
                daysloaded = true;
                checkLoadFinish();
            });
        this.api
            .get("user")
            .then((response) => {
                UserName.value = response.data.FullName;
            })
            .catch(() => {
                UserName.value = "";
                Failed.value = true;
            })
            .finally(() => {
                userloaded = true;
                checkLoadFinish();
            });
        this.api
            .get("organization")
            .then((response) => {
                OrganizationName.value = response.data.FullName;
                OrganizationID.value = response.data.ID;
            })
            .catch(() => {
                OrganizationName.value = "";
                OrganizationID.value = 0;
                Failed.value = true;
            })
            .finally(() => {
                orgloaded = true;
                checkLoadFinish();
            });
    }
    function checkLoadFinish() {
        if (examsloaded && userloaded && orgloaded && daysloaded) {
            // examloaded &&
            Loading.value = false;
        }
    }
    function clear() {
        OrganizationName.value = "";
        OrganizationID.value = 0;
        // ExamType.value = "";
        Exams.value = [];
        Days.value = [];
        UserName.value = "";
        Loading.value = false;
    }
    // expose
    return { OrganizationID, OrganizationName, Exams, Days, CurrentDay, UserName, Loading, Failed, refresh, clear }; // ExamType,
});
