<template>
    <div>
        <h2 class="font-bold text-2xl">Schedule</h2>
        <div class="flex items-center justify-between mb-4 whitespace-nowrap">
            <div>
                <label class="select-arrow">
                    <span class="mr-2">View:</span>
                    <select class="font-bold" v-model="filter.view">
                        <option class="font-normal">Room</option>
                        <option class="font-normal">Candidate</option>
                        <option class="font-normal">Examiner</option>
                    </select>
                </label>
                <label class="select-arrow ml-12">
                    <span class="mr-2">Date:</span>
                    <select class="font-bold" v-model="filter.date">
                        <option v-for="d in dates" :key="d" class="font-normal" :value="d">{{ formatDate(d) }}</option>
                    </select>
                </label>
                <label v-if="filter.view !== 'Examiner'" class="select-arrow ml-12">
                    <span class="mr-2">Meridiem:</span>
                    <select class="font-bold" v-model="filter.time">
                        <option class="font-normal">All</option>
                        <option class="font-normal">AM</option>
                        <option class="font-normal">PM</option>
                    </select>
                </label>
                <label class="ml-12">
                    <span class="mr-2">{{ filter.view }}:</span>
                    <input type="text" class="border-b border-aboggray-700 -mr-4" v-model="filter.search" />
                    <i :class="filter.search !== '' ? 'fas fa-xmark cursor-pointer' : 'fas fa-search pointer-events-none'" @click="filter.search = ''"></i>
                </label>
                <label v-if="filter.view !== 'Examiner' && cases.some((c) => c.CaseType === 'Case List')" class="ml-12">
                    <span class="mr-2">Case List:</span>
                    <input type="checkbox" v-model="filter.caselist" />
                </label>
            </div>
            <div>
                <!-- <button class="blue-button mx-4" :class="saving ? 'opacity-50 pointer-events-none' : 'cursor-pointer'" @click="save"><i class="fa fa-floppy-disk" /> Save</button> -->
                <label class="blue-button inline-block ml-4" :class="importDialog.show ? 'opacity-50 pointer-events-none' : 'cursor-pointer'">
                    <i class="fa fa-file-import" /> Import
                    <input type="file" accept=".csv" @change="scheduleImport" hidden :disabled="importDialog.show" />
                </label>
            </div>
        </div>
        <div class="overflow-scroll">
            <table v-if="filter.view === 'Room' && schedule.length > 0" class="border-separate border-spacing-0 border border-aboggray-700">
                <tr class="bg-abogblue-400 text-white">
                    <th class="px-4 py-2 sticky left-0 bg-abogblue-400">Room</th>
                    <th v-for="t in times" :key="t" class="px-4 py-2">{{ t.StartTime }}</th>
                </tr>
                <tr v-for="group in scheduleViewByRoom" :key="group.ID">
                    <td class="border border-aboggray-700 px-4 py-2 sticky left-0 bg-white">{{ group.Name }}</td>
                    <td v-for="block in group.Blocks" :key="block.ID" class="border border-aboggray-700">
                        <div class="whitespace-nowrap border-b px-4 py-1"><span class="inline-block w-24">Type:</span>{{ timeSlotKindLookup[block.Kind] }}</div>
                        <div class="text-abogblue-400 cursor-pointer whitespace-nowrap border-y px-4 py-1" @click="candidateEdit(block.ID)">
                            <!-- :class="{ 'bg-aboggray-100': hasChanges(block.ID, 'candidate') }" -->
                            <span class="inline-block w-24">Candidate:</span>{{ block.CandidateName || "None" }}
                        </div>
                        <div class="text-abogblue-400 cursor-pointer whitespace-nowrap border-y px-4 py-1" @click="examinerEdit(block.ID)">
                            <!-- :class="{ 'bg-aboggray-100': hasChanges(block.ID, 'examiner') }" -->
                            <span class="inline-block w-24">Examiners:</span>{{ block.Examiners.length === 0 ? "None" : block.Examiners.length < 3 ? block.Examiners.map((e) => e.ExaminerName).join(", ") : block.Examiners.length }}
                        </div>
                        <div class="text-abogblue-400 cursor-pointer whitespace-nowrap border-t px-4 py-1" @click="caseEdit(block.ID)">
                            <!-- :class="{ 'bg-aboggray-100': hasChanges(block.ID, 'case') }" -->
                            <span class="inline-block w-24">Cases:</span>{{ checkCaseListFilter(block.Cases).length || "None" }}
                        </div>
                    </td>
                </tr>
            </table>
            <table v-if="filter.view === 'Candidate' && schedule.length > 0" class="border-separate border-spacing-0 border border-aboggray-700">
                <tr class="bg-abogblue-400 text-white">
                    <th class="px-4 py-2 sticky left-0 bg-abogblue-400">Candidate</th>
                    <!-- <th class="px-4 py-2" colspan="99">Hours</th> -->
                    <th v-for="t in times" :key="t" class="px-4 py-2">{{ t.StartTime }}</th>
                </tr>
                <tr v-for="group in scheduleViewByCandidate" :key="group.ID">
                    <td class="border border-aboggray-700 px-4 py-2 sticky left-0 bg-white">{{ group.Name }}</td>
                    <td v-for="block in group.Blocks" :key="block.ID" class="border border-aboggray-700">
                        <!-- <div class="whitespace-nowrap border-b px-4 py-1"><span class="inline-block w-24">Time:</span>{{ new AbogDate(block.Date).toABOGDateString() }} {{ block.StartTime }}</div> -->
                        <div class="text-abogblue-400 cursor-pointer whitespace-nowrap border-b px-4 py-1" @click="candidateBlockEdit(block, group.ID)">
                            <!-- :class="{ 'bg-aboggray-100': hasChanges(block.ID, 'candidate') }" -->
                            <span class="inline-block w-24">Room:</span>
                            {{ block.RoomNumber }}
                        </div>
                        <div class="text-abogblue-400 cursor-pointer whitespace-nowrap border-y px-4 py-1" @click="examinerEdit(block.ID)">
                            <!-- :class="{ 'bg-aboggray-100': hasChanges(block.ID, 'examiner') }" -->
                            <span class="inline-block w-24">Examiners:</span>{{ block.Examiners.length === 0 ? "" : block.Examiners.length < 3 ? block.Examiners.map((e) => e.ExaminerName).join(", ") : block.Examiners.length }}
                        </div>
                        <div class="text-abogblue-400 cursor-pointer whitespace-nowrap border-t px-4 py-1" @click="caseEdit(block.ID)">
                            <!-- :class="{ 'bg-aboggray-100': hasChanges(block.ID, 'case') }" -->
                            <span class="inline-block w-24">Cases:</span>{{ checkCaseListFilter(block.Cases).length || "" }}
                        </div>
                    </td>
                </tr>
            </table>
            <table v-if="filter.view === 'Examiner' && schedule.length > 0" class="border-separate border-spacing-0 border border-aboggray-700">
                <tr class="bg-abogblue-400 text-white">
                    <th class="px-4 py-2">Examiner</th>
                    <th class="px-4 py-2">Time</th>
                    <th class="px-4 py-2">Room</th>
                    <th class="px-4 py-2">Type</th>
                    <th class="px-4 py-2">Role</th>
                    <th class="px-4 py-2">Candidate</th>
                    <th class="px-4 py-2">Partner</th>
                </tr>
                <template v-for="group in scheduleViewByExaminer" :key="group.ID">
                    <tr v-for="(block, i) in group.Blocks" :key="block.ID">
                        <!-- :class="{ 'bg-aboggray-100': hasChanges(block.ID, 'examiner') }" -->
                        <td v-if="i === 0" class="border border-aboggray-700 px-4 py-2" :rowspan="group.Blocks.length">{{ group.Name }}</td>
                        <td class="border px-4 py-2 border-l-aboggray-700" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Blocks.length - 1 }">{{ block.StartTime }}</td>
                        <td class="text-abogblue-400 cursor-pointer border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Blocks.length - 1 }" @click="examinerBlockEdit(block, group.ID)">
                            {{ block.RoomNumber }}
                        </td>
                        <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Blocks.length - 1 }">{{ timeSlotKindLookup[block.Kind] }}</td>
                        <td class="text-abogblue-400 cursor-pointer border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Blocks.length - 1 }" @click="roleEdit(block.ID, group.ID)">
                            {{ block.Role || "None" }}
                        </td>
                        <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Blocks.length - 1 }">{{ block.CandidateName }}</td>
                        <td class="border px-4 py-2 border-r-aboggray-700" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Blocks.length - 1 }">{{ block.Partner }}</td>
                    </tr>
                </template>
            </table>
        </div>
        <AbogModal v-if="showmodals === 'cblock'" title="Edit Room" @close="closeModal()">
            <p>Select a room to move this candidate to.</p>
            <div class="border min-w-40 max-h-96">
                <div class="cursor-pointer p-2" :class="{ 'bg-abogyellow-100': selectedBlockId === null }" @click="candidateBlockSelect(selectedCandidateId, null)">None</div>
                <div
                    v-for="block in newBlockOptions"
                    :key="block.ID"
                    class="whitespace-nowrap cursor-pointer p-2"
                    :class="{ 'bg-abogyellow-100': selectedBlockId && editBlock.ID === block.ID }"
                    @click="candidateBlockSelect(selectedCandidateId, block.ID)"
                >
                    {{ block.RoomNumber }}
                </div>
            </div>
        </AbogModal>
        <AbogModal v-if="showmodals === 'eblock'" title="Edit Room" @close="closeModal()">
            <p>Select a room to move this examiner to.</p>
            <div class="border min-w-40 max-h-96">
                <div class="cursor-pointer p-2" :class="{ 'bg-abogyellow-100': selectedBlockId === 0 }" @click="examinerBlockSelect(selectedExaminerId, 0)">None</div>
                <div v-for="block in newBlockOptions" :key="block.ID" class="whitespace-nowrap cursor-pointer p-2" :class="{ 'bg-abogyellow-100': selectedBlockId === block.ID }" @click="examinerBlockSelect(selectedExaminerId, block.ID)">
                    {{ block.RoomNumber }}
                </div>
            </div>
        </AbogModal>
        <AbogModal v-if="showmodals === 'candidate'" title="Edit Candidate" @close="closeModal()">
            <p>Select a candidate</p>
            <div class="border overflow-scroll max-h-96">
                <div class="cursor-pointer p-2" :class="{ 'bg-abogyellow-100': editBlock.CandidateID === null }" @click="candidateSelect(0)">None</div>
                <div v-for="candidate in candidates" :key="candidate.ID" class="whitespace-nowrap cursor-pointer p-2" :class="{ 'bg-abogyellow-100': candidate.ID === editBlock.CandidateID }" @click="candidateSelect(candidate.ID)">
                    {{ candidate.FullName }}
                </div>
            </div>
        </AbogModal>
        <AbogModal v-if="showmodals === 'examiner'" title="Edit Examiners" @close="closeModal()">
            <div class="flex">
                <div class="border overflow-scroll max-h-96 min-w-40" @dragover="enableDrop" @drop="examinerRemove()">
                    <div class="text-center font-bold">Name</div>
                    <div
                        v-for="examiner in examiners.filter((e) => !editBlock.Examiners.some((be) => be.ExaminerID === e.ID))"
                        :key="examiner.ID"
                        class="p-2 whitespace-nowrap cursor-pointer w-fit min-w-full"
                        :class="{ 'bg-abogyellow-100': examiner.ID === selectedExaminerId }"
                        @click="examinerSelect(examiner.ID)"
                        draggable="true"
                        @dragstart="examinerSelect(examiner.ID)"
                    >
                        {{ examiner.FullName }}
                    </div>
                </div>
                <div class="flex flex-col justify-center">
                    <button class="hollow-blue-button m-2" :disabled="!selectedExaminerId || editBlock.Examiners.some((be) => be.ExaminerID === selectedExaminerId)" @click="examinerAdd()"><i class="fa fa-chevron-right" /></button>
                    <button class="hollow-blue-button m-2" :disabled="!selectedExaminerId || !editBlock.Examiners.some((be) => be.ExaminerID === selectedExaminerId)" @click="examinerRemove()"><i class="fa fa-chevron-left" /></button>
                </div>
                <div class="border max-h-96 min-w-40" @dragover="enableDrop" @drop="examinerAdd()">
                    <table class="overflow-scroll w-full">
                        <tr>
                            <th>Name</th>
                            <th>Role</th>
                        </tr>
                        <tr
                            v-for="examiner in editBlock.Examiners"
                            :key="examiner.ExaminerID"
                            class="cursor-pointer w-fit min-w-full"
                            :class="{ 'bg-abogyellow-100': examiner.ExaminerID === selectedExaminerId }"
                            @click="examinerSelect(examiner.ExaminerID)"
                            draggable="true"
                            @dragstart="examinerSelect(examiner.ExaminerID)"
                        >
                            <td class="p-2 whitespace-nowrap">{{ examiner.ExaminerName }}</td>
                            <td class="p-2 whitespace-nowrap">
                                <input type="text" class="bg-transparent border-b pr-4" v-model="examiner.Role" @change="saveRole(editBlock.ID, examiner.ExaminerID, examiner.Role)" />
                                <i class="fa fa-pencil -ml-4 pointer-events-none" />
                                <!-- {{ examiner.Role }} -->
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </AbogModal>
        <AbogModal v-if="showmodals === 'case'" title="Edit Cases" @close="closeModal()">
            <div class="flex">
                <div class="border overflow-auto max-h-96 min-w-40" @dragover="enableDrop" @drop="caseRemove()">
                    <div
                        v-for="c in checkCaseListFilter(cases).filter((c) => !editBlock.Cases.some((bc) => bc.ID === c.ID))"
                        :key="c.ID"
                        class="p-2 whitespace-nowrap cursor-pointer w-fit min-w-full"
                        :class="{ 'bg-abogyellow-100': c.ID === selectedCaseId }"
                        @click="caseSelect(c.ID)"
                        draggable="true"
                        @dragstart="caseSelect(c.ID)"
                    >
                        ({{ c.Section }}) {{ c.Description }}
                    </div>
                </div>
                <div class="flex flex-col justify-center">
                    <button class="hollow-blue-button m-2" :disabled="!selectedCaseId || editBlock.Cases.some((bc) => bc.ID === selectedCaseId)" @click="caseAdd()"><i class="fa fa-chevron-right" /></button>
                    <button class="hollow-blue-button m-2" :disabled="!selectedCaseId || !editBlock.Cases.some((bc) => bc.ID === selectedCaseId)" @click="caseRemove()"><i class="fa fa-chevron-left" /></button>
                </div>
                <div class="border overflow-auto max-h-96 min-w-40" @dragover="enableDrop" @drop="caseAdd()">
                    <div
                        v-for="c in checkCaseListFilter(cases).filter((c) => editBlock.Cases.some((bc) => bc.ID === c.ID))"
                        :key="c.ID"
                        class="p-2 whitespace-nowrap cursor-pointer w-fit min-w-full"
                        :class="{ 'bg-abogyellow-100': c.ID === selectedCaseId }"
                        @click="caseSelect(c.ID)"
                        draggable="true"
                        @dragstart="caseSelect(c.ID)"
                    >
                        ({{ c.Section }}) {{ c.Description }}
                    </div>
                </div>
            </div>
        </AbogModal>
        <!-- ImportDialog Dialog -->
        <AbogModal :title="importDialog.title" v-if="importDialog.show" :width="40" @close="closeImportDialog()">
            <label v-if="importDialog.importing" class="flex items-center justify-center whitespace-nowrap my-6">
                Importing...
                <AbogLoading class="w-12 mx-2" />
            </label>
            <div v-if="!importDialog.importing">
                <div class="mb-2">
                    <p class="whitespace-pre-wrap">
                        {{ importDialog.message }}
                    </p>
                </div>
                <div class="text-right py-4">
                    <button @click="closeImportDialog()" class="blue-button mx-2">OK</button>
                </div>
            </div>
        </AbogModal>
    </div>
</template>
<script setup>
import { ref, computed, onMounted, inject } from "vue";
import AbogModal from "@/pages/components/AbogModal";
import AbogDate from "@/pages/components/AbogDate";
import AbogAlert from "@/pages/components/AbogAlert";
import AbogLoading from "@/pages/components/AbogLoading";
const axios = inject("axios");

const timeSlotKindLookup = {
    0: "Exam",
    1: "Caucus",
    2: "Review",
    3: "Other",
};

const schedule = ref([]);
const cases = ref([]);
const examiners = ref([]);
const candidates = ref([]);
const rooms = ref([]);
// const saving = ref(false);
const filter = ref({ time: "All", date: "", search: "", caselist: false, view: "Room" });
const showmodals = ref("");
const importDialog = ref({ show: false, importing: false, title: "", message: "" });

// let initialschedule = [];

// load data
onMounted(() => {
    loadSchedules();
    loadCases();
    loadExaminers();
    loadCandidates();
    loadRooms();
});

function loadSchedules() {
    axios.get("schedules").then((response) => {
        response.data.forEach((s) => {
            // leave date format for comparisons
            // s.Date = new AbogDate(s.Date).toABOGDateString();
            s.StartTime = s.StartTime.slice(0, -3);
            s.EndTime = s.EndTime.slice(0, -3);
        });
        filter.value.date = response.data[0].Date;
        schedule.value = response.data;
        // initialschedule = structuredClone(response.data);
    });
}
function loadCases() {
    axios.get("cases/?caseQuestions=false&caseListCases=false").then((response) => {
        cases.value = response.data;
    });
}
function loadExaminers() {
    axios.get("examiners").then((response) => {
        examiners.value = response.data;
    });
}
function loadCandidates() {
    axios.get("candidates").then((response) => {
        candidates.value = response.data;
    });
}
function loadRooms() {
    axios.get("rooms").then((response) => {
        rooms.value = response.data;
    });
}

// function save() {
//     saving.value = true;
//     getInvalidUsers(); // validate data before we save
//     setTimeout(() => (saving.value = false), 500); // save everything at once
// }

// const invalidUsers = ref([]);
// function getInvalidUsers() {
//     // no one can be in 2 places at the same time
//     // let invalidusers = [];
//     let edict = {};
//     let cdict = {};
//     schedule.value.forEach((sb) => {
//         if (!cdict[`${sb.Date}-${sb.StartTime}`]) {
//             cdict[`${sb.Date}-${sb.StartTime}`] = new Set();
//             edict[`${sb.Date}-${sb.StartTime}`] = new Set();
//         }
//         if (sb.CandidateID && cdict[`${sb.Date}-${sb.StartTime}`].has(sb.CandidateID)) {
//             invalidUsers.value.push({ Date: sb.Date, StartTime: sb.StartTime, Candidate: sb.CandidateID });
//         }
//         cdict[`${sb.Date}-${sb.StartTime}`].add(sb.CandidateID);
//         sb.Examiners.forEach((e) => {
//             if (edict[`${sb.Date}-${sb.StartTime}`].has(e.ExaminerID)) {
//                 invalidUsers.value.push({ Date: sb.Date, StartTime: sb.StartTime, Examiner: e.ExaminerID });
//             }
//             edict[`${sb.Date}-${sb.StartTime}`].add(e.ExaminerID);
//         });
//     });
// }

const filteredSchedule = computed(() => {
    resetSearch();
    return schedule.value.filter((s) => {
        if (s.Date !== filter.value.date) return false;
        if (filter.value.view !== "Examiner" && filter.value.time === "AM" && s.StartTime >= "12:00") return false;
        if (filter.value.view !== "Examiner" && filter.value.time === "PM" && s.StartTime < "12:00") return false;
        return true;
    });
});

function resetFilterTime() {
    filter.value.time = "All";
}

function resetSearch() {
    filter.value.search = "";
}

const dates = computed(() => {
    return schedule.value.reduce((obj, sb) => {
        const d = sb.Date;
        if (!obj.includes(d)) obj.push(d);
        return obj;
    }, []);
});
function formatDate(d) {
    const abogdate = new AbogDate(d);
    return abogdate.toABOGDateString();
}

const times = computed(() => {
    return schedule.value
        .reduce((obj, sb) => {
            const st = sb.StartTime;
            const et = sb.EndTime;
            if (!obj.some((o) => o.StartTime === st)) obj.push({ StartTime: st, EndTime: et, Kind: sb.Kind });
            return obj;
        }, [])
        .filter((t) => {
            if (filter.value.time === "AM" && t.StartTime >= "12:00") return false;
            if (filter.value.time === "PM" && t.StartTime < "12:00") return false;
            return true;
        });
});

function checkCaseListFilter(caselist) {
    return caselist.filter((c) => filter.value.caselist || c);
}

const scheduleViewByRoom = computed(() => {
    return filteredSchedule.value
        .filter((s) => s.RoomNumber.toUpperCase().includes(filter.value.search.toUpperCase()))
        .reduce((obj, sb) => {
            const room = obj.find((r) => r.ID === sb.RoomIndex);
            if (room) {
                room.Blocks.push(sb);
            } else {
                obj.push({ ID: sb.RoomIndex, Name: sb.RoomNumber, Blocks: [sb] });
            }
            return obj;
        }, []);
});

const scheduleViewByCandidate = computed(() => {
    let sched = [];
    candidates.value
        .filter((c) => c.FullName.toUpperCase().includes(filter.value.search.toUpperCase()))
        .forEach((candidate) => {
            let blocks = [];
            times.value.forEach((time) => {
                blocks.push({
                    ID: null,
                    Date: filter.value.date, // only add selected date
                    Kind: time.Kind,
                    StartTime: time.StartTime,
                    EndTime: time.EndTime,
                    RoomIndex: null,
                    RoomNumber: "",
                    CandidateID: 0,
                    CandidateName: "",
                    Examiners: [],
                    Cases: [],
                });
            });
            sched.push({
                ID: candidate.ID,
                Name: candidate.FullName,
                Blocks: blocks,
            });
        });
    filteredSchedule.value.forEach((sb) => {
        const candidate = sched.find((s) => s.ID === sb.CandidateID);
        if (candidate) {
            const idx = candidate.Blocks.findIndex((b) => b.Date === sb.Date && b.StartTime === sb.StartTime);
            if (idx >= 0) candidate.Blocks[idx] = sb;
        }
    });
    return sched;
});

const scheduleViewByExaminer = computed(() => {
    resetFilterTime();
    let sched = [];
    examiners.value
        .filter((e) => e.FullName.toUpperCase().includes(filter.value.search.toUpperCase()))
        .forEach((examiner) => {
            let blocks = [];
            times.value.forEach((time) => {
                blocks.push({
                    ID: null,
                    Date: filter.value.date, // only add selected date
                    Kind: time.Kind,
                    StartTime: time.StartTime,
                    EndTime: time.EndTime,
                    RoomIndex: null,
                    RoomNumber: "",
                    CandidateID: null,
                    CandidateName: "",
                    // custom
                    Role: "",
                });
            });
            sched.push({
                ID: examiner.ID,
                Name: examiner.FullName,
                Blocks: blocks,
            });
        });
    filteredSchedule.value.forEach((sb) => {
        sb.Examiners.forEach((e) => {
            const examiner = sched.find((s) => s.ID === e.ExaminerID);
            if (examiner) {
                const block = examiner.Blocks.find((b) => b.Date === sb.Date && b.StartTime === sb.StartTime);
                block.ID = sb.ID;
                block.Role = e.Role;
                block.RoomIndex = sb.RoomIndex;
                block.RoomNumber = sb.RoomNumber;
                block.CandidateID = sb.CandidateID;
                block.CandidateName = sb.CandidateName || "None";
                const partners = sb.Examiners.filter((p) => p.ExaminerID !== examiner.ID);
                block.Partner = partners.length > 1 ? "Multiple" : partners.length === 1 ? partners[0].ExaminerName : "None";
            }
        });
    });
    return sched;
});

function scheduleImport(e) {
    importDialog.value = { show: true, importing: true, title: "Import Schedule", message: "" };
    const file = e.target.files[0];
    axios
        .post("schedule/import", file)
        .then((response) => {
            let titleMessage = "";
            switch (response.data.Result) {
                case 0:
                    titleMessage = "Schedule Import Success!";
                    break;
                case 1:
                    titleMessage = "Schedule Import Completed with Errors";
                    break;
                case 2:
                    titleMessage = "Schedule Import Failed";
                    break;
            }
            importDialog.value = { show: true, importing: false, title: titleMessage, message: response.data.Message };
            e.target.value = null;
            loadSchedules();
        })
        .catch((error) => {
            importDialog.value = { show: true, importing: false, title: "Error", message: error.response.data };
        });
}

function closeImportDialog() {
    importDialog.value = { show: false, importing: false, title: "", message: "" };
}
// function hasChanges(blockid, field) {
//     if (!blockid) return false;
//     // these are the editable fields
//     // CandidateID: 9
//     // CandidateName: "632F31A3-0897-40E3-A2C4-29CE4F1288C0"
//     // Cases: Array(18) [ {…}, {…}, {…}, … ]
//     // Examiners: Array [ {…}, {…} ]
//     const initial = initialschedule.find((s) => s.ID === blockid);
//     const current = schedule.value.find((s) => s.ID === blockid);

//     if (field === "case") {
//         if (initial.Cases.length !== current.Cases.length) return true;
//         for (let i = 0; i < initial.Cases.length; i++) {
//             if (initial.Cases[i].ID !== current.Cases[i].ID) return true;
//         }
//     } else if (field === "examiner") {
//         if (initial.Examiners.length !== current.Examiners.length) return true;
//         for (let i = 0; i < initial.Examiners.length; i++) {
//             if (initial.Examiners[i].ID !== current.Examiners[i].ID) return true;
//             if (initial.Examiners[i].Role !== current.Examiners[i].Role) return true;
//         }
//     } else if (field === "candidate") {
//         if (initial.CandidateID !== current.CandidateID) return true;
//     }
//     return false;
// }

// global modal stuff
const selectedBlockId = ref(0);
const editBlock = computed(() => {
    return schedule.value.find((s) => s.ID === selectedBlockId.value);
});
const newBlockOptions = ref([]);
const selectedExaminerId = ref(0);
const selectedCandidateId = ref(0);
const selectedCaseId = ref(0);
function closeModal() {
    showmodals.value = "";
    selectedBlockId.value = 0;
    selectedExaminerId.value = 0;
}
function enableDrop(e) {
    e.preventDefault();
}

// role edit modal stuff
function roleEdit(blockid, examinerid) {
    if (blockid === null) AbogAlert.error("The examiner is not currently assigned to this block.\n\nIn order to set a role they must be assigned first.");
    const block = schedule.value.find((sb) => sb.ID === blockid);
    const examiner = block.Examiners.find((e) => e.ExaminerID === examinerid);
    AbogAlert.prompt("What role would you like to assign this examiner?", examiner.Role).then((role) => {
        saveRole(blockid, examinerid, role);
    });
}
function saveRole(blockid, examinerid, role) {
    const block = schedule.value.find((sb) => sb.ID === blockid);
    const examiner = block.Examiners.find((e) => e.ExaminerID === examinerid);
    axios
        .put(`schedule/${blockid}/examiner/${examinerid}`, role)
        .then(() => {
            examiner.Role = role;
            AbogAlert.success("Role updated successfully");
        })
        .catch((error) => {
            AbogAlert.error(error.response.data);
        });
}
// candidate room edit modal stuff
function candidateBlockEdit(sourceblock, candidateid) {
    newBlockOptions.value = schedule.value.filter((b) => b.Date === sourceblock.Date && b.StartTime === sourceblock.StartTime);
    selectedBlockId.value = sourceblock.ID;
    selectedCandidateId.value = candidateid;
    showmodals.value = "cblock";
}
async function candidateBlockSelect(candidateid, newblockid) {
    // confirm conflicts, clear old record, add new record
    // candidateBlockCheckConflicts(candidateid, newblockid)
    //     .then(() => candidateBlockClearOld())
    //     .then(() => candidateBlockAddNew(candidateid, newblockid))
    //     .then(() => {
    //         selectedBlockId.value = newblockid;
    //     });
    // await candidateBlockCheckConflicts(candidateid, newblockid);
    await candidateBlockClearOld(newblockid);
    await candidateBlockAddNew(candidateid, newblockid);
    selectedBlockId.value = newblockid;
}
// removed to not overwrite rooms if occupied
// function candidateBlockCheckConflicts(candidateid, newblockid) {
//     return new Promise((resolve, reject) => {
//         if (newblockid) {
//             const block = schedule.value.find((s) => s.ID === newblockid);
//             if (block.CandidateID !== null) {
//                 return AbogAlert.confirm("That room is not vacant, are you sure you want to overwrite the current candidate?")
//                     .then(() => {
//                         resolve(true);
//                     })
//                     .catch(() => {
//                         reject;
//                     });
//             } else {
//                 resolve(true);
//             }
//         } else {
//             resolve(true);
//         }
//     });
// }
function candidateBlockClearOld(newblockid) {
    return new Promise((resolve, reject) => {
        if (editBlock.value && newblockid === null) {
            const clearold = { ID: editBlock.value.ID, CandidateName: "" };
            axios
                .put("schedule", clearold)
                .then(() => {
                    editBlock.value.CandidateID = null;
                    editBlock.value.CandidateName = "";
                    AbogAlert.success("Candidate removed successfully");
                    resolve(true);
                })
                .catch((response) => {
                    AbogAlert.error(response.data.data);
                    reject;
                });
        } else {
            resolve(true);
        }
    });
}
function candidateBlockAddNew(candidateid, newblockid) {
    return new Promise((resolve, reject) => {
        if (newblockid) {
            const candidate = candidates.value.find((e) => e.ID === candidateid);
            const block = schedule.value.find((s) => s.ID === newblockid);
            const addnew = { ID: block.ID, CandidateName: candidate.FullName };
            axios
                .put("schedule", addnew)
                .then(() => {
                    block.CandidateID = candidate.ID;
                    block.CandidateName = candidate.FullName;
                    AbogAlert.success("Candidate added successfully");
                    resolve(true);
                })
                .catch((error) => {
                    AbogAlert.error(error.response.data);
                    reject;
                });
        } else {
            resolve(true);
        }
    });
}

// examiner room edit modal stuff
function examinerBlockEdit(sourceblock, examiner) {
    newBlockOptions.value = schedule.value.filter((s) => s.Date === sourceblock.Date && s.StartTime === sourceblock.StartTime);
    selectedBlockId.value = sourceblock.ID;
    selectedExaminerId.value = examiner;
    showmodals.value = "eblock";
}
function examinerBlockSelect(examinerid, newblockid) {
    let role = ""; // so we can maintain the examiner's role while moving blocks
    // remove the examiner from the original block
    if (editBlock.value && editBlock.value.ID !== newblockid) {
        const idx = editBlock.value.Examiners.findIndex((e) => e.ExaminerID === examinerid);
        role = editBlock.value.Examiners[idx].Role;
        axios.delete(`schedule/${editBlock.value.ID}/examiner/${examinerid}`).then(() => {
            editBlock.value.Examiners.splice(idx, 1);
            examinerBlockAddNew(examinerid, newblockid, role);
            selectedBlockId.value = newblockid;
        });
    } else {
        examinerBlockAddNew(examinerid, newblockid, role);
        selectedBlockId.value = newblockid;
    }
}
function examinerBlockAddNew(examinerid, newblockid, role) {
    // add the examiner to the new block (if selected)
    if (newblockid) {
        const block = schedule.value.find((s) => s.ID === newblockid);
        const examiner = examiners.value.find((e) => e.ID === examinerid);
        const assignment = {
            BlockID: block.ID,
            ExaminerID: examiner.ID,
            ExaminerName: examiner.FullName,
            Role: role,
            GradesSubmittedOn: null,
        };
        axios
            .put("schedule/examiner", assignment)
            .then(() => {
                block.Examiners.push(assignment);
                AbogAlert.success("Examiner assigned successfully");
            })
            .catch((error) => {
                AbogAlert.error(error.response.data);
            });
    }
}

// candidate edit modal stuff
function candidateEdit(id) {
    selectedBlockId.value = id;
    showmodals.value = "candidate";
}
function candidateSelect(id) {
    const candidate = candidates.value.find((c) => c.ID === id);
    const update = { ID: editBlock.value.ID, CandidateID: null, CandidateName: "" };
    if (candidate) {
        update.CandidateID = candidate.ID;
        update.CandidateName = candidate.FullName;
    }
    axios
        .put("schedule", update)
        .then(() => {
            editBlock.value.CandidateID = update.CandidateID;
            editBlock.value.CandidateName = update.CandidateName;
            AbogAlert.success("Candidate updated successfully");
        })
        .catch((error) => {
            AbogAlert.error(error.response.data);
        });
}

// examiner edit modal stuff
function examinerEdit(id) {
    if (id === null) {
        AbogAlert.error("Cannot edit examiners in an unscheduled block.");
        return;
    }
    selectedBlockId.value = id;
    showmodals.value = "examiner";
}
function examinerSelect(id) {
    selectedExaminerId.value = id;
}
function examinerAdd() {
    const selectedExaminer = examiners.value.find((e) => e.ID === selectedExaminerId.value);
    if (selectedExaminer && !editBlock.value.Examiners.some((e) => e.ExaminerID === selectedExaminer.ID)) {
        // AbogAlert.prompt("What role would you like to assign this examiner?").then((role) => {
        const assignment = {
            BlockID: editBlock.value.ID,
            ExaminerID: selectedExaminer.ID,
            ExaminerName: selectedExaminer.FullName,
            Role: "", // role || "", // default blank role, allow inline edits
            GradesSubmittedOn: null,
        };
        axios
            .put("schedule/examiner", assignment)
            .then(() => {
                editBlock.value.Examiners.push(assignment);
                selectedExaminerId.value = 0;
                AbogAlert.success("Examiner added successfully");
            })
            .catch((error) => {
                AbogAlert.error(error.response.data);
            });
        // });
    }
}
function examinerRemove() {
    const exidx = editBlock.value.Examiners.findIndex((e) => e.ExaminerID === selectedExaminerId.value);
    if (exidx >= 0) {
        axios
            .delete(`schedule/${editBlock.value.ID}/examiner/${selectedExaminerId.value}`)
            .then(() => {
                editBlock.value.Examiners.splice(exidx, 1);
                selectedExaminerId.value = 0;
                AbogAlert.success("Examiner removed successfully");
            })
            .catch((error) => {
                AbogAlert.error(error.response.data);
            });
    }
}

// case edit modal stuff
function caseEdit(id) {
    if (id === null) {
        AbogAlert.error("Cannot edit cases in an unscheduled block.");
        return;
    }
    selectedBlockId.value = id;
    showmodals.value = "case";
}
function caseSelect(id) {
    selectedCaseId.value = id;
}
function caseAdd() {
    const selectedCase = cases.value.find((c) => c.ID === selectedCaseId.value);
    if (!editBlock.value.Cases.some((c) => c.ID === selectedCase.ID)) {
        axios
            .put(`schedule/${editBlock.value.ID}/case/${selectedCase.ID}`)
            .then(() => {
                editBlock.value.Cases.push({
                    ID: selectedCase.ID,
                    Section: selectedCase.Section,
                    Description: selectedCase.Description,
                    CaseType: selectedCase.CaseType,
                    ExternalID: selectedCase.ExternalID,
                    // Questions: [],
                });
                selectedCaseId.value = 0;
                AbogAlert.success("Case added successfully");
            })
            .catch((error) => {
                AbogAlert.error(error.response.data);
            });
    }
}
function caseRemove() {
    const cidx = editBlock.value.Cases.findIndex((c) => c.ID === selectedCaseId.value);
    if (cidx >= 0) {
        axios
            .delete(`schedule/${editBlock.value.ID}/case/${selectedCaseId.value}`)
            .then(() => {
                editBlock.value.Cases.splice(cidx, 1);
                selectedCaseId.value = 0;
                AbogAlert.success("Case removed successfully");
            })
            .catch((error) => {
                AbogAlert.error(error.response.data);
            });
    }
}
</script>
<style scoped>
/* for some reason tailwind doesn't implement specific min widths in this version */
.min-w-40 {
    min-width: 10rem;
}
</style>
