<template>
    <!-- left panel -->
    <div class="min-w-fit pr-4 border border-white border-r-abogblue-200">
        <h2 class="font-bold text-2xl mb-4">Cases</h2>
        <!-- Main buttons -->
        <button class="blue-button mr-2" @click="showEditDialog(true)">
            <i class="fa fa-plus-circle" />
            Add
        </button>
        <button class="blue-button mx-2" :disabled="!currentCase" @click="showEditDialog(false)">
            <i class="fa fa-pencil" />
            Edit
        </button>
        <button class="blue-button mx-2" :disabled="!currentCase" @click="deleteCase()">
            <i class="fa-solid fa-trash" />
            Delete
        </button>
        <label class="blue-button inline-block cursor-pointer select-none mx-2">
            <i class="fa-solid fa-file-import"></i>
            Import
            <input type="file" accept=".txt" @change="importCases" hidden :disabled="importDialog.importing" />
        </label>
        <!-- Case Filter Control -->
        <div class="my-6">
            <span class="whitespace-nowrap text-abogblue-200">
                <span class="mr-2 font-bold">Sort By</span>
                <select v-model="caselistFilterText" class="border border-abogblue-200 rounded-lg px-4 py-2 text-abogblue-200">
                    <option value="ExternalID">ID</option>
                    <option value="Section">Section</option>
                    <option value="Description">Description</option>
                </select>
            </span>
        </div>
        <!-- case card repeater-->
        <div class="border border-abogblue-400 mb-2">
            <div class="p-2 bg-abogblue-400 text-white border-b flex justify-between whitespace-nowrap">
                <label class="mr-2 font-bold">Cases</label>
            </div>
            <div class="overflow-y-scroll fullscreenhmax">
                <div v-for="c in sortedCases" :key="c.ID" @click="selectCase(c.ID)" class="cursor-pointer border-b w-full relative" :class="{ 'bg-abogyellow-100': currentCase.ID === c.ID }">
                    <div class="p-2 w-full">
                        <div><strong>ID:</strong> {{ c.ExternalID }}</div>
                        <div><strong>Type:</strong> {{ c.CaseType }}</div>
                        <div><strong>Section:</strong> {{ c.Section }}</div>
                        <div><strong>Description:</strong> {{ c.Description }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Right panel -->
    <div class="ml-4 w-full mr-6">
        <h2 class="font-bold text-2xl mb-4">Questions</h2>
        <!-- Question Button Bar -->
        <button class="blue-button mr-2" :disabled="!currentCase" @click="setQuestionEditMode('Add')">
            <i class="fa fa-plus-circle" />
            Add
        </button>
        <button class="blue-button mx-2" :disabled="!currentQuestion" @click="setQuestionEditMode('Edit')">
            <i class="fa fa-pencil" />
            Edit
        </button>
        <button class="blue-button mx-2" :disabled="!currentQuestion" @click="deleteQuestion()">
            <i class="fa-solid fa-trash" />
            Delete
        </button>
        <!-- Question Navigator -->
        <div class="flex items-center justify-left whitespace-nowrap my-6">
            <button class="hollow-blue-button mx-2" @click="navFirst()">
                <i class="mr-2 fa fa-angles-left" />
                First
            </button>
            <button class="hollow-blue-button mx-2" @click="navPrev()">
                <i class="mr-2 fa fa-angle-left" />
                Prev
            </button>
            <span class="px-4 text-abogblue-200 font-bold">Question {{ currentQuestionIndex + 1 }} of {{ questionCount }}</span>
            <button class="hollow-blue-button mx-2" @click="navNext()">
                Next
                <i class="ml-2 fa fa-angle-right" />
            </button>
            <button class="hollow-blue-button mx-2" @click="navLast()">Last<i class="ml-2 fa fa-angles-right" /></button>
        </div>
        <!-- Question Detail -->
        <div class="mt-4">
            <!-- Buttons for edit mode-->
            <div v-if="questionEditMode != 'None'" class="flex items-center justify-center whitespace-nowrap my-6">
                <button @click="saveQuestion()" class="purple-button mx-2">Save</button>
                <button @click="setQuestionEditMode('Cancel')" class="blue-button mx-2">Cancel</button>
            </div>
            <!-- Question and Answer-->
            <span class="flex justify-between">
                <label class="font-bold">Question Text</label>
                <span>
                    <AbogLoading v-if="uploading" class="w-6 mx-2" />
                    <label class="font-bold mr-4 hover:text-abogblue-200" v-if="questionEditMode == 'None'">
                        <i class="fa-regular fa-image"></i>
                        Upload Media
                        <input type="file" data-answer="false" @change="uploadMedia" hidden :disabled="!currentQuestion || uploading" />
                    </label>
                    <button class="font-bold mr-2 hover:text-abogblue-200" @click="promptDeleteMedia(false)" v-if="questionEditMode == 'None'">
                        <i class="fa-solid fa-trash"></i>
                        Delete Media
                    </button>
                </span>
            </span>
            <div v-if="currentQuestion" class="bg-aboggray-100 rounded-lg py-2 px-4 mb-4">
                <div v-if="questionEditMode == 'None'">
                    <StructuredCase
                        :data="currentQuestion.Question"
                        :casetype="currentCase.CaseType"
                        @sendfullscreenrequest="sendFullScreen"
                        :fullscreenmedia="fullScreenMedia"
                        :media="currentQuestion.Media.filter(m => m.Answer === false).map((ele) => ({ ID: ele.ID, URL: `media/${ele.ID}` }))"
                    />
                </div>
                <div v-else>
                    <div>
                        <AbogEditor v-model="currentQuestion.Question" />
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="w-full flex bg-aboggray-100 rounded-lg py-10" />
            </div>
            <span class="flex justify-between">
                <label class="font-bold">Answer Text</label>
                <span>
                    <label class="font-bold mr-4 hover:text-abogblue-200" v-if="questionEditMode == 'None'">
                        <i class="fa-regular fa-image"></i>
                        Upload Media
                        <input type="file" data-answer="true" @change="uploadMedia" hidden :disabled="!currentQuestion || uploading" />
                    </label>
                    <button class="font-bold mr-2 hover:text-abogblue-200" @click="promptDeleteMedia(true)" v-if="questionEditMode == 'None'">
                        <i class="fa-solid fa-trash"></i>
                        Delete Media
                    </button>
                </span>
            </span>
            <div v-if="currentQuestion" class="bg-abogyellow-100 rounded-lg py-2 px-4 mb-4">
                <div v-if="questionEditMode == 'None'">
                    <div class="w-full rounded-lg p-4">
                        <div class="flex" :class="{ 'flex-col': fullScreenMedia }">
                            <div v-if="currentQuestion.Answer" class="w-full pr-2 whitespace-pre-wrap" v-html="currentQuestion.Answer"></div>
                            <div v-if="currentQuestion.Media.filter(m => m.Answer === true).map((ele) => ({ ID: ele.ID, URL: `media/${ele.ID}` })).length > 0" class="relative pl-2" :class="{ 'order-first w-full': fullScreenMedia }">
                                <AuthMedia
                                    v-for="med in currentQuestion.Media.filter(m => m.Answer === true).map((ele) => ({ ID: ele.ID, URL: `media/${ele.ID}` }))"
                                    :key="med.ID"
                                    :src="med.URL"
                                    @click="sendFullScreen(med.ID,med.ID !== fullScreenMedia)"
                                    class="object-contain"
                                    :class="{ 'w-full fullscreenhmax': med.ID === fullScreenMedia, 'w-40 h-40': !fullScreenMedia, hidden: fullScreenMedia && med.ID !== fullScreenMedia }"
                                />
                                <div v-if="fullScreenMedia" @click="sendFullScreen(fullScreenMedia,false)" class="z-20 absolute top-0 right-0 w-10 h-10 p-2 font-bold cursor-pointer"><i class="text-2xl fa fa-times" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <AbogEditor v-model="currentQuestion.Answer" />
                </div>
            </div>
            <div v-else>
                <div class="w-full flex bg-abogyellow-100 rounded-lg py-10" />
            </div>
            <label v-if="blueprint.length > 0" class="font-bold">Blueprint</label>
            <div v-if="currentQuestion && blueprint.length > 0" class="bg-abogyellow-100 rounded-lg py-2 px-4 mb-4">
                <div v-if="questionEditMode == 'None'">
                    <span class="whitespace-pre-wrap p-4" v-html="blueprintLookup[currentQuestion.ID_B]"></span>
                </div>
                <div v-else>
                    <span class="whitespace-nowrap select-arrow">
                        <select v-model="currentQuestion.ID_B" class="font-bold w-auto ml-2">
                            <option :value="null" class="font-normal"></option>
                            <option v-for="bp in blueprint" :key="bp.ID" :value="bp.ID" class="font-normal">{{ bp.Description }}</option>
                        </select>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- edit dialog -->
    <AbogEditDialog v-if="editDialog.show" :isAdd="editDialog.isAdd" entity="Case" @close="closeEditDialog" :saveCallback="saveCase">
        <div class="mb-2">
            <label>
                <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">ID</span>
                <input type="text" v-model.trim="currentCase.ExternalID" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
            </label>
        </div>
        <div class="mb-2">
            <label>
                <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">Type</span>
                <input type="text" v-model.trim="currentCase.CaseType" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
            </label>
        </div>
        <div class="mb-2">
            <label>
                <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">Section</span>
                <input type="text" v-model.trim="currentCase.Section" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
            </label>
        </div>
        <div class="mb-2">
            <label>
                <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">Description</span>
                <input type="text" v-model.trim="currentCase.Description" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
            </label>
        </div>
    </AbogEditDialog>
    <!-- ImportDialog Dialog -->
    <AbogModal :title="importDialog.title" v-if="importDialog.show" :width="40" @close="closeImportDialog()">
        <label v-if="importDialog.importing" class="flex items-center justify-center whitespace-nowrap my-6">
            Importing...
            <AbogLoading class="w-12 mx-2" />
        </label>
        <div v-if="!importDialog.importing">
            <div class="mb-2">
                <p class="whitespace-pre-wrap">
                    {{ importDialog.message }}
                </p>
            </div>
            <div class="text-right py-4">
                <button @click="closeImportDialog()" class="blue-button mx-2">OK</button>
            </div>
        </div>
    </AbogModal>
</template>
<script setup>
import { inject, ref, computed, onMounted } from "vue";
import AbogAlert from "@/pages/components/AbogAlert";
import AbogEditDialog from "@/pages/components/AbogEditDialog";
import AbogModal from "@/pages/components/AbogModal";
import StructuredCase from "@/pages/components/casetemplates/StructuredCase";
import AbogEditor from "@/pages/components/AbogEditor";
import AbogLoading from "@/pages/components/AbogLoading";
import AuthMedia from "@/pages/components/AuthMedia";
const axios = inject("axios");
const cases = ref([]);
const currentCase = ref(null);
const caselistFilterText = ref("ExternalID");
const currentQuestionIndex = ref(-1);
const currentQuestion = ref(null);
const questionEditMode = ref("None"); // values are None, Add, Edit
const fullScreenMedia = ref(null);
const editDialog = ref({ show: false, isAdd: false });
const importDialog = ref({ show: false, importing: false, title: "", message: "" });
const uploading = ref(false);
const blueprint = ref([]);

onMounted(() => {
    loadBlueprint();
    loadCases(null);
});

function loadBlueprint() {
    axios.get("blueprint").then((response) => {
        blueprint.value = response.data;
    });
}

function loadCases(caseIDToSelect) {
    axios.get("Cases/?caseQuestions=true&caseListCases=false").then((response) => {
        cases.value = response.data;
        if (cases.value.length > 0) {
            if (caseIDToSelect) {
                const lastSelectedQuestionIndex = currentQuestionIndex.value;
                selectCase(caseIDToSelect);
                if (lastSelectedQuestionIndex > -1) {
                    currentQuestionIndex.value = lastSelectedQuestionIndex;
                    nav();
                }
            } else selectCase(cases.value[0].ID);
        }
    });
}

const blueprintLookup = computed(() => {
    return Object.assign({}, ...blueprint.value.map((bp) => ({ [bp.ID]: bp.Description })));
});

const sortedCases = computed(() => {
    const cc = structuredClone(cases.value);
    if (caselistFilterText.value === "ExternalID") {
        return cc.sort((a, b) => (a.ExternalID > b.ExternalID ? 1 : b.ExternalID > a.ExternalID ? -1 : 0));
    } else if (caselistFilterText.value === "Section") {
        return cc.sort((a, b) => (a.Section > b.Section ? 1 : b.Section > a.Section ? -1 : 0));
    } else if (caselistFilterText.value === "Description") {
        return cc.sort((a, b) => (a.Description > b.Description ? 1 : b.Description > a.Description ? -1 : 0));
    }
    return {};
});

const questionCount = computed(() => {
    return currentCase.value && currentCase.value.Questions ? currentCase.value.Questions.length : 0;
});

function selectCase(cid) {
    currentCase.value = cases.value.find((c) => c.ID === cid);
    currentQuestionIndex.value = -1;
    currentQuestion.value = null;
    navFirst();
}

function showEditDialog(add) {
    if (add) currentCase.value = { ID: 0, Type: "", Section: "", Description: "" };
    editDialog.value = { show: true, isAdd: add };
}

function closeEditDialog() {
    editDialog.value = { show: false, isAdd: false };
    loadCases(currentCase.value.ID);
}

async function saveCase() {
    let result = null;
    await axios
        .put("case/update", currentCase.value)
        .then((response) => {
            loadCases(response.data.ID);
        })
        .catch((error) => {
            result = error.response.data;
        });
    return result;
}

function deleteCase() {
    AbogAlert.confirm("Delete Case " + currentCase.value.Description + "?").then(() => {
        axios
            .delete(`case/${currentCase.value.ID}`)
            .then(() => {
                loadCases(null);
            })
            .catch((error) => {
                AbogAlert.error(error.response.data);
            });
    });
}

function closeImportDialog() {
    importDialog.value = { show: false, importing: false, title: "", message: "" };
}

function importCases(e) {
    importDialog.value = { show: true, importing: true, title: "Import Cases", message: "" };
    const file = e.target.files[0];
    axios
        .post("ImportCases", file)
        .then((response) => {
            let titleMessage = "";
            switch (response.data.Result) {
                case 0:
                    titleMessage = "Case Import Success!";
                    break;
                case 1:
                    titleMessage = "Case Import Completed with Errors";
                    break;
                case 2:
                    titleMessage = "Case Import Failed";
                    break;
            }
            importDialog.value = { show: true, importing: false, title: titleMessage, message: response.data.Message };
            e.target.value = null;
            loadCases();
        })
        .catch((error) => {
            importDialog.value = { show: true, importing: false, title: "Error", message: error.response.data };
        });
}

function setQuestionEditMode(mode) {
    closeFullScreen();
    if (mode == "Cancel") {
        if (questionEditMode.value == "Add") nav(); // just navigate back to the last question the user was looking at before they clicked Add
        else if (questionEditMode.value == "Edit") loadCases(currentCase.value.ID); // reload to clear out any changes
        mode = "None";
    }
    if (mode == "Add") currentQuestion.value = { ID: 0, CaseID: currentCase.value.ID, Question: "", Answer: "", Notes: "" };
    questionEditMode.value = mode;
}

function saveQuestion() {
    axios
        .put("case/questions/update", currentQuestion.value)
        .then((response) => {
            AbogAlert.success("Question Saved");
            currentQuestion.value = response.data;
            if (questionEditMode.value == "Add") {
                currentCase.value.Questions.push(currentQuestion.value);
                currentQuestionIndex.value = currentCase.value.Questions.indexOf(currentQuestion.value);
            }
            setQuestionEditMode("None");
        })
        .catch((error) => {
            AbogAlert.error(error.response.data);
        });
}

function deleteQuestion() {
    AbogAlert.confirm("Delete this question?").then(() => {
        axios
            .delete(`question/${currentQuestion.value.ID}`)
            .then(() => {
                AbogAlert.success("Question Deleted");
                currentCase.value.Questions.splice(currentQuestionIndex.value, 1);
                if (currentCase.value.Questions.length == 0) {
                    currentQuestionIndex.value = -1;
                    currentQuestion.value = null;
                } else {
                    navPrev();
                }
            })
            .catch((error) => {
                AbogAlert.error(error.response.data);
            });
    });
}

function navFirst() {
    closeFullScreen();
    if (questionCount.value > 0) {
        currentQuestionIndex.value = 0;
        nav();
    }
}

function navPrev() {
    closeFullScreen();
    if (questionCount.value > 0) {
        currentQuestionIndex.value = Math.max(0, currentQuestionIndex.value - 1);
        nav();
    }
}

function navNext() {
    closeFullScreen();
    if (questionCount.value > 0) {
        currentQuestionIndex.value = Math.min(questionCount.value - 1, currentQuestionIndex.value + 1);
        nav();
    }
}

function navLast() {
    closeFullScreen();
    if (questionCount.value > 0) {
        currentQuestionIndex.value = currentCase.value.Questions.length - 1;
        nav();
    }
}

function nav() {
    currentQuestion.value = currentCase.value.Questions[currentQuestionIndex.value];
}

function sendFullScreen(mediaID, full) {
    fullScreenMedia.value = full ? mediaID : null;
}

function closeFullScreen() {
    fullScreenMedia.value = null;
}

function uploadMedia(e) {
    closeFullScreen();
    uploading.value = true;
    const file = e.target.files[0];
    const answer = e.target.dataset.answer;
    axios
        .post(`question/${currentQuestion.value.ID}/media/import?filename=${file.name}&answer=${answer}`, file)
        .then((response) => {
            e.target.value = null;
            currentQuestion.value.Media.push(response.data);
            AbogAlert.success("Media Uploaded");
        })
        .catch((error) => {
            AbogAlert.error(error.response.data);
        })
        .finally(() => {
            uploading.value = false;
        });
}

function promptDeleteMedia(answer) {
    closeFullScreen();
    if (!currentQuestion.value) return;
    if (currentQuestion.value.Media.filter(m => m.Answer === answer).length == 1) {
        AbogAlert.confirm("Delete the media file?").then(() => {
            var idx = 1;
            var deleteindex = 0;
            currentQuestion.value.Media.forEach((m) => {
                if (m.Answer === answer) {idx--;}
                if (idx > 0) {deleteindex++;}
            });
            deleteMedia(deleteindex);
        });
    } else if (currentQuestion.value.Media.filter(m => m.Answer == answer).length > 1) {
        AbogAlert.prompt(`Which media file would you like to delete? (Enter a number between 1 and ${currentQuestion.value?.Media.filter(m => m.Answer === answer).length})`).then((idx) => {
           var deleteindex = 0;
           currentQuestion.value.Media.forEach((m) => {
                if (m.Answer === answer) {idx--;}
                if (idx > 0) {deleteindex++;}
            });
            deleteMedia(deleteindex);
        });
    }

    function deleteMedia(mediaIndex) {
        uploading.value = true;
        axios
            .delete(`media/${currentQuestion.value.Media[mediaIndex].ID}`)
            .then(() => {
                currentQuestion.value.Media.splice(mediaIndex, 1);
                AbogAlert.success("Media Deleted");
            })
            .catch((error) => {
                AbogAlert.error(error.response.data);
            })
            .finally(() => {
                uploading.value = false;
            });
    }
}
</script>
<style scoped>
.fullscreenhmax {
    max-height: 70vh;
}
</style>
