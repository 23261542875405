<template>
    <div class="w-full p-4 md:w-1/2 md:mx-auto">
        <h2 class="font-head text-2xl mb-2">Recover Password</h2>
        <div v-if="step === steps.load" class="flex justify-center items-center">
            <AbogLoading class="w-10" />
            <span class="ml-4 text-xl">Loading</span>
        </div>
        <div v-else-if="step === steps.start">
            <form @submit.prevent="reset">
                <label>
                    <span class="w-full block mb-2 whitespace-nowrap">Enter your email</span>
                    <input v-model="useremail" type="text" class="w-full border px-4 py-2 block mb-2" />
                </label>
                <button type="submit" class="w-full hollow-blue-button mb-2">Send a recovery link</button>
            </form>
        </div>
        <div v-else-if="step === steps.done">
            <span class="w-full block mb-2">A link has been sent to the email provided. The link is only valid for 2 hours. Click on the link in your email to reset your password.</span>
        </div>
        <div v-else-if="step === steps.fail">
            <span class="w-full block mb-2">Something went wrong processing your request. If this continues please contact us.</span>
            <button class="hollow-blue-button" @click="retry">Try again</button>
        </div>
        <div class="text-right">
            <RouterLink to="/login" class="text-abogblue-300 hover:text-abogblue-200 hover:underline">Back to login</RouterLink>
        </div>
    </div>
</template>
<script setup>
import { ref, inject } from "vue";
import AbogLoading from "@/pages/components/AbogLoading";
const axios = inject("axios");

const steps = {
    start: 0,
    load: 1,
    done: 2,
    fail: 3,
};

const useremail = ref("");
const step = ref(steps.start);

function reset() {
    if (!useremail.value) {
        alert("An email must be provided.");
        return;
    }
    step.value = steps.load;
    axios
        .post("Authentication/RequestPasswordReset", useremail.value)
        .then(() => {
            step.value = steps.done;
        })
        .catch(() => {
            step.value = steps.fail;
        });
}

function retry() {
    useremail.value = "";
    step.value = steps.start;
}
</script>
