<template>
    <div>
        <h2 class="font-bold text-2xl mb-4">Examiners</h2>
        <!-- Main buttons -->
        <button class="blue-button mr-2" @click="showEditDialog(true)">
            <i class="fa fa-plus-circle" />
            Add
        </button>
        <button class="blue-button mx-2" :disabled="!currentExaminer" @click="showEditDialog(false)">
            <i class="fa fa-pencil" />
            Edit
        </button>
        <button class="blue-button mx-2" :disabled="!currentExaminer" @click="deleteExaminer()">
            <i class="fa-solid fa-trash" />
            Delete
        </button>
        <label class="blue-button inline-block cursor-pointer select-none mx-2">
            <i class="fa-solid fa-file-import"></i>
            Import
            <input type="file" accept=".csv" @change="importExaminers" hidden :disabled="importDialog.importing" />
        </label>
        <button class="blue-button mx-2" @click="showRegistrationDialog()">
            <i class="fa-solid fa-envelope"></i>
            Register
        </button>
        <button class="blue-button mx-2" :disabled="!currentExaminer" @click="showPasswordDialog()">
            <i class="fa-solid fa-key"></i>
            Reset Password
        </button>
        <div class="my-4">
            <AbogTable :data="examiners" :columns="tableColumns" initialKey="ID" @selected="selectExaminer" />
        </div>
        <!-- edit dialog -->
        <AbogEditDialog v-if="editDialog.show" :isAdd="editDialog.isAdd" entity="Examiner"
            @close="closeEditDialog" :saveCallback="saveExaminer">
            <div class="mb-2">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">Full Name</span>
                    <input type="text" v-model="currentExaminer.FullName" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
            <div class="mb-2">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">Email</span>
                    <input type="text" v-model.trim="currentExaminer.Email" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
            <div class="mb-2">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">ID</span>
                    <input type="text" v-model.trim="currentExaminer.ExternalID" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
        </AbogEditDialog>
        <!-- ImportDialog Dialog -->
        <AbogModal :title="importDialog.title" v-if="importDialog.show" :width="40" @close="closeImportDialog()">
            <label v-if="importDialog.importing" class="flex items-center justify-center whitespace-nowrap my-6">
                Importing...
                <AbogLoading class="w-12 mx-2" />
            </label>
            <div v-if="!importDialog.importing">
                <div class="mb-2">
                    <p class="whitespace-pre-wrap">
                        {{ importDialog.message }}
                    </p>
                </div>
                <div class="text-right py-4">
                    <button @click="closeImportDialog()" class="blue-button mx-2">OK</button>
                </div>
            </div>
        </AbogModal>
        <!-- Registration Dialog -->
        <AbogModal :title="'Register'" v-if="rdShow" :width="35" @close="closeRegistrationDialog()">
            <div class="mb-2" v-if="!(rdSending || rdDone) && currentExaminer">
                <div class="py-2">
                    <label>
                        <input type="radio" class="mx-2" v-model="rdSendAll" :value="true" />
                        Send registration emails to all unregistered Examiners
                    </label>
                </div>
                <div class="py-2">
                    <label>
                        <input type="radio" class="mx-2" v-model="rdSendAll" :value="false" />
                        Send registration email only to {{ currentExaminer.FullName }}
                    </label>
                </div>
            </div>
            <div class="mb-2" v-if="!(rdSending || rdDone) && !currentExaminer">
                <label> Send registration emails to all unregistered Examiners? </label>
            </div>
            <label class="mb-2" v-if="rdSending">
                Sending {{ rdSendAll ? rdCount + 1 : "" }}...
                <AbogLoading class="w-6 mx-2" />
            </label>
            <div class="mb-2" v-if="rdDone">
                <p v-if="rdCount > 0">{{ rdCount == 1 ? "Message" : rdCount + " messages" }} sent.</p>
                <p v-if="rdErrorCount > 0">Error sending {{ rdErrorCount }} {{ rdErrorCount == 1 ? "message" : "messages" }}.</p>
                <p v-if="rdError">Error details: {{ rdError }}</p>
            </div>
            <div class="text-right py-4">
                <button @click="sendRegistrationBtnClick()" class="blue-button mx-2" v-if="!rdDone" :disabled="rdSending">Send</button>
                <button @click="closeRegistrationDialog()" class="blue-button mx-2" :disabled="rdSending">{{ rdDone ? "OK" : "Cancel" }}</button>
            </div>
        </AbogModal>
        <!-- Password Dialog -->
        <AbogModal :title="'Reset Password'" v-if="pdShow" :width="35" @close="closePasswordDialog()">
            <div class="mb-2">
                <label v-if="!(pdSending || pdDone)"> Send password reset email to {{ currentExaminer.FullName }}? </label>
                <label v-if="pdSending">
                    Sending...
                    <AbogLoading class="w-6 mx-2" />
                </label>
                <div v-if="pdDone">
                    <p v-if="!pdError">Message sent to {{ currentExaminer.FullName }}.</p>
                    <p v-if="pdError">Error sending message: {{ pdError }}</p>
                </div>
            </div>
            <div class="text-right py-4">
                <button @click="sendPasswordBtnClick()" class="blue-button mx-2" v-if="!pdDone" :disabled="pdSending">Send</button>
                <button @click="closePasswordDialog()" class="blue-button mx-2" :disabled="pdSending">{{ pdDone ? "OK" : "Cancel" }}</button>
            </div>
        </AbogModal>
    </div>
</template>
<script setup>
import { inject, ref, onMounted } from "vue";
import AbogLoading from "@/pages/components/AbogLoading";
import AbogModal from "@/pages/components/AbogModal";
import AbogTable from "@/pages/components/AbogTable";
import AbogEditDialog from "@/pages/components/AbogEditDialog";
import AbogAlert from "../components/AbogAlert";
const axios = inject("axios");
const examiners = ref([]);
const currentExaminer = ref(null);
const editDialog = ref({ show: false, isAdd: false });
const importDialog = ref({ show: false, importing: false, title: "", message: "" });

// registration dialog state
const rdShow = ref(false);
const rdSendAll = ref(false);
const rdSending = ref(false);
const rdDone = ref(false);
const rdCount = ref(0);
const rdErrorCount = ref(0);
const rdError = ref("");

// password dialog state
const pdShow = ref(false);
const pdSending = ref(false);
const pdDone = ref(false);
const pdError = ref("");

const tableColumns = [
    { prop: "FullName", display: "Name", align: "left" },
    { prop: "Email", display: "Email", align: "left" },
    { prop: "ExternalID", display: "ID", align: "left" },
    { prop: "Registered", display: "Registered", align: "left" },
    { prop: "ID", hide: true, key: true },
];

onMounted(() => {
    loadExaminers();
});

function loadExaminers() {
    axios.get("examiners").then((response) => {
        examiners.value = response.data;
    });
}

function selectExaminer(id) {
    currentExaminer.value = examiners.value.find((e) => e.ID === id);
}

function showEditDialog(add) {
    if (add) currentExaminer.value = { ID: 0, FullName: "", Email: "", ExternalID: "" };
    editDialog.value = { show: true, isAdd: add };
}

function closeEditDialog() {
    editDialog.value = { show: false, isAdd: false };
}


async function saveExaminer() {
    let result = null;
    await axios
        .put("examiner", currentExaminer.value)
        .then((response) => {
            loadExaminers();
            currentExaminer.value = response.data;
        })
        .catch((error) => {
            result = error.response.data;
        });
    return result;
}

function deleteExaminer()  {
    axios
        .get(`examiner/${currentExaminer.value.ID}/assignedblockcount`)
        .then((response) => {
            const blockCount = response.data;
            const msg = (blockCount === 0) ? `Delete examiner ${currentExaminer.value.FullName}?` :
                `Examiner ${currentExaminer.value.FullName} is assigned to ${blockCount} schedule blocks.
                Deleting them will permanently remove them from the schedule. Continue?`
            AbogAlert.confirm(msg).then(() => {
                axios
                    .delete(`examiner/${currentExaminer.value.ID}`)
                    .then(() =>{
                        loadExaminers();
                    })
                    .catch((error) => {
                        AbogAlert.error(error.response.data);
            });
        });
    });
}

function closeImportDialog() {
    importDialog.value = { show: false, importing: false, title: "", message: "" };
}

function importExaminers(e) {
    importDialog.value = { show: true, importing: true, title: "Import Examiners", message: "" };
    const file = e.target.files[0];
    axios
        .post("ImportExaminers", file)
        .then((response) => {
            let titleMessage = "";
            switch (response.data.Result) {
                case 0:
                    titleMessage = "Examiner Import Success!";
                    break;
                case 1:
                    titleMessage = "Examiner Import Completed with Errors";
                    break;
                case 2:
                    titleMessage = "Examiner Import Failed";
                    break;
            }
            importDialog.value = { show: true, importing: false, title: titleMessage, message: response.data.Message };
            e.target.value = null;
            loadExaminers();
        })
        .catch((error) => {
            importDialog.value = { show: true, importing: false, title: "Error", message: error.response.data };
        });
}

function showRegistrationDialog() {
    rdShow.value = true;
    rdSendAll.value = true;
    rdSending.value = false;
    rdDone.value = false;
    rdError.value = "";
    rdCount.value = 0;
    rdErrorCount.value = 0;
}

function closeRegistrationDialog() {
    rdShow.value = false;
}

function sendRegistrationBtnClick() {
    if (rdSendAll.value) {
        sendRegistrationEmailToAll();
    } else {
        if (currentExaminer.value.Registered) {
            alert(`${currentExaminer.value.FullName} is already registered.`);
        } else {
            sendRegistrationEmailToCurrent();
        }
    }
}

function sendRegistrationEmailToCurrent() {
    rdSending.value = true;
    sendRegistrationEmail(currentExaminer.value.Email).then(() => {
        rdSending.value = false;
        rdDone.value = true;
    });
}

async function sendRegistrationEmailToAll() {
    rdSending.value = true;
    for (let i = 0; i < examiners.value.length; i++) {
        let ex = examiners.value[i];
        if (!ex.Registered) {
            await sendRegistrationEmail(ex.Email);
        }
    }
    rdSending.value = false;
    rdDone.value = true;
}

async function sendRegistrationEmail(to) {
    await axios
        .post("Authentication/SendExaminerRegistration", to)
        .then(() => {
            rdCount.value += 1;
        })
        .catch((error) => {
            rdErrorCount.value += 1;
            rdError.value = error.response.data.split("\n")[0];
        });
}

function showPasswordDialog() {
    pdShow.value = true;
    pdSending.value = false;
    pdDone.value = false;
    pdError.value = "";
}

function closePasswordDialog() {
    pdShow.value = false;
}

function sendPasswordBtnClick() {
    pdSending.value = true;
    sendPasswordEmail(currentExaminer.value.Email);
}

async function sendPasswordEmail(to) {
    await axios
        .post("Authentication/RequestPasswordReset", to)
        .then(() => {
            pdError.value = "";
        })
        .catch((error) => {
            pdError.value = error.response.data.split("\n")[0];
        })
        .finally(() => {
            pdSending.value = false;
            pdDone.value = true;
        });
}
</script>
