<template>
    <BreadCrumb current="Documents" />
    <div class="md:mx-12 lg:mx-auto lg:w-1/2">
        <AuthFile v-for="doc in documents" :key="doc.ID" class="p-2 border-t text-abogblue-300" :file="doc" />
    </div>
    <div v-if="loaderror" class="text-center p-4">
        <h3 class="text-lg font-bold">Error Loading Schedule</h3>
        <p>Please reload to try again. If errors persist contact your exam administrator.</p>
    </div>
</template>
<script setup>
import { inject, ref, onMounted } from "vue";
import BreadCrumb from "@/pages/components/BreadCrumb";
import AuthFile from "@/pages/components/AuthFile";
const axios = inject("axios");
const documents = ref([]);
const loaderror = ref(false);

onMounted(() => {
    loaderror.value = false;
    axios
        .get("documents")
        .then((response) => {
            response.data.sort((a, b) => (a.FileName.toUpperCase() > b.FileName.toUpperCase() ? 1 : a.FileName.toUpperCase() < b.FileName.toUpperCase() ? -1 : 0));
            documents.value = response.data;
        })
        .catch(() => {
            loaderror.value = true;
        });
});
</script>
