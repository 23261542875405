<template>
    <div class="w-full">
        <div class="flex justify-between items-center">
            <h2 class="font-bold text-2xl">Grades</h2>
        </div>
        <div v-if="grades.length > 0">
            <div class="flex items-center justify-between mb-4 whitespace-nowrap">
                <div>
                    <label class="select-arrow">
                        <span class="mr-2">View:</span>
                        <select class="font-bold" v-model="view">
                            <option class="font-normal">Candidate</option>
                            <option class="font-normal">Examiner</option>
                        </select>
                    </label>
                    <label class="select-arrow ml-12">
                        <span class="mr-2">Date:</span>
                        <select class="font-bold" v-model="filter.date">
                            <option v-for="d in dates" :key="d" class="font-normal" :value="d">{{ formatDate(d) }}</option>
                        </select>
                    </label>
                    <label v-if="view !== 'Examiner'" class="select-arrow ml-12">
                        <span class="mr-2">Meridiem:</span>
                        <select class="font-bold" v-model="filter.time">
                            <option class="font-normal">All</option>
                            <option class="font-normal">AM</option>
                            <option class="font-normal">PM</option>
                        </select>
                    </label>
                    <label class="ml-12">
                        <span class="mr-2">{{ view }}:</span>
                        <input type="text" class="border-b border-aboggray-700 -mr-4" v-model="filter.search" />
                        <i :class="filter.search !== '' ? 'fas fa-xmark cursor-pointer' : 'fas fa-search pointer-events-none'" @click="filter.search = ''"></i>
                    </label>
                    <label class="ml-12">
                        <input type="checkbox" v-model="filter.showIncompleteOnly" />
                        Only Show No Grades Submitted
                    </label>
                </div>
                <div class="flex">
                    <button class="relative purple-button" @click="loadGrades()">
                        <div class="absolute bg-white opacity-25 h-full top-0 left-0" :style="`width: ${refreshTimer}%`"></div>
                        Refresh
                    </button>
                    <button class="blue-button inline-block ml-4" @click="exportGrades">Export</button>
                </div>
            </div>
            <div>
                <table v-if="view === 'Candidate'" class="border-separate border-spacing-0 border border-aboggray-700">
                    <tr class="bg-abogblue-400 text-white">
                        <th class="px-4 py-2">Canidate</th>
                        <th class="px-4 py-2">Time</th>
                        <th class="px-4 py-2">Room</th>
                        <th class="px-4 py-2">Examiner</th>
                        <th class="px-4 py-2">Entered</th>
                        <th class="px-4 py-2">Submitted</th>
                        <th class="px-4 py-2">Unsubmit</th>
                    </tr>
                    <template v-for="group in gradeViewByCandidate" :key="group.ID">
                        <tr v-for="(examiner, i) in group.Examiners" :key="examiner.ExaminerID">
                            <td v-if="i === 0" class="border border-aboggray-700 px-4 py-2" :rowspan="group.Examiners.length">
                                {{ group.Name }}
                            </td>
                            <td class="border px-4 py-2 border-l-aboggray-700" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Examiners.length - 1 }">
                                {{ examiner.StartTime }}
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Examiners.length - 1 }">
                                {{ examiner.RoomNumber }}
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Examiners.length - 1 }">
                                {{ examiner.ExaminerName }}
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Examiners.length - 1 }">
                                <div>
                                    <div class="border overflow-hidden" :class="examiner.SubmittedOn === null ? 'border-abogblue-400 bg-abogblue-100' : 'border-aboggreen-500'">
                                        <div :class="examiner.SubmittedOn === null ? 'bg-abogblue-400' : 'bg-aboggreen-300'" :style="`width: ${(examiner.gradeCount / examiner.totalQuestions) * 100}%`">
                                            <p class="text-white text-center w-20">{{ examiner.gradeCount }}/{{ examiner.totalQuestions }}</p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Examiners.length - 1 }">
                                {{ examiner.SubmittedOn === null ? "No grade submitted" : formatTime(examiner.SubmittedOn) }}
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Examiners.length - 1 }">
                                <span v-if="!examiner.SubmittedOn">N/A</span>
                                <button v-else class="blue-button" @click="unsubmitGrades(examiner.ScheduleBlockID, examiner.ExaminerID)">Unsubmit</button>
                            </td>
                        </tr>
                    </template>
                </table>
                <table v-if="view === 'Examiner'" class="border-separate border-spacing-0 border border-aboggray-700">
                    <tr class="bg-abogblue-400 text-white">
                        <th class="px-4 py-2">Examiner</th>
                        <th class="px-4 py-2">Time</th>
                        <th class="px-4 py-2">Room</th>
                        <th class="px-4 py-2">Candidate</th>
                        <th class="px-4 py-2">Progress</th>
                        <th class="px-4 py-2">Time Submitted</th>
                        <th class="px-4 py-2">Unsubmit</th>
                    </tr>
                    <template v-for="group in gradeViewByExaminer" :key="group.ID">
                        <tr v-for="(candidate, i) in group.Candidates" :key="candidate.RoomNumber">
                            <td v-if="i === 0" class="border border-aboggray-700 px-4 py-2" :rowspan="group.Candidates.length">{{ group.Name }}</td>
                            <td class="border px-4 py-2 border-l-aboggray-700" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Candidates.length - 1 }">
                                {{ candidate.StartTime }}
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Candidates.length - 1 }">
                                {{ candidate.RoomNumber }}
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Candidates.length - 1 }">
                                {{ candidate.CandidateName }}
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Candidates.length - 1 }">
                                <div>
                                    <div class="border overflow-hidden" :class="candidate.SubmittedOn === null ? 'border-abogblue-400 bg-abogblue-100' : 'border-aboggreen-500'">
                                        <div :class="candidate.SubmittedOn === null ? 'bg-abogblue-400' : 'bg-aboggreen-300'" :style="`width: ${(candidate.gradeCount / candidate.totalQuestions) * 100}%`">
                                            <p class="text-white text-center w-20">{{ candidate.gradeCount }}/{{ candidate.totalQuestions }}</p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Candidates.length - 1 }">
                                {{ candidate.SubmittedOn === null ? "No grade submitted" : formatTime(candidate.SubmittedOn) }}
                            </td>
                            <td class="border px-4 py-2" :class="{ 'border-t-aboggray-700': i === 0, 'border-b-aboggray-700': i === group.Candidates.length - 1 }">
                                <span v-if="!candidate.SubmittedOn">N/A</span>
                                <button v-else class="blue-button" @click="unsubmitGrades(candidate.ScheduleBlockID, group.ID)">Unsubmit</button>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, inject, computed, watch } from "vue";
import AbogDate from "@/pages/components/AbogDate";
import AbogAlert from "@/pages/components/AbogAlert";
const axios = inject("axios");

const grades = ref([]);
const view = ref("Candidate");
const filter = ref({ time: "All", date: "", search: "", showIncompleteOnly: false });
const refreshTimer = ref(0);
let refreshTimerId;

watch(view, () => {
    filter.value.search = "";
});

onMounted(() => {
    loadGrades();
    startRefreshTimer();
});

onUnmounted(() => clearInterval(refreshTimerId));

function loadGrades() {
    refreshTimer.value = 0;
    axios.get("grades").then((response) => {
        response.data.forEach((s) => {
            s.StartTime = s.StartTime.slice(0, -3);
            s.EndTime = s.EndTime.slice(0, -3);
        });
        if (filter.value.date === "" && response.data.length > 0) {
            filter.value.date = response.data[0].Date;
        }
        grades.value = response.data;
    });
}

const filteredGrades = computed(() => {
    return grades.value.filter((g) => {
        if (g.Date !== filter.value.date) return false;
        if (view.value !== "Examiner" && filter.value.time === "AM" && g.StartTime >= "12:00") return false;
        if (view.value !== "Examiner" && filter.value.time === "PM" && g.StartTime < "12:00") return false;
        if (view.value === "Examiner" && !g.ExaminerName.toUpperCase().includes(filter.value.search.toUpperCase())) return false;
        if (view.value === "Candidate" && !g.CandidateName.toUpperCase().includes(filter.value.search.toUpperCase())) return false;
        if (filter.value.showIncompleteOnly && g.SubmittedOn !== null) return false;
        return true;
    });
});

const dates = computed(() => {
    return grades.value.reduce((obj, sb) => {
        const d = sb.Date;
        if (!obj.includes(d)) obj.push(d);
        return obj;
    }, []);
});
function formatDate(d) {
    const abogdate = new AbogDate(d);
    return abogdate.toABOGDateString();
}
function formatTime(d) {
    const abogdate = new AbogDate(d);
    return abogdate.toABOGDateTimeString();
}

const gradeViewByCandidate = computed(() => {
    return filteredGrades.value.reduce((groups, g) => {
        if (!groups.some((x) => x.ID === g.CandidateID)) {
            groups.push({
                ID: g.CandidateID,
                Name: g.CandidateName,
                Examiners: [],
            });
        }
        const candidateGroup = groups.find((c) => c.ID === g.CandidateID);
        if (!candidateGroup.Examiners.some((e) => e.ExaminerID === g.ExaminerID && e.StartTime === g.StartTime)) {
            candidateGroup.Examiners.push({
                ScheduleBlockID: g.ScheduleBlockID,
                Date: g.Date,
                StartTime: g.StartTime,
                RoomNumber: g.RoomNumber,
                ExaminerName: g.ExaminerName,
                ExaminerID: g.ExaminerID,
                SubmittedOn: g.SubmittedOn,
                gradeCount: 0,
                totalQuestions: 0,
            });
        }
        const examiner = candidateGroup.Examiners.find((e) => e.ExaminerID === g.ExaminerID && e.StartTime === g.StartTime);
        if (g.Score !== null) examiner.gradeCount++;
        examiner.totalQuestions++;
        return groups;
    }, []);
});

const gradeViewByExaminer = computed(() => {
    return filteredGrades.value.reduce((groups, g) => {
        if (!groups.some((x) => x.ID === g.ExaminerID)) {
            groups.push({
                ID: g.ExaminerID,
                Name: g.ExaminerName,
                Candidates: [],
            });
        }
        const examinerGroup = groups.find((e) => e.ID === g.ExaminerID);
        if (!examinerGroup.Candidates.some((c) => c.CandidateID === g.CandidateID && c.StartTime === g.StartTime)) {
            examinerGroup.Candidates.push({
                ScheduleBlockID: g.ScheduleBlockID,
                Date: g.Date,
                StartTime: g.StartTime,
                RoomNumber: g.RoomNumber,
                CandidateName: g.CandidateName,
                CandidateID: g.CandidateID,
                SubmittedOn: g.SubmittedOn,
                gradeCount: 0,
                totalQuestions: 0,
            });
        }
        const candidate = examinerGroup.Candidates.find((c) => c.CandidateID === g.CandidateID && c.StartTime === g.StartTime);
        if (g.Score !== null) candidate.gradeCount++;
        candidate.totalQuestions++;
        return groups;
    }, []);
});

function unsubmitGrades(id_sb, examinerID) {
    axios.post(`schedule/${id_sb}/unsubmit/${examinerID}/grades`).then(() => {
        AbogAlert.success("Grades unsubmitted");
        loadGrades();
    });
}

function exportGrades() {
    // loadGrades(); // if we reload before an export then we need to wait for results
    const exportGradesArr = grades.value.map((obj) => {
        return {
            CandidateExternalID: obj.CandidateExternalID,
            CandidateName: obj.CandidateName,
            ExaminerExternalID: obj.ExaminerExternalID,
            ExaminerName: obj.ExaminerName,
            CaseDescription: obj.CaseDescription,
            CaseExternalID: obj.CaseExternalID,
            CaseQuestionIndex: obj.CaseQuestionIndex === null ? "Overall" : obj.CaseQuestionIndex,
            Score: obj.Score,
            Note: obj.Note,
            SubmittedOn: obj.SubmittedOn === null ? "" : obj.SubmittedOn,
        };
    });
    const csvRows = [];
    const headers = Object.keys(exportGradesArr[0]);
    csvRows.push(headers.join(",") + "\r\n");

    var str = "";
    for (var i = 0; i < exportGradesArr.length; i++) {
        var line = "";
        for (var index in exportGradesArr[i]) {
            if (index !== "CandidateExternalID") line += ",";
            var value = exportGradesArr[i][index];
            if (typeof value === "string" && value.includes(",")) value = `"${value}"`;
            line += value;
        }
        str += line + "\r\n";
    }

    csvRows.push(str);

    var blob = new Blob(csvRows, { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "grades.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

function startRefreshTimer() {
    refreshTimerId = setInterval(() => {
        refreshTimer.value = refreshTimer.value + 1;
        if (refreshTimer.value > 99) {
            loadGrades();
        }
    }, 300);
}
</script>
