<template>
    <div>
        <div class="hidden md:block">
            <table class="w-full">
                <tr class="sticky top-14">
                    <th class="p-2"></th>
                    <th v-for="gradeKey in props.gradeKeys" :key="gradeKey.Score" class="p-2">{{ gradeKey.Description }}</th>
                </tr>
                <template v-for="(grade) in props.grades" :key="gradeCalcId(grade)">
                    <!-- <tr v-if="grade.firstCaseRow" class="border-t" :class="{ 'bg-aboggray-100': grade.caseColor }">
                        <td class="p-4" colspan="100">{{ grade.CaseDescription }}</td>
                    </tr> -->
                    <tr
                        class="border-t"
                        :class="{
                            'bg-abogred-100': grade.hasErrors,
                            'opacity-50': grade.isSaving,
                            'bg-aboggray-100': grade.caseColor,
                           // 'border-b-4': grade.CaseQuestionIndex === null && grade.GradeCustomIndex === null && i < props.grades.length - 1,
                        }"
                    >
                        <td class="p-4">
                            <span>{{gradeDescription(grade)}}</span>
                        </td>
                        <td v-for="gradeKey in props.gradeKeys" :key="gradeKey.Score" class="text-center">
                            <i
                                @click="setGrade(grade, gradeKey.Score)"
                                class="my-1 p-4"
                                :class="{
                                    'pointer-events-none': !editable,
                                    'cursor-pointer': editable,
                                    'fa fa-check': grade.Score === gradeKey.Score,
                                    'far fa-circle': grade.Score !== gradeKey.Score,
                                }"
                            />
                        </td>
                    </tr>
                    <tr v-if="props.gradeKeys && grade.Score !== null && noteRequired(grade.Score)">
                        <td :colspan="props.gradeKeys.length + 1">
                            <textarea :value="grade.Note" @change="setNote(grade, $event.target.value)" :disabled="!editable || grade.isSaving" class="border w-full p-2"></textarea>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="block md:hidden">
            <div
                v-for="(grade, i) in props.grades"
                :key="gradeCalcId(grade)"
                class="border-t flex justify-between mx-4"
                :class="{
                    'bg-abogred-100': grade.hasErrors,
                    'opacity-50': grade.isSaving,
                    'bg-aboggray-100': grade.caseColor,
                    'border-b-4': grade.CaseQuestionIndex === null && i < props.grades.length - 1,
                }"
            >
                <div class="p-4">
                    <span :class="{ 'font-bold': grade.CaseQuestionIndex === null }">{{ grade.CaseDescription }} - {{ grade.CaseQuestionIndex !== null ? grade.CaseQuestionIndex : "Overall" }}</span>
                </div>
                <select @change="setGrade(grade, $event.target.value)" class="p-4" :disabled="!editable || grade.isSaving">
                    <option :selected="grade.Score === null" value=""></option>
                    <option v-for="gradeKey in props.gradeKeys" :key="gradeKey.Score" :selected="grade.Score === gradeKey.Score" :value="gradeKey.Score">{{ gradeKey.Description }}</option>
                </select>
                <div v-if="props.gradeKeys && grade.Score !== null && noteRequired(grade.Score)" class="w-full ml-4">
                    <textarea :value="grade.Note" @change="setNote(grade, $event.target.value)" :disabled="!editable || grade.isSaving" class="border w-full p-2"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const props = defineProps(["gradeKeys", "grades", "editable"]);
const emit = defineEmits(["saveGrade"]);
function gradeCalcId(grade) {
    // grades might not have an id yet, so build a fake id from case & question ids
    return `${grade.CaseID}-${grade.CaseQuestionID}`;
}
function setGrade(grade, score) {
    // if score is the same assume we are "unchecking" the score
    // select inputs cannot have "null" values, so use empty string for unselected
    if (grade.Score === score || score === "") score = null;
    grade.Score = score;
    emit("saveGrade", grade);
}
function setNote(grade, note) {
    grade.Note = note;
    emit("saveGrade", grade);
}
function noteRequired(score) {
    if (!props.gradeKeys) return false;
    const key = props.gradeKeys.find((k) => k.Score === score);
    return key.NoteRequired;
}
function gradeDescription(grade) {
    if (grade.GradeCustomDescription != '') {
        return grade.GradeCustomDescription;
    } else {
        // {{ grade.CaseDescription }} - {{ grade.CaseQuestionIndex !== null ? grade.CaseQuestionIndex : "Overall" }}
        return grade.CaseDescription + ' - ' + (grade.CaseQuestionIndex !== null ? grade.CaseQuestionIndex : "Overall");
    }
}

</script>
