<template>
    <div>
        <h2 class="font-bold text-2xl">Dashboard</h2>
        <div class="flex flex-wrap">
            <div class="w-48 border rounded-lg m-4 p-4">
                <h3 class="font-bold text-xl mb-4">User Count</h3>
                <div class="flex items-center text-center" title="Candidates">
                    <i class="text-6xl fa fa-graduation-cap w-24" />
                    <span class="text-xl">{{ candidatecount }}</span>
                </div>
                <div class="flex items-center text-center" title="Examiners">
                    <i class="text-6xl fa fa-user-doctor w-24" />
                    <span class="text-xl">{{ examinercount }}</span>
                </div>
            </div>
            <div class="w-96 h-60 flex flex-col border rounded-lg m-4 p-4">
                <h3 class="font-bold text-xl flex justify-between">
                    <span>Missing Grades</span>
                    <i v-if="completedcount" class="text-2xl cursor-pointer text-abogblue-400" :class="detailview ? 'fa fa-chart-pie' : 'fa fa-table'" @click="detailview = !detailview" />
                </h3>
                <div v-if="completedcount === 0" class="mt-4">No grades are available for this exam yet</div>
                <div v-else-if="!detailview" class="flex">
                    <!-- abogblue-300: #2e6498, abogred-600: #ac3535, aboggreen-400: #1e8688 -->
                    <div class="w-40 h-40 rounded-full m-4 relative" :style="`background-image: conic-gradient(#ac3535 ${ungradedpct}%, #1e8688 ${ungradedpct}%)`">
                        <div v-if="ungradedcount > 0" class="absolute text-center text-white left-0 right-0 top-0 bottom-0 pt-1" :style="`transform-origin: center; transform: rotate(${360 * ungradedpct * 0.01 * 0.5}deg)`">
                            <div :style="`transform-origin: center; transform: rotate(-${360 * ungradedpct * 0.01 * 0.5}deg)`">{{ ungradedcount }}</div>
                        </div>

                        <div
                            v-if="completedcount > ungradedcount"
                            class="absolute text-center text-white left-0 right-0 top-0 bottom-0 pt-1"
                            :style="ungradedcount > 0 ? `transform-origin: center; transform: rotate(-${360 * (100 - ungradedpct) * 0.01 * 0.5}deg)` : ''"
                        >
                            <div :style="ungradedcount > 0 ? `transform: rotate(${360 * (100 - ungradedpct) * 0.01 * 0.5}deg)` : ''">{{ completedcount - ungradedcount }}</div>
                        </div>

                        <!-- outlines around pie wedges to help define sections for colorblind people -->

                        <span v-if="ungradedcount > 0 && completedcount > ungradedcount" class="absolute text-center text-white h-24 w-1 bg-white rounded-b" style="left: 50%; bottom: 50%"></span>

                        <span
                            v-if="ungradedcount > 0 && completedcount > ungradedcount"
                            class="absolute text-center text-white h-24 w-1 bg-white rounded-b"
                            :style="`left: 50%; bottom:50%; transform-origin: bottom; transform: rotate(${360 * ungradedpct * 0.01}deg)`"
                        ></span>
                    </div>
                    <div class="mx-2 my-8">
                        <div class="flex items-center whitespace-nowrap">
                            <span class="inline-block w-4 h-4 mr-2 border bg-aboggreen-400"></span> <span>Complete ({{ completedcount - ungradedcount }})</span>
                        </div>
                        <div class="flex items-center whitespace-nowrap">
                            <span class="inline-block w-4 h-4 mr-2 border bg-abogred-600"></span> <span>Ungraded ({{ ungradedcount }})</span>
                        </div>
                    </div>
                </div>
                <div v-else class="overflow-scroll">
                    <table class="text-left whitespace-nowrap">
                        <tr>
                            <th class="px-2">Examiner</th>
                            <th class="px-2">Candidate</th>
                            <th class="px-2">Time</th>
                        </tr>
                        <tr v-for="row in ungraded" :key="row.id">
                            <td class="px-2">{{ row.examiner }}</td>
                            <td class="px-2">{{ row.candidate }}</td>
                            <td class="px-2">{{ row.datetime }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="w-72 border rounded-lg m-4 p-4 overflow-x-auto">
                <h3 class="font-bold text-xl mb-4">User Search</h3>
                <div v-if="searchoptions.length === 0">No active blocks to search at this time</div>
                <label v-else class="block mb-2">
                    <input type="text" list="namelist" class="removearrow border-b w-full -mr-4" v-model="searchtext" @input="search" />
                    <i :class="selectedblock >= 0 ? 'fas fa-xmark cursor-pointer' : 'fas fa-search pointer-events-none'" @click="clear" />
                </label>
                <datalist id="namelist">
                    <option v-for="o in searchoptions" :key="o" :value="o"></option>
                </datalist>
                <table v-if="selectedblock >= 0" class="whitespace-nowrap">
                    <tr>
                        <td class="pr-4">Room:</td>
                        <td>{{ currentblocks[selectedblock].room }}</td>
                    </tr>
                    <tr>
                        <td class="pr-4">Time:</td>
                        <td>{{ currentblocks[selectedblock].times }}</td>
                    </tr>
                    <tr>
                        <td class="pr-4">Candidate:</td>
                        <td>{{ currentblocks[selectedblock].candidate }}</td>
                    </tr>
                    <tr>
                        <td class="pr-4">Examiners:</td>
                        <td>
                            <!-- {{ currentblocks[selectedblock].examiners.join(", ") }} -->
                            <div v-for="examiner in currentblocks[selectedblock].examiners" :key="examiner">{{ examiner }}</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="border rounded-lg m-4 p-4">
                <h3 class="font-bold text-xl">Exam Status</h3>
                <div class="flex pt-4">
                    <div v-for="status in examstatus" :key="status.name" class="text-xl mx-4 text-center" :title="status.name">
                        <div class="relative h-32 w-8">
                            <div :style="`height: ${(status.count / maxstatuscount) * 100}%`" class="absolute w-full bottom-0" :class="status.bgcolor">
                                <span class="absolute text-base top-0 left-0 right-0 w-full -mt-6">{{ status.count }}</span>
                            </div>
                        </div>
                        <i :class="[status.icon, status.textcolor]" />
                    </div>
                </div>
            </div>
            <div class="border rounded-lg m-4 p-4">
                <h3 class="font-bold text-xl">Exam Progress</h3>
                <p class="ml-4"><strong>Current Block:</strong> {{ currentslotlabel }}</p>
                <div class="flex">
                    <div v-for="d in examdays" :key="d" class="mx-2">
                        {{ d }}
                        <div class="relative">
                            <div v-for="h in examhours" :key="h" class="border h-4 w-full" :title="h"></div>
                            <div v-if="nowdate === d && pcttime > 0 && pcttime < 100" :style="`top: ${pcttime}%; margin-top:-2px;`" :title="nowtime" class="position absolute h-0 w-full border-2 border-abogred-600"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border rounded-lg m-4 p-4">
                <h3 class="font-bold text-xl flex justify-between">
                    <span>Notifications</span>
                    <RouterLink to="messages"><i class="text-xl cursor-pointer text-abogblue-400 fa-solid fa-envelope" @click="detailview = !detailview" /></RouterLink>
                </h3>
                <p class="ml-4"><strong>Current Block:</strong> {{ currentslotlabel }}</p>
                <!-- <div class="text-lg text-red-500 ml-4">{{ messageCount }}</div> -->
                <div v-for="mes in messageCount" :key="mes" class="text-lg text-red-500 ml-4">{{ mes }}</div>
            </div>
            <!--
            <div class="h-60 border rounded-lg m-4 p-4 flex flex-col">
                <h3 class="font-bold text-xl">Schedule Warnings</h3>
                <div class="flex items-center m-4">
                    <i class="fa fa-warning text-6xl text-abogyellow-200" />
                    <span class="ml-2 text-4xl">{{ warnings.length }}</span>
                </div>
                <div class="overflow-auto">
                    <p v-for="w in warnings" :key="w">{{ w }}</p>
                </div>
            </div>
            <div class="h-60 border rounded-lg m-4 p-4">
                <h3 class="font-bold text-xl">Messages</h3>
                <div class="flex items-center m-4">
                    <i class="fa fa-envelope text-6xl text-abogpurple-400" />
                    <span class="ml-2 text-4xl">{{ messages.length }}</span>
                </div>
                <div class="overflow-auto">
                    <p v-for="m in messages" :key="m">{{ m }}</p>
                </div>
            </div>
            -->
        </div>
    </div>
</template>
<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { useExamStore } from "@/pages/components/ExamStore.js";
import AbogDate from "@/pages/components/AbogDate";

const axios = inject("axios");
const exam = useExamStore();
// current date/time
const now = ref(new AbogDate()); // "2023-11-05T10:35:00" for test
const nowdate = computed(() => now.value.toABOGDateString());
const nowtime = computed(() => now.value.getHours().toString().padStart(2, "0") + ":" + now.value.getMinutes().toString().padStart(2, "0"));
// api data
const candidates = ref([]);
const examiners = ref([]);
const timeslots = ref([]);
const schedule = ref([]);
const messages = ref([]);
// loads
onMounted(() => {
    setNow();
    loadCandidates();
    loadExaminers();
    loadTimeSlots();
    loadSchedules();
    loadMessages();
});
function setNow() {
    const ms = now.value.getSeconds() * 1000 + now.value.getMilliseconds();
    setTimeout(() => {
        now.value = new AbogDate();
        setNow();
        loadSchedules();
    }, 60000 - ms);
}
function loadCandidates() {
    axios.get("candidates").then((response) => {
        candidates.value = response.data;
    });
}
function loadExaminers() {
    axios.get("examiners").then((response) => {
        examiners.value = response.data;
    });
}
function loadTimeSlots() {
    axios.get("timeslots").then((response) => {
        response.data.forEach((s) => {
            s.StartTime = s.StartTime.slice(0, -3);
            s.EndTime = s.EndTime.slice(0, -3);
        });
        timeslots.value = response.data;
    });
}
function loadSchedules() {
    axios.get("schedules").then((response) => {
        response.data.forEach((s) => {
            s.Date = new AbogDate(s.Date).toABOGDateString();
            s.StartTime = s.StartTime.slice(0, -3);
            s.EndTime = s.EndTime.slice(0, -3);
        });
        schedule.value = response.data;
    });
}
function loadMessages() {
    axios.get("HelpMessages").then((response) => {
        messages.value = response.data;
    });
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// user count widget
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const candidatecount = computed(() => candidates.value.length);
const examinercount = computed(() => examiners.value.length);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// exam progress widget
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const examdays = computed(() => exam.Days.map((d) => new AbogDate(d).toABOGDateString()));
const examhours = computed(() => {
    if (timeslots.value.length === 0) return [];
    const mintime = timeslots.value.reduce((min, ts) => (ts.StartTime < min ? ts.StartTime : min), "99:99");
    const maxtime = timeslots.value.reduce((max, ts) => (ts.EndTime > max ? ts.EndTime : max), "00:00");
    let timelist = [];
    let loophour = parseInt(mintime.slice(0, 2));
    let loopsafety = 100;
    while (`${loophour}:00`.padStart(5, "0") < maxtime && loopsafety-- > 0) {
        timelist.push(`${loophour}:00`.padStart(5, "0"));
        loophour++;
    }
    return timelist;
});
const currentslot = computed(() => {
    if (!examdays.value.includes(nowdate.value)) return null;
    const slot = timeslots.value.find((ts) => ts.StartTime <= nowtime.value && ts.EndTime >= nowtime.value);
    if (!slot) return null;
    return slot;
});
const currentslotlabel = computed(() => {
    if (!currentslot.value) return "N/A";
    return `${nowdate.value} ${currentslot.value.StartTime} - ${currentslot.value.EndTime}`;
});
const pcttime = computed(() => {
    const enddatetime = new Date(`${nowdate.value} ${examhours.value[examhours.value.length - 1]}`);
    enddatetime.setTime(enddatetime.getTime() + 60 * 60 * 1000); // add 1 hour to the end
    const startdatetime = new Date(`${nowdate.value} ${examhours.value[0]}`);
    const nowdatetime = new Date(`${nowdate.value} ${nowtime.value}`);
    const totseconds = enddatetime - startdatetime;
    const nowseconds = nowdatetime - startdatetime;
    return (nowseconds / totseconds) * 100;
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// user search widget
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const selectedblock = ref(-1);
const searchtext = ref("");
// list of active schedule blocks
const currentblocks = computed(() => {
    if (!currentslot.value) return [];
    const currentblocks = schedule.value.filter((s) => s.Date === nowdate.value && s.StartTime === currentslot.value.StartTime);
    return currentblocks.map((b) => {
        return { examiners: b.Examiners.map((e) => e.ExaminerName), candidate: b.CandidateName, room: b.RoomNumber, status: b.Status, times: b.StartTime + " - " + b.EndTime };
    });
});
// list of things we can search for (examiners/candidates/rooms)
const searchoptions = computed(() => {
    let arr = [];
    currentblocks.value.forEach((block) => {
        arr.push(block.candidate);
        arr.push(block.room);
        block.examiners.forEach((e) => {
            arr.push(e);
        });
    });
    // distinct
    return [...new Set(arr)];
});
// search functions
function search() {
    selectedblock.value = currentblocks.value.findIndex((block) => block.room === searchtext.value || block.candidate === searchtext.value || block.examiners.includes(searchtext.value));
}
function clear() {
    selectedblock.value = -1;
    searchtext.value = "";
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// exam status widget
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const examstatus = computed(() => {
    // Pending: 0, Active: 1, Complete: 2
    let es = [
        { name: "Pending", count: 0, icon: "fa fa-clock", bgcolor: "bg-abogblue-100", textcolor: "text-abogblue-100" },
        { name: "Active", count: 0, icon: "fa fa-play", bgcolor: "bg-abogblue-300", textcolor: "text-abogblue-300" },
        { name: "Complete", count: 0, icon: "fa fa-check", bgcolor: "bg-aboggreen-400", textcolor: "text-aboggreen-400" },
    ];
    schedule.value.forEach((block) => {
        es[block.Status].count++;
    });
    return es;
});
const maxstatuscount = computed(() => examstatus.value.reduce((max, s) => (s.count > max ? s.count : max), 1));

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// missing grades widget
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const detailview = ref(false);
const ungraded = computed(() => {
    let arr = [];
    let i = 1;
    schedule.value.forEach((s) => {
        // only expect grades from completed blocks with candidates
        if (s.Status === 2 && s.CandidateID !== null) {
            s.Examiners.forEach((e) => {
                if (e.GradesSubmittedOn === null)
                    arr.push({
                        id: i++,
                        examiner: e.ExaminerName,
                        candidate: s.CandidateName,
                        datetime: s.Date + " " + s.StartTime,
                    });
            });
        }
    });
    return arr;
});
const completedcount = computed(() => schedule.value.filter((s) => s.Status === 2 && s.CandidateID !== null).reduce((count, s) => count + s.Examiners.length, 0)); // total completed examiners
const ungradedcount = computed(() => ungraded.value.length);
const ungradedpct = computed(() => (ungradedcount.value / completedcount.value) * 100);

/*
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// warning widget
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const warnings = computed(() => {
    return ["501", "502"];
});
*/
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// messages widget
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const messageCount = computed(() => {
    let arr = [];
    messages.value.forEach((obj) => {
        let mdate = new AbogDate(obj.OpenedDate).toABOGDateString();
        let mtime = new AbogDate(obj.OpenedDate).getHours().toString().padStart(2, "0") + ":" + new AbogDate(obj.OpenedDate).getMinutes().toString().padStart(2, "0");
        if (mdate == nowdate.value && currentslot.value.StartTime <= mtime && mtime <= currentslot.value.EndTime && obj.ID_HMS == 1) {
            arr.push(`${obj.FullName} needs ${obj.Options} in room ${obj.RoomNumber}`);
        }
    });
    if (arr.length == 0) {
        return ["There are no new messages"];
    } else {
        return arr;
    }
});
</script>
<style scoped>
.removearrow::-webkit-calendar-picker-indicator {
    display: none !important;
}
</style>
