<template>
    <div v-if="props.data" class="w-full bg-aboggray-100 rounded-lg p-4">
        <div class="w-full px-2 mb-6">
            <div class="float-right text-xl text-abogred-600 font-bold">{{ display.SlideID }}</div>
            <!-- <label class="block uppercase text-xs font-bold">
                {{ formDefinition[spec][sectionId].title }}
            </label> -->
            <h1 class="font-head text-xl font-bold">{{ display.CategoryDescription }}</h1>
        </div>
        <div class="flex flex-wrap">
            <div v-if="spec == 'URPS' || (spec == 'REI' && sectionId == 2) || spec == 'ONC'" class="w-full px-2 mb-6 lg:w-2/12">
                <label class="block uppercase text-xs font-bold mb-2">
                    {{ daysInHospitalLabel }}
                </label>
                <span class="px-4">{{ display.DaysInHospital }}</span>
            </div>

            <div class="w-full px-2 mb-6 lg:w-1/12">
                <label class="block uppercase text-xs font-bold mb-2 whitespace-nowrap"> Age </label>
                <span class="px-4">{{ display.PatientAge }}</span>
            </div>

            <div v-if="spec != 'URPS' && spec != 'ONC'" class="w-full px-2 mb-6 lg:w-1/12">
                <label class="block uppercase text-xs font-bold mb-2"> Gravida </label>
                <span class="px-4">{{ display.Gravida }}</span>
            </div>
            <div v-if="spec != 'ONC' && spec != 'URPS'" class="w-full px-2 mb-6 lg:w-1/12">
                <label class="block uppercase text-xs font-bold mb-2"> Para </label>
                <span class="px-4">{{ display.Para }}</span>
            </div>
            <div class="w-full px-2 mb-6 lg:w-1/6">
                <label class="block uppercase text-xs font-bold mb-2 whitespace-nowrap"> Collection TimeFrame </label>
                <span class="px-4 whitespace-nowrap">{{ casetimeframe }}</span>
            </div>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full px-2 mb-6 lg:w-1/2">
                <label class="block uppercase text-xs font-bold mb-2 relative">
                    {{ formDefinition[spec][sectionId][1].Label }}
                </label>
                <p class="px-4 whitespace-pre-wrap overflow-auto">{{ display[formDefinition[spec][sectionId][1].Field] }}</p>
            </div>
            <div class="w-full px-2 mb-6 lg:w-1/2">
                <label class="block uppercase text-xs font-bold mb-2 relative">
                    {{ formDefinition[spec][sectionId][2].Label }}
                </label>
                <p class="px-4 whitespace-pre-wrap overflow-auto">{{ display[formDefinition[spec][sectionId][2].Field] }}</p>
            </div>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full px-2 mb-6 lg:w-1/2">
                <label class="block uppercase text-xs font-bold mb-2 relative">
                    {{ formDefinition[spec][sectionId][3].Label }}
                </label>
                <p class="px-4 whitespace-pre-wrap overflow-auto">{{ display[formDefinition[spec][sectionId][3].Field] }}</p>
            </div>
            <div v-if="spec != 'URPS'" class="w-full px-2 mb-6 lg:w-1/2">
                <label class="block uppercase text-xs font-bold mb-2 relative">
                    {{ formDefinition[spec][sectionId][4].Label }}
                </label>
                <p class="px-4 whitespace-pre-wrap overflow-auto">{{ display[formDefinition[spec][sectionId][4].Field] }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, computed } from "vue";
const props = defineProps(["data"]);
const display = computed(() => JSON.parse(props.data));
const spec = computed(() => display.value.Spec);
const sectionId = computed(() => display.value.C_Type);

const formDefinition = reactive({
    URPS: {
        1: {
            //Surgical - Prolapse & Recon
            title: "Surgical - Prolapse & Recon",
            1: { Header: "Diagnosis", Field: "Text1", Label: "DIAGNOSIS (INCLUDE POP STAGE, IF APPLICABLE)", Limit: "200" },
            2: { Header: "Procedures", Field: "Text2", Label: "SURGICAL PROCEDURE(S)", Limit: "200" },
            3: { Header: "Status", Field: "Text3", Label: "COMPLICATIONS AND STATUS", Limit: "200" },
            4: { Header: "", Field: "", Label: "", Limit: "" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: false, Para: false },
        },
        2: {
            //Office
            title: "Office",
            1: { Header: "Diagnosis", Field: "Text1", Label: "DIAGNOSIS", Limit: "200" },
            2: { Header: "Treatment", Field: "Text2", Label: "TREATMENT", Limit: "300" },
            3: { Header: "Status", Field: "Text3", Label: "STATUS", Limit: "200" },
            4: { Header: "", Field: "", Label: "", Limit: "" },
            validate: { Hospital: false, DaysInHospital: true, Gravida: false, Para: false },
        },
        3: {
            //Surgical - Urinary & Fecal Incont
            title: "Surgical - Urinary & Fecal Incont",
            1: { Header: "Diagnosis", Field: "Text1", Label: "DIAGNOSIS (INCLUDE POP STAGE, IF APPLICABLE)", Limit: "200" },
            2: { Header: "Procedures", Field: "Text2", Label: "SURGICAL PROCEDURE(S)", Limit: "200" },
            3: { Header: "Status", Field: "Text3", Label: "COMPLICATIONS AND STATUS", Limit: "200" },
            4: { Header: "", Field: "", Label: "", Limit: "" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: false, Para: false },
        },
    },
    ONC: {
        1: {
            //Ovarian, peritoneal, and fallopian tube cancer (include chemotherapy cases)
            title: "Ovarian, peritoneal, and fallopian tube cancer (include chemotherapy cases)",
            1: { Header: "Preoperative Diagnosis", Field: "Text1", Label: "DIAGNOSIS PREOPERATIVE OR ADMISSION", Limit: "200" },
            2: { Header: "Treatment", Field: "Text2", Label: "TREATMENT", Limit: "300" },
            3: { Header: "Final Diagnosis", Field: "Text3", Label: "FINAL PATHOLOGIC DIAGNOSIS", Limit: "200" },
            4: { Header: "Status", Field: "Text4", Label: "COMPLICATIONS AND PRESENT STATUS", Limit: "200" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: false, Para: false },
        },
        2: {
            //Uterine malignancies: endometrial cancer, sarcoma, GTD, other
            title: "Uterine malignancies: endometrial cancer, sarcoma, GTD, other",
            1: { Header: "Preoperative Diagnosis", Field: "Text1", Label: "DIAGNOSIS PREOPERATIVE OR ADMISSION", Limit: "200" },
            2: { Header: "Treatment", Field: "Text2", Label: "TREATMENT", Limit: "300" },
            3: { Header: "Final Diagnosis", Field: "Text3", Label: "FINAL PATHOLOGIC DIAGNOSIS", Limit: "200" },
            4: { Header: "Status", Field: "Text4", Label: "COMPLICATIONS AND PRESENT STATUS", Limit: "200" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: false, Para: false },
        },
        3: {
            //Cervical, vulvar, and vaginal cancers and radiation therapy
            title: "Cervical, vulvar, and vaginal cancers and radiation therapy",
            1: { Header: "Preoperative Diagnosis", Field: "Text1", Label: "DIAGNOSIS PREOPERATIVE OR ADMISSION", Limit: "200" },
            2: { Header: "Treatment", Field: "Text2", Label: "TREATMENT", Limit: "300" },
            3: { Header: "Final Diagnosis", Field: "Text3", Label: "FINAL PATHOLOGIC DIAGNOSIS", Limit: "200" },
            4: { Header: "Status", Field: "Text4", Label: "COMPLICATIONS AND PRESENT STATUS", Limit: "200" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: false, Para: false },
        },
    },
    REI: {
        1: {
            //Reproductive Endocrinology
            title: "Reproductive Endocrinology",
            1: { Header: "Diagnosis", Field: "Text1", Label: "DIAGNOSIS AND ALL PERTINENT DIAGNOSTIC TEST RESULTS DONE PRIOR TO SEEING YOU", Limit: "200" },
            2: { Header: "Results", Field: "Text2", Label: "RESULTS OF ALL TESTS ORDERED BY YOU", Limit: "200" },
            3: { Header: "Treatment", Field: "Text3", Label: "FINAL DIAGNOSIS AND TREATMENT", Limit: "300" },
            4: { Header: "Outcome", Field: "Text4", Label: "OUTCOME", Limit: "200" },
            validate: { Hospital: false, DaysInHospital: false, Gravida: true, Para: true },
        },
        2: {
            //Surgery
            title: "Surgery",
            1: { Header: "Diagnosis", Field: "Text1", Label: "DIAGNOSIS AND ALL PERTINENT DIAGNOSTIC TEST RESULTS DONE PRIOR TO SEEING YOU", Limit: "200" },
            2: { Header: "Results", Field: "Text2", Label: "RESULTS OF ALL TESTS ORDERED BY YOU", Limit: "200" },
            3: { Header: "Treatment", Field: "Text3", Label: "FINAL DIAGNOSIS AND TREATMENT", Limit: "300" },
            4: { Header: "Outcome", Field: "Text4", Label: "OUTCOME", Limit: "200" },
            validate: { Hospital: true, DaysInHospital: true, Gravida: true, Para: true },
        },
        3: {
            //Infertility
            title: "Infertility",
            1: { Header: "Diagnosis", Field: "Text1", Label: "DIAGNOSIS AND ALL PERTINENT DIAGNOSTIC TEST RESULTS DONE PRIOR TO SEEING YOU", Limit: "200" },
            2: { Header: "Results", Field: "Text2", Label: "RESULTS OF ALL TESTS ORDERED BY YOU", Limit: "200" },
            3: { Header: "Treatment", Field: "Text3", Label: "FINAL DIAGNOSIS AND TREATMENT", Limit: "300" },
            4: { Header: "Outcome", Field: "Text4", Label: "OUTCOME", Limit: "200" },
            validate: { Hospital: false, DaysInHospital: false, Gravida: true, Para: true },
        },
    },
    NONE: {
        0: {},
    },
});

const daysInHospitalLabel = computed(() => {
    if ((spec.value == "OBGYN" && sectionId.value == 3) || (spec.value == "URPS" && sectionId.value == 2)) {
        return "Visits";
    } else {
        return "Nights In Hospital";
    }
});

const casetimeframe = computed(() => {
    // the casetimeframe could be added the the case JSON instead
    if (display.value.Spec == "OBGYN") {
        switch (display.value.CaseTimeframe) {
            case 1:
                return "12 Months";
            case 2:
                return "18 Months";
            case 3:
                return "Senior Resident";
            case 4:
                return "Fellowship";
            default:
                return "";
        }
    } else if (display.value.Spec == "ONC") {
        if (display.value.CaseTimeframe == 18) {
            return "18 Months";
        } else {
            return display.value.CaseTimeframe == new Date().getFullYear() - 1 ? "12 Months" : "24 Months";
        }
    } else {
        switch (display.value.CaseTimeframe) {
            case 1:
                return "12 Months";
            case 2:
                return "24 Months";
            case 3:
                return "18 Months";
            default:
                return "";
        }
    }
});
</script>
