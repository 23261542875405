<template>
    <div class="max-w-md mx-auto my-4">
        <h2 class="text-2xl font-bold text-center mb-4">User Login</h2>
        <h3 v-if="showerror" class="text-lg text-center text-abogred-600 mb-4"><i class="fas fa-exclamation-circle" /> {{ showerror }}</h3>
        <form @submit.prevent="login">
            <div class="flex mb-4 items-baseline">
                <span class="w-1/3 pr-4 text-right">
                    <label for="name">User Name</label>
                </span>
                <input id="name" type="text" v-model="username" class="w-2/3 p-1 border" />
            </div>
            <div class="flex mb-4 items-baseline">
                <label for="pass" class="w-1/3 text-right pr-4">Password</label>
                <input id="pass" type="password" v-model="password" class="w-2/3 p-1 border" />
            </div>
            <div class="flex mb-4">
                <label class="w-1/3"></label>
                <RouterLink to="/forgotpw" class="w-2/3 whitespace-nowrap text-abogblue-300 hover:text-abogblue-200 hover:underline">Forgot your password?</RouterLink>
            </div>
            <div class="flex mb-4">
                <label class="w-1/3"></label>
                <button class="purple-button">Login</button>
            </div>
        </form>
    </div>
    <AbogModal v-if="examlist.length > 0" @close="examlist = []" title="Select an exam">
        <div class="flex flex-col">
            <span class="mb-4">Your account has access to more than one exam. Please select which exam you would like to log in to.</span>
            <button v-for="examopt in examlist" :key="examopt.ID" class="hollow-blue-button mb-4" @click="examLogin(examopt.ExamName)">
                {{ examopt.ExamType }}
            </button>
            <div class="text-right">
                <button @click="examlist = []" class="blue-button">Cancel</button>
            </div>
        </div>
    </AbogModal>
</template>
<script setup>
import { ref, onMounted, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useExamStore } from "@/pages/components/ExamStore";
import AbogModal from "@/pages/components/AbogModal";
import Token from "@/token";
// inject globals
const axios = inject("axios");
const router = useRouter();
const route = useRoute();
const exam = useExamStore();
const emits = defineEmits(["login"]);
// data
const username = ref("");
const password = ref("");
const examname = ref("");
const examlist = ref([]);
const showerror = ref("");
// auth errors will pass a query string param
const auth = route.query.auth;
router.replace({ query: null });
// mounted
onMounted(() => {
    if (auth) showerror.value = "Unable to refresh authentication token";
    Token.clear();
    exam.clear();
    delete axios.defaults.headers.common["Authorization"];
});
// methods
function login() {
    let postdata = {
        Email: username.value,
        Password: password.value,
        Exam: examname.value,
    };
    axios
        .post("authentication", postdata)
        .then((response) => {
            if (response.data.Token != "") {
                // got a token, go for it
                showerror.value = "";
                Token.set(response.data.Token);
                axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.Token;
                exam.refresh();
                emits("login");
                router.push("/");
            } else if (response.data.Exams.length > 0) {
                // user has multiple exams available, make them pick one
                examlist.value = response.data.Exams;
            } else {
                // something went wrong
                showerror.value = "Invalid User Name or Password.";
            }
        })
        .catch((e) => {
            showerror.value = e.response.data === "Attempted to perform an unauthorized operation." ? "Invalid User Name or Password." : e.response.data;
        });
}
function examLogin(examName) {
    // set the exam before you log in
    examname.value = examName;
    login();
}
</script>
