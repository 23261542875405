<template>
    <AbogModal :title="operation + ' ' + entity" :width="40">
        <div class="w-full h-full p-4"><slot /></div>
        <div class="text-right py-4">
            <button @click="saveBtnClick()" class="purple-button mx-2" :disabled="saving">
                Save
            </button>
            <button @click="$emit('close')" class="blue-button mx-2" :disabled="saving">
                Cancel
            </button>
        </div>
        <div class="w-full h-full absolute top-0 left-0 flex items-center justify-center" v-if="saving" >
            <AbogLoading class="w-12" />
        </div>
    </AbogModal>
</template>
<script setup>
import AbogModal from "@/pages/components/AbogModal";
import AbogLoading from "@/pages/components/AbogLoading";
import AbogAlert from "@/pages/components/AbogAlert";
import { ref, computed } from "vue";
const props = defineProps({
  isAdd: {
    type: Boolean,
    required: true
  },
  entity: {
    type: String,
    required: true
  },
  saveCallback: {
    type: Function,
    required: false
  },
})
const emit = defineEmits(['close'])
const saving = ref(false);
const error = ref(null);
const operation = computed(() => {
    return props.isAdd ? "Add" : "Edit";
});

async function saveBtnClick() {
    if (props.saveCallback && typeof props.saveCallback === 'function') {
        saving.value = true;
        error.value = await props.saveCallback();
        emit('close');
        if (error.value) {
            AbogAlert.error("Error saving " + props.entity + ": " + error.value);
        }
        
    }
}

</script>