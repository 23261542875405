[Wednesday 9:32 AM] Denton Pfister
<template>
    <video v-if="isVideo" :src="mediaDataUrl" autoplay controls muted />
    <img v-else :src="mediaDataUrl" :alt="props.alt" />
</template>
<script setup>
import { ref, onMounted, inject, watch } from "vue";
const axios = inject("axios");
const props = defineProps(["src", "alt"]);
const mediaDataUrl = ref(null);
const isVideo = ref(false);

// reload image on src change
watch(
    () => props.src, // need the lambda to access the property
    () => {
        loadImg();
    }
);

onMounted(() => {
    loadImg();
});

function loadImg() {
    axios.get(props.src, { responseType: "blob" }).then((response) => {
        let type = response.data.type.split("/")[0];
        if (type === "video") isVideo.value = true;
        const reader = new window.FileReader();
        reader.readAsDataURL(response.data);
        reader.onload = function () {
            mediaDataUrl.value = reader.result;
        };
    });
}
</script>
