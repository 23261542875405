import Token from "./token.js";

import Login from "@/pages/UserLogin";
import ForgotPassword from "@/pages/ForgotPassword";
import ChangePassword from "@/pages/ChangePassword";
import SetToken from "@/pages/SetToken";
import FourOhFour from "@/pages/FourOhFour";
import ExaminerSchedule from "@/pages/examiner/ExaminerSchedule";
import ScheduleNav from "@/pages/examiner/ScheduleNav";
import DisplayCases from "@/pages/examiner/DisplayCases";
import SubmitGrades from "@/pages/examiner/SubmitGrades";
import CanidateAttestation from "@/pages/examiner/CanidateAttestation";
import DocumentList from "@/pages/examiner/DocumentList";
import AdminNav from "@/pages/admin/AdminNav";
import AdminDashboard from "@/pages/admin/AdminDashboard";
import AdminExaminers from "@/pages/admin/AdminExaminers";
import AdminCandidates from "@/pages/admin/AdminCandidates";
import AdminCases from "@/pages/admin/AdminCases";
import AdminSchedule from "@/pages/admin/AdminSchedule";
import AdminDocuments from "@/pages/admin/AdminDocuments";
import AdminGrades from "@/pages/admin/AdminGrades";
import AdminMessages from "@/pages/admin/AdminMessages";
import AdminBlueprint from "@/pages/admin/AdminBlueprint";
import AdminSettings from "@/pages/admin/AdminSettings";
import ExamRoom from "@/pages/ExamRoom";

const routes = [
    {
        // this route is just here to redirect people where they should be
        path: "/",
        name: "Root",
    },
    {
        path: "/examroom",
        name: "ExamRoom",
        component: ExamRoom,
    },
    {
        path: "/schedule",
        name: "Schedule",
        component: ExaminerSchedule,
    },
    {
        path: "/schedule/:id_sb",
        name: "schednav",
        component: ScheduleNav,
        children: [
            {
                path: "/schedule/:id_sb/grades",
                name: "Grades",
                component: SubmitGrades,
            },
            {
                path: "/schedule/:id_sb/attestation",
                name: "Attestation",
                component: CanidateAttestation,
            },
            {
                path: "/schedule/:id_sb/cases", // add case type param ?
                name: "Cases",
                component: DisplayCases,
            },
            // {
            //     path: "/schedule/:id_sb/patient",
            //     name: "patientcases",
            //     component: PatientCases,
            // },
        ],
    },
    {
        path: "/documents",
        name: "Documents",
        component: DocumentList,
    },
    {
        path: "/admin",
        name: "adminnav",
        component: AdminNav,
        children: [
            {
                path: "dashboard",
                name: "AdminDashboard",
                component: AdminDashboard,
            },
            {
                path: "examiners",
                name: "AdminExaminers",
                component: AdminExaminers,
            },
            {
                path: "candidates",
                name: "AdminCandidates",
                component: AdminCandidates,
            },
            {
                path: "cases",
                name: "AdminCases",
                component: AdminCases,
            },
            {
                path: "schedule",
                name: "AdminSchedule",
                component: AdminSchedule,
            },
            {
                path: "documents",
                name: "AdminDocuments",
                component: AdminDocuments,
            },
            {
                path: "grades",
                name: "AdminGrades",
                component: AdminGrades,
            },
            {
                path: "messages",
                name: "AdminMessages",
                component: AdminMessages,
            },
            {
                path: "blueprint",
                name: "AdminBlueprint",
                component: AdminBlueprint,
            },
            {
                path: "settings",
                name: "AdminSettings",
                component: AdminSettings,
            },
        ],
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/forgotpw",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/changepw",
        name: "ChangePassword",
        component: ChangePassword,
    },
    {
        path: "/settoken",
        name: "SetToken",
        component: SetToken,
    },
    {
        path: "/:pathMatch(.*)*",
        component: FourOhFour,
    },
];

function hasPermission(topath, roles) {
    // schedule paths require examiner
    if (topath.startsWith("/schedule") && !(roles && roles.includes("Examiner"))) {
        return false;
    }
    // documents requires examiner
    if (topath.startsWith("/documents") && !(roles && roles.includes("Examiner"))) {
        return false;
    }
    // admin paths require admin
    if (topath.startsWith("/admin") && !(roles && roles.includes("Admin"))) {
        return false;
    }
    return true;
}

// runs in router.beforeEach to check permissions
function authCheck(to, from, next) {
    // no need for validation on these pages
    if (Token.nonAuthPages.includes(to.path)) {
        next();
        return;
    }
    const payload = Token.payload();
    if (payload) {
        if (to.path === "/") {
            // forward people from root to the expected page
            if (hasPermission("/schedule", payload.role)) {
                next("/schedule");
            } else if (hasPermission("/admin", payload.role)) {
                next("/admin/dashboard");
            } else {
                next("/examroom");
            }
        } else if (hasPermission(to.path, payload.role)) {
            // check if the current page is legal
            next();
        } else {
            // no permission, so redirect to the base page
            next("/");
        }
    } else {
        // no token, bail to the login page
        // next({ path: "/login", query: { r: to.path } });
        next("/login");
    }
}

export default { routes, authCheck };
