// vue
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { createPinia } from "pinia";
// axios
import axios from "axios";
// custom
import App from "./App.vue";
import Routing from "./routing.js";
import ErrorHandler from "./errorhandlers.js";
// setup axios
// prettier-ignore
const API_ROOT = process.env.VUE_APP_ENV === "local" ? "https://localhost:7089/" :
                 process.env.VUE_APP_ENV === "dev" ? "https://examapi.dev.org/" :
                 process.env.VUE_APP_ENV === "preprod" ? "https://examapi.goba.org/" :
                 process.env.VUE_APP_ENV === "prod" ? "https://examapi.abog.org/" : "";
const api = axios.create({ baseURL: API_ROOT });
api.defaults.headers.post["Content-Type"] = "application/json";
api.defaults.headers.put["Content-Type"] = "application/json";
ErrorHandler.InitAxios(api);
// setup routing
const router = createRouter({
    history: createWebHistory(),
    routes: Routing.routes,
});
router.beforeEach(Routing.authCheck);
// setup stores
const pinia = createPinia();
pinia.use(() => ({ api }));
// setup app
const app = createApp(App);
app.use(router);
app.use(pinia);
// ErrorHandler.InitVue(app);
app.config.globalProperties.$axios = api; // for use in options api
app.provide("axios", api); // for use in composition api
app.mount("#app");
