<template>
    <div v-if="scheduleblock" class="flex justify-between px-8 py-2 sticky top-0 z-50 bg-white">
        <span>Room {{ scheduleblock.RoomNumber }}</span>
        <AbogTimer v-if="scheduleblock.ID" v-bind:id_sb="scheduleblock.ID" @timerCompleted="timerCompleted" />
        <span v-else>No Exam Scheduled</span>
    </div>
    <div class="m-4">
        <p v-if="templatename === 'Error'" class="text-abogred-600 bg-aboggray-100 rounded-lg p-4">There was a problem loading the form. Please try again or contact an administrator if the problem persists.</p>
        <AttestationForm v-if="templatename === 'AttestationForm'" :examiners="examiners" :disclaimer="disclaimer" :complete="false" />
        <component v-else :is="casetemplates[templatename]" :data="question" :casetype="casetype" :fullscreenmedia="fullscreenmedia" :media="media" />
    </div>
</template>
<script setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import AbogTimer from "@/pages/components/AbogTimer";
import StructuredCase from "@/pages/components/casetemplates/StructuredCase";
import PatientCase from "@/pages/components/casetemplates/PatientCase";
import PatientCaseNewborn from "@/pages/components/casetemplates/PatientCaseNewborn";
import AttestationForm from "@/pages/components/AttestationForm";
import Token from "@/token";
const axios = inject("axios");
// const ws = ref(null);
const scheduleblock = ref(null);
const fullscreenmedia = ref(null);
const media = ref([]);
const question = ref("");
const casetype = ref("");
const examiners = ref([]);
const disclaimer = ref("");
const templatename = ref("");
const casetemplates = {
    StructuredCase,
    PatientCase,
    PatientCaseNewborn,
};

onMounted(() => {
    connectWebSocket();
    loadScheduleBlock();
});
onUnmounted(() => {
    closeWebSocket();
});

function getCase(exam, id, template) {
    axios
        .get(`question/${id}?exam=${exam}`)
        .then((response) => {
            question.value = response.data.Question;
            casetype.value = response.data.CaseType;
            media.value = response.data.Media.filter(m => m.Answer === false).map((ele) => ({ ID: ele.ID, URL: `media/${ele.ID}?exam=${exam}` }));
            templatename.value = template;
        })
        .catch(() => {
            templatename.value = "Error";
        });
}
function getAttestation(exam, id) {
    axios
        .get(`Examiners/Candidate/${id}?exam=${exam}`)
        .then((response) => {
            examiners.value = response.data.sort((a, b) => (a.FullName > b.FullName ? 1 : b.FullName > a.FullName ? -1 : 0));
            templatename.value = "AttestationForm";
        })
        .catch(() => {
            templatename.value = "Error";
        });
    axios
        .get(`ExamSettings?exam=${exam}`)
        .then((response) => {
            disclaimer.value = response.data.DisclaimerText;
        })
        .catch(() => {
            templatename.value = "Error";
        });
}
function loadScheduleBlock() {
    axios.get(`Schedule/Current`).then((response) => {
        scheduleblock.value = response.data;
    });
}
function timerCompleted() {
    loadScheduleBlock();
    question.value = "";
    media.value = [];
}

const client = new WebPubSubClient({
    getClientAccessUrl: async () => {
        return (await axios.get("authentication/pubsub")).data;
    },
});

function connectWebSocket() {
    client.start();
    client.on("group-message", (event) => {
        const wsdata = event.message.data;
        if (wsdata.command === "case") {
            fullscreenmedia.value = null;
            getCase(wsdata.exam, wsdata.id, wsdata.template);
        } else if (wsdata.command === "clear") {
            templatename.value = "";
            question.value = "";
            casetype.value = "";
            fullscreenmedia.value = null;
            examiners.value = [];
            disclaimer.value = "";
            media.value = [];
        } else if (wsdata.command === "fullscreen") {
            fullscreenmedia.value = wsdata.mediaID;
        } else if (wsdata.command === "attestation") {
            getAttestation(wsdata.exam, wsdata.id);
        }
    });
    const payload = Token.payload();
    client.joinGroup(payload.sub);
    // axios.get("authentication/pubsub").then((result) => {
    //     ws.value = new WebSocket(result.data, "json.webpubsub.azure.v1");
    //     // initial connection
    //     ws.value.onopen = () => {
    //         const payload = Token.payload();
    //         ws.value.send(
    //             JSON.stringify({
    //                 type: "joinGroup",
    //                 group: payload.sub,
    //             })
    //         );
    //     };
    //     // received message
    //     ws.value.onmessage = (event) => {
    //         const wsdata = JSON.parse(event.data);
    //         if (wsdata.type === "message") {
    //             if (wsdata.data.command === "case") {
    //                 fullscreenmedia.value = null;
    //                 templatename.value = wsdata.data.template;
    //                 getCase(wsdata.data.exam, wsdata.data.id);
    //             } else if (wsdata.data.command === "clear") {
    //                 question.value = "";
    //                 media.value = [];
    //             } else if (wsdata.data.command === "fullscreen") {
    //                 fullscreenmedia.value = wsdata.data.mediaID;
    //             } else if (wsdata.data.command === "attestation") {
    //                 templatename.value = "AttestationForm";
    //                 getAttestation(wsdata.data.exam, wsdata.data.id);
    //             }
    //         }
    //     };
    // });
}
function closeWebSocket() {
    client.stop();
    // if (ws.value) ws.value.close();
}
</script>
