export default class AbogDate extends Date {
    constructor(date) {
        if (Object.prototype.toString.call(date) === "[object Date]" || Object.prototype.toString.call(date) === "[object Number]") {
            super(date);
        } else if (date === undefined) {
            super();
        } else {
            // try parsing sql dates
            const datetimeparts = date.includes("T") ? date.trim().split("T") : [date.trim()];
            let y = 0,
                m = 0,
                d = 0,
                h = 0,
                n = 0,
                s = 0;
            // date
            if (datetimeparts[0].includes("/")) {
                //6/18/2020
                const parts = datetimeparts[0].split("/");
                m = parseInt(parts[0]) - 1;
                d = parseInt(parts[1]);
                y = parseInt(parts[2]);
            } else {
                //2020-06-18
                const parts = datetimeparts[0].split("-");
                m = parseInt(parts[1]) - 1;
                d = parseInt(parts[2]);
                y = parseInt(parts[0]);
            }
            // time
            if (datetimeparts.length > 1) {
                //handle the time that was passed
                const parts = datetimeparts[1].split(":");
                h = parseInt(parts[0]);
                n = parseInt(parts[1]);
                s = parseInt(parts[2]);
            }
            super(y, m, d, h, n, s);
        }
    }
    getMonthName() {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthNames[this.getMonth()];
    }
    toABOGDateString() {
        return `${this.getMonth() + 1}/${this.getDate()}/${this.getFullYear()}`;
    }
    toABOGDateLongString() {
        return `${this.getMonthName()} ${this.getDate()}, ${this.getFullYear()}`;
    }
    toABOGTimeString() {
        let hour = this.getHours();
        let minute = this.getMinutes();
        let second = this.getSeconds();
        let ap = "AM";
        if (hour > 11) {
            ap = "PM";
        }
        if (hour > 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        //if (hour < 10) { hour = "0" + hour; }
        if (minute < 10) {
            minute = "0" + minute;
        }
        if (second < 10) {
            second = "0" + second;
        }
        // output
        return `${hour}:${minute}:${second} ${ap}`;
    }
    toABOGDateTimeString() {
        return this.toABOGDateString() + " " + this.toABOGTimeString();
    }
    toABOGDateTimeLongString() {
        return this.toABOGDateLongString() + " " + this.toABOGTimeString();
    }
}
