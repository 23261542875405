<template>
    <div>
        <h2 class="font-bold text-2xl mb-4">Custom Grades</h2>
        <button class="blue-button mr-2" @click="showEditDialog(true)">
            <i class="fa fa-plus-circle" />
            Add
        </button>
        <button class="blue-button mx-2" :disabled="!currentGradeCustom" @click="showEditDialog(false)">
            <i class="fa fa-pencil" />
            Edit
        </button>
        <button class="blue-button mx-2" :disabled="!currentGradeCustom" @click="deleteGradeCustom()">
            <i class="fa-solid fa-trash" />
            Delete
        </button>
        <label class="blue-button inline-block cursor-pointer select-none mx-2">
            <i class="fa-solid fa-file-import" />
            Import
            <input type="file" accept=".csv" @change="importGradeCustom" hidden :disabled="importDialog.importing" />
        </label>
        <div class="my-4">
            <AbogTable :data="gradecustom" :columns="tableColumns" initialKey="ID" @selected="selectGradeCustom" />
        </div>
        <!-- edit dialog -->
        <AbogEditDialog v-if="editDialog.show" :isAdd="editDialog.isAdd" entity="Custom Grade" @close="closeEditDialog" :saveCallback="saveGradeCustom">
            <div class="mb-2">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">Custom Grade Description</span>
                    <input type="text" v-model="currentGradeCustom.Description" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
            <div class="mb-2">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">ID</span>
                    <input type="text" v-model.trim="currentGradeCustom.ExternalID" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
            <div class="mb-2" v-if="editDialog.isAdd== false">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">Sort Order</span>
                    <input type="text" v-model.trim="currentGradeCustom.Index" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
        </AbogEditDialog>
        <!-- import dialog -->
        <AbogModal :title="importDialog.title" v-if="importDialog.show" :width="40" @close="closeImportDialog()">
            <label v-if="importDialog.importing" class="flex items-center justify-center whitespace-nowrap my-6">
                Importing...
                <AbogLoading class="w-12 mx-2" />
            </label>
            <div v-if="!importDialog.importing">
                <div class="mb-2 whitespace-pre-wrap">
                    {{ importDialog.message }}
                </div>
                <div class="text-right py-4">
                    <button @click="closeImportDialog()" class="blue-button mx-2">OK</button>
                </div>
            </div>
        </AbogModal>
    </div>
</template>
<script setup>
import { inject, ref, onMounted } from "vue";
import AbogLoading from "@/pages/components/AbogLoading";
import AbogModal from "@/pages/components/AbogModal";
import AbogTable from "@/pages/components/AbogTable";
import AbogEditDialog from "@/pages/components/AbogEditDialog";
import AbogAlert from "@/pages/components/AbogAlert";
const axios = inject("axios");
const gradecustom = ref([]);
const currentGradeCustom = ref(null);
const editDialog = ref({ show: false, isAdd: false });
const importDialog = ref({ show: false, importing: false, title: "", message: "" });
const tableColumns = [
    { prop: "Description", display: "Description", align: "left" },
    { prop: "ExternalID", display: "ID", align: "left" },
    { prop: "ID", hide: true, key: true },
    { prop: "Index", display: "Sort Order", align: "left" },
];

onMounted(() => {
    loadGradeCustom();
});

function loadGradeCustom() {
    axios.get("ExamSettings/GradeCustom").then((response) => {
        gradecustom.value = response.data;
    });
}

function selectGradeCustom(id) {
    currentGradeCustom.value = gradecustom.value.find((e) => e.ID === id);
}

function showEditDialog(add) {
    if (add) currentGradeCustom.value = { ID: 0, FullName: "", ExternalID: "", Index: 0 };
    editDialog.value = { show: true, isAdd: add };
}

function closeEditDialog() {
    editDialog.value = { show: false, isAdd: false };
}

async function saveGradeCustom() {
    let result = null;
    await axios
        .put("ExamSettings/GradeCustom", currentGradeCustom.value)
        .then((response) => {
            loadGradeCustom();
            currentGradeCustom.value = response.data;
        })
        .catch((error) => {
            result = error.response.data;
        });
    return result;
}

function deleteGradeCustom() {
        const msg = `Delete Custom Grade "${currentGradeCustom.value.Description}?  Deleting it will permanently remove it from all cases. Continue?`;
        AbogAlert.confirm(msg).then(() => {
            axios
                .delete(`ExamSettings/GradeCustom/${currentGradeCustom.value.ID}`)
                .then(() => {
                    loadGradeCustom();
                })
                .catch((error) => {
                    console.log("Err");
                    console.log(error);
                    AbogAlert.error(error.response.data);
                });
        });
}

function closeImportDialog() {
    importDialog.value = { show: false, importing: false, title: "", message: "" };
}

function importGradeCustom(e) {
    importDialog.value = { show: true, importing: true, title: "Import Custom Grades", message: "" };
    const file = e.target.files[0];
    axios
        .post("ImportGradeCustom", file)
        .then((response) => {
            let titleMessage = "";
            switch (response.data.Result) {
                case 0:
                    titleMessage = "Custom Grades Import Success!";
                    break;
                case 1:
                    titleMessage = "Custom Grades Import Completed with Errors";
                    break;
                case 2:
                    titleMessage = "Custom Grades Import Failed";
                    break;
            }
            importDialog.value = { show: true, importing: false, title: titleMessage, message: response.data.Message };
            e.target.value = null;
            loadGradeCustom();
        })
        .catch((error) => {
            importDialog.value = { show: true, importing: false, title: "Error", message: error.response.data };
        });
}
</script>
