<template>
    <div>
        <div v-if="loading" class="text-center">
            <div class="text-2xl">Loading Attestation</div>
            <AbogLoading class="w-40" />
        </div>
        <div v-else-if="loaderror" class="text-center p-4">
            <h3 class="text-lg font-bold">Error Loading Attestation</h3>
            <p>Please reload to try again. If errors persist contact your exam administrator.</p>
        </div>
        <div v-else class="md:flex">
            <div class="w-full p-4 md:w-1/3">
                <div v-if="!complete">
                    <p class="mb-4">Make sure the <strong>candidate</strong> agrees to the statements before clicking "Agree"</p>
                    <button class="green-button" @click="sendCase">Display</button>
                    <button :class="!displayBtnClicked ? 'border border-abogblue-100 bg-abogblue-100 rounded-lg px-4 py-2 text-white mx-2 cursor-default' : ' blue-button mx-2'" @click="signAttestation">Agree</button>
                    <button class="purple-button" @click="skipAttestation">Skip</button>
                    <p v-if="showDisabledText" class="mt-4 text-abogred-600">Please click "Display" before Agreeing</p>
                </div>
            </div>
            <AttestationForm class="w-full md:w-2/3 m-4" :complete="complete" :examiners="examiners" :disclaimer="disclaimer" />
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, inject } from "vue";
import { useRouter } from "vue-router";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import Token from "@/token";
import AbogLoading from "@/pages/components/AbogLoading.vue";
import AttestationForm from "@/pages/components/AttestationForm.vue";

// inject globals
const axios = inject("axios");
const router = useRouter();

const props = defineProps(["schedule"]);

//data
const examiners = ref([]);
const disclaimer = ref("");
const complete = ref(false);
//const saved = ref(false);
const loading = ref(false);
const loaderror = ref(false);
//const countdown = ref(5);
const displayBtnClicked = ref(false);
const showDisabledText = ref(false);
//computed
// const loading = computed(() => conflictLoad || diclaimerLoad || attestedLoad);

onMounted(() => {
    loadAttestationData();
    connectWebSocket();
});
onUnmounted(() => {
    closeWebSocket();
});

function loadAttestationData() {
    loading.value = true;
    loaderror.value = false;
    Promise.all([
        axios.get(`Examiners/Candidate/${props.schedule.CandidateID}`).then((response) => {
            examiners.value = response.data.sort((a, b) => (a.FullName > b.FullName ? 1 : b.FullName > a.FullName ? -1 : 0));
        }),
        axios.get(`Candidate/${props.schedule.CandidateID}`).then((response) => {
            complete.value = response.data.AttestationDate !== null;
        }),
        axios.get("ExamSettings").then((response) => {
            disclaimer.value = response.data.DisclaimerText;
        }),
    ])
        .catch(() => {
            console.log("error");
            loaderror.value = true;
        })
        .finally(() => {
            loading.value = false;
        });
}

function signAttestation() {
    if (displayBtnClicked.value) {
        axios.put(`Candidate/${props.schedule.CandidateID}/attestation`).then((response) => {
            examiners.value = response.data;
            // redirect to exam
            router.push({ name: "Cases" });
        });
    } else {
        showDisabledText.value = true;
    }
}

function skipAttestation() {
    router.push({ name: "Cases" });
}

function sendCase() {
    showDisabledText.value = false;
    displayBtnClicked.value = true;
    const tokenpayload = Token.payload();
    sendWebSocket({ command: "attestation", exam: tokenpayload.ExamName, id: props.schedule.CandidateID });
}

/////////////////////////////////////////////////////////////////////
// websocket stuff
/////////////////////////////////////////////////////////////////////
const client = new WebPubSubClient({
    getClientAccessUrl: async () => {
        return (await axios.get("authentication/pubsub")).data;
    },
});
// const ws = ref(null);

function connectWebSocket() {
    client.start();
    // axios.get("authentication/pubsub").then((result) => {
    //     ws.value = new WebSocket(result.data, "json.webpubsub.azure.v1");
    // });
}

function sendWebSocket(wsdata) {
    // if (!presenting.value) return; // don't send messages if we aren't presenting
    client.sendToGroup(props.schedule.RoomNumber, wsdata, "json");
    // ws.value.send(
    //     JSON.stringify({
    //         type: "sendToGroup", // if we don't want to join a room
    //         group: props.schedule.RoomNumber,
    //         dataType: "json",
    //         data: wsdata,
    //     })
    // );
}

function closeWebSocket() {
    client.stop();
    // if (ws.value) ws.value.close();
}
</script>
