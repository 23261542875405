<template>
    <div class="ml-20 font-bold py-2">
        <router-link v-for="link in links" :key="link.Url" :to="link.Url" class="text-abogblue-300 cursor-pointer">/ {{ link.Name }}</router-link>
        <span> / {{ current }}</span>
    </div>
</template>
<script>
export default {
    props: ["links", "current"],
};
</script>
