<template>
    <div class="flex flex-col w-full h-full">
        <div v-if="filterable" class="w-full flex">
            <div class="w-1/2">
                <input type="text" class="pl-2 py-1 w-full" placeholder="Filter..." v-model="filter.text" />
            </div>
            <div class="w-1/2 flex justify-end font-medium pr-4">{{ filteredData.length }} {{ filteredData.length === 1 ? "row" : "rows" }} listed</div>
        </div>
        <table class="mt-2">
            <tr>
                <th v-for="(column, i) in visibleColumns" :key="i" class="tab" :class="column.alignHeader !== null ? 'text-' + column.alignHeader : 'text-center'" @click="selectColumn(column)">
                    <span class="pr-2">{{ column.display || column.prop }}</span>
                    <i class="fas fa-sort-up" v-if="selected.column.prop === column.prop && filter.orderAscending"></i>
                    <i class="fas fa-sort-down" v-if="selected.column.prop === column.prop && !filter.orderAscending"></i>
                </th>
            </tr>
            <tbody>
                <tr
                    class="cursor-pointer select-none"
                    v-for="(object, i) in filteredData"
                    :key="object[keyColumn.prop]"
                    :class="getObjectClass(object, i)"
                    @click.exact="selectObject(object)"
                    @click.ctrl.exact="selectObjectToggle(object)"
                    @click.shift.exact="selectObjectRange(object)"
                >
                    <td class="py-2 px-4" v-for="(column, i) in visibleColumns" :key="i" :class="getTextAlignmentClass(column, object[column.prop])">
                        <span v-if="column.html" v-html="cleanValueText(object[column.prop])"></span>
                        <span v-else>{{ cleanValueText(object[column.prop]) }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "AbogTable",
    props: {
        data: {
            required: true,
        },
        columns: {
            // exampleColumns: [
            //     { prop: "ID_A", display: "ID", key: true, hide: true },
            //     { prop: "Activity", display: "Name", html: true },
            //     { prop: "StatusText", display: "Status", alignHeader: "end" },
            // ],
            type: Array,
            required: true,
        },
        styles: {
            // exampleStyles: [{ flag: "IsInvalid", class: "bg-yellow-300" }],
            type: Array,
            required: false,
        },
        // scrollable: {
        //     type: Boolean,
        //     required: false,
        //     default: true,
        // },
        filterable: {
            type: Boolean,
            required: false,
            default: false,
        },
        initialKey: {
            required: false,
        },
        multiselect: {
            type: Boolean,
            required: false,
            default: false,
        },
        initialsort: {
            required: false,
        },
    },
    data() {
        return {
            selected: {
                column: {},
                objects: [],
            },
            filter: {
                orderAscending: true, // up arrow
                text: "",
            },
        };
    },
    computed: {
        initialsortcomp() {
            // no default sorts
            if (this.initialsort === undefined || this.initialsort === null) {
                return;
            }
            // if we pass in a single number use that column and boolean for direction
            // true ascending and false is decesnding
            if (typeof this.initialsort === "number" || this.initialsort instanceof Number) {
                const val = this.initialsort;
                return {
                    column: val,
                    ascending: true,
                };
            }
            // use whatever we passed in
            return this.initialsort;
        },
        visibleColumns() {
            return this.columns.filter((c) => !c.hide);
        },
        filteredData() {
            let filteredData = [];
            for (var i in this.data) {
                if (this.getObjectText(this.data[i]).includes(this.filter.text.toLowerCase())) filteredData.push(this.data[i]);
            }
            filteredData.sort((a, b) => {
                if (this.selected.column.type === "date") {
                    // convert values to dates
                    let nullreplace = "1/1/1900"; // replace nulls with this
                    let sortval = Date.parse(a[this.selected.column.prop] || nullreplace) - Date.parse(b[this.selected.column.prop] || nullreplace);
                    return this.filter.orderAscending ? sortval : sortval * -1;
                } else {
                    var aVal = a[this.selected.column.prop] == null ? "" : a[this.selected.column.prop];
                    var bVal = b[this.selected.column.prop] == null ? "" : b[this.selected.column.prop];
                    if (aVal > bVal) return this.filter.orderAscending ? 1 : -1;
                    if (bVal > aVal) return this.filter.orderAscending ? -1 : 1;
                    return 0;
                }
            });
            return filteredData;
        },
        keyColumn() {
            for (var i in this.columns) {
                if (this.columns[i].key) return this.columns[i];
            }
            return null;
        },
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            if (this.initialsortcomp) {
                this.selectColumn(this.visibleColumns[this.initialsortcomp.column], this.initialsortcomp.ascending);
            }
        },
        getTextAlignmentClass(column, value) {
            if (column.align === "right") return "text-right";
            if (column.align === "center") return "text-center";
            if (column.align === "left") return "text-left";
            if (typeof value === "number") return "text-right";
            return "text-center";
        },
        rowRef(keyColumnValue) {
            return `row-${keyColumnValue}`;
        },
        selectColumn(column, ascending) {
            if (this.selected.column.prop == column.prop) {
                this.filter.orderAscending = ascending === undefined ? !this.filter.orderAscending : ascending;
            } else {
                this.selected.column = column;
                this.filter.orderAscending = ascending === undefined ? true : ascending;
            }
        },
        getObjectClass(object, rowNum) {
            if (typeof this.initialKey !== "undefined" && object[this.keyColumn.prop] === this.initialKey && !this.initialRowSet) {
                this.initialRowSet = true;
                return "active-row";
            }

            if (this.selected.objects.includes(object)) return "active-row";
            for (var i in this.styles) {
                if (object[this.styles[i].flag]) return this.styles[i].class;
            }
            return rowNum % 2 == 0 ? "bg-aboggray-100" : "";
        },
        getObjectText(object) {
            var text = "";
            for (var i in this.columns) {
                text += object[this.columns[i].prop] + "-";
            }
            return text.toLowerCase();
        },
        cleanValueText(value) {
            if (typeof value == "undefined" || value == null) return "";
            if (typeof value == "boolean") return value ? "Yes" : "";
            return value.toString().replace("T00:00:00", "");
        },
        selectObject(object) {
            this.selected.objects = [object];
            this.emitSelect();
        },
        selectObjectToggle(object) {
            if (this.multiselect) {
                let i = this.selected.objects.indexOf(object);
                if (i >= 0) {
                    this.selected.objects.splice(i, 1);
                } else {
                    this.selected.objects.push(object);
                }
                this.emitSelect();
            }
        },
        selectObjectRange(object) {
            if (this.multiselect) {
                // most recently added
                let i = this.selected.objects.length - 1;
                if (i >= 0) {
                    let prevobj = this.selected.objects[i];
                    let inrange = false;
                    for (let j in this.filteredData) {
                        let compobj = this.filteredData[j];
                        if (compobj == prevobj || compobj == object) {
                            inrange = !inrange;
                        }
                        if (inrange && !this.selected.objects.includes(compobj)) {
                            this.selected.objects.push(compobj);
                        }
                    }
                    // we will skip the final object if it occurs after the "prevobj"
                    //  in our list, so add it here
                    if (!this.selected.objects.includes(object)) {
                        this.selected.objects.push(object);
                    }
                    this.emitSelect();
                } else {
                    this.selectObject(object);
                }
            }
        },
        emitSelect() {
            if (this.keyColumn != null) {
                if (!this.multiselect) {
                    if (this.selected.objects.length === 1) {
                        this.$emit("selected", this.selected.objects[0][this.keyColumn.prop]);
                    }
                } else {
                    this.$emit(
                        "selected",
                        this.selected.objects.map((obj) => obj[this.keyColumn.prop])
                    );
                }
            }
        },
    },
};
</script>

<style scoped>
.tab {
    @apply py-2 cursor-pointer select-none font-bold bg-abogblue-400 text-white;
}
/* .tab:hover {
    @apply bg-gray-400;
}
.row:hover {
    @apply bg-gray-400;
} */
.active-row {
    @apply bg-abogyellow-100;
}
/* .active-row:hover {
    @apply bg-yellow-200;
} */
</style>
