<template>
    <svg class="inline" version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="44" stroke-width="12" stroke="currentColor" stroke-linecap="round" stroke-dasharray="135.717" stroke-dashoffset="135.717" fill="none">
            <animateTransform 
                attributeName="transform" 
                attributeType="XML" 
                type="rotate"
                dur="1s" 
                from="0 50 50"
                to="360 50 50" 
                repeatCount="indefinite" />
        </circle>
    </svg>
</template>