<template>
    <BreadCrumb current="Schedule" />
    <ul v-if="dayLookup.length > 1" class="flex justify-center ml-0 list-none">
        <li v-for="day in dayLookup" :key="day" class="px-2">
            <a
                class="inline-block py-2 px-4 font-bold cursor-pointer"
                :class="{
                    'text-gray-400': !isScheduled(day),
                    'text-abogblue-200': isScheduled(day),
                    'text-abogblue-300 border-t border-x rounded-t -mb-1 bg-white': selectedDay === day,
                }"
                @click="selectedDay = day"
            >
                {{ day }}
            </a>
        </li>
    </ul>
    <div>
        <table class="mx-auto border-t">
            <thead class="font-bold hidden md:table-header-group">
                <tr>
                    <th class="text-left p-4">Candidate</th>
                    <th class="p-4">Time</th>
                    <th class="p-4">Room</th>
                    <th class="text-left p-4">Assignment</th>
                    <th class="text-left p-4">Partners</th>
                    <th class="p-4">Status <i class="cursor-pointer fa fa-circle-question" @click="showIconKey = true" /></th>
                </tr>
            </thead>
            <tbody class="hidden md:table-row-group">
                <tr v-for="(sched, i) in selectedDaySchedule" :key="i" class="border-t text-abogblue-300" :class="{ 'cursor-pointer hover:bg-aboggray-100': sched.ID }" @click="goToSchedule(sched)">
                    <td class="p-4">{{ sched.CandidateName }}</td>
                    <td class="p-4">{{ sched.StartTime }}</td>
                    <td class="p-4">{{ sched.RoomNumber }}</td>
                    <td class="p-4">{{ sched.Assignment }}</td>
                    <td class="p-4">{{ sched.Partners.length === 0 ? "None" : sched.Partners.length < 2 ? sched.Partners.join(", ") : "Multiple" }}</td>
                    <td class="p-4 text-center"><i class="text-2xl fa" :class="calcIconClass(sched)" /></td>
                </tr>
            </tbody>
            <tbody class="table-row-group md:hidden">
                <tr v-for="(sched, i) in selectedDaySchedule" :key="i" class="border-t text-abogblue-300" :class="{ 'cursor-pointer hover:bg-aboggray-100': sched.ID }" @click="goToSchedule(sched)">
                    <td class="p-4">
                        <i class="float-right text-2xl fa" :class="calcIconClass(sched)" />
                        <div>{{ sched.Assignment }} at {{ sched.StartTime }}</div>
                        <div>Room {{ sched.RoomNumber }} with {{ sched.Partners.length === 0 ? "None" : sched.Partners.length < 2 ? sched.Partners.join(", ") : "Multiple" }}</div>
                        <div v-if="sched.CandidateName">Candidate {{ sched.CandidateName }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div v-if="loaderror" class="text-center p-4">
        <h3 class="text-lg font-bold">Error Loading Schedule</h3>
        <p>Please reload to try again. If errors persist contact your exam administrator.</p>
    </div>
    <AbogModal v-if="showIconKey" title="Status Icons" @close="showIconKey = false">
        <div v-for="status in iconLookup" :key="status" class="m-4"><i class="text-center w-10 text-2xl fa" :class="status.Class" /> {{ status.Name }}</div>
    </AbogModal>
</template>
<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import { useExamStore } from "@/pages/components/ExamStore";
import AbogDate from "@/pages/components/AbogDate";
import AbogModal from "@/pages/components/AbogModal";
import BreadCrumb from "@/pages/components/BreadCrumb";

const axios = inject("axios");
const router = useRouter();
const exam = useExamStore();

const schedule = ref([]);
const selectedDay = ref("");
const showIconKey = ref(false);
const loaderror = ref(false);

const selectedDaySchedule = computed(() => {
    return schedule.value.filter((c) => c.Date === selectedDay.value);
});
const dayLookup = computed(() => {
    return exam.Days.map((d) => new AbogDate(d).toABOGDateString());
});
const iconLookup = [
    { Name: "Pending", Class: "fa-clock text-abogblue-100" }, // 0
    { Name: "Active", Class: "fa-play text-abogblue-300" }, // 1
    { Name: "Complete", Class: "fa-check text-aboggreen-400" }, // 2
    { Name: "Grading", Class: "fa-circle-exclamation text-abogred-600" }, // 3  if (Status === "Complete" & GradesSubmittedOn === null)
];

onMounted(() => {
    loadSchedule();
});

function loadSchedule() {
    const timeslotkindlookup = ["Exam", "Caucus", "Candidate Review", "Other"];
    loaderror.value = false;
    Promise.all([axios.get("timeslots"), axios.get("schedule")])
        .then((response) => {
            // force schedule to display every day/timeslot combo and fills schedule when available
            const timeslotdata = response[0].data;
            const scheduledata = response[1].data;
            const today = new AbogDate(exam.CurrentDay).toABOGDateString();
            selectedDay.value = dayLookup.value[0];
            exam.Days.forEach((day) => {
                const fdate = new AbogDate(day).toABOGDateString();
                if (fdate === today) {
                    selectedDay.value = fdate;
                }
                timeslotdata.forEach((timeslot) => {
                    let schedrow = scheduledata.find((s) => s.Date === day && s.StartTime === timeslot.StartTime);
                    if (schedrow) {
                        schedrow.Date = fdate;
                        schedule.value.push(schedrow);
                    } else {
                        // not scheduled
                        schedule.value.push({
                            Date: fdate,
                            StartTime: timeslot.StartTime,
                            CandidateName: "",
                            RoomNumber: "",
                            Assignment: timeslotkindlookup[timeslot.Kind],
                            Partners: [],
                            Status: null,
                        });
                    }
                });
            });
        })
        .catch(() => {
            loaderror.value = true;
        });
}
function goToSchedule(sched) {
    // context based default navigation
    if (sched.Status === 2 && sched.CandidateID) router.push({ name: "Grades", params: { id_sb: sched.ID } });
    else router.push({ name: "Cases", params: { id_sb: sched.ID } });
}
function isScheduled(day) {
    return schedule.value.some((s) => s.Date === day);
}
function calcIconClass(sched) {
    if (!iconLookup[sched.Status]) return "";
    if (sched.Status === 2 && sched.GradesSubmittedOn === null && sched.CandidateID) return iconLookup[3].Class;
    return iconLookup[sched.Status].Class;
}
</script>
