class AbogAlert {
    static success(content) {
        var divElement = document.createElement("div");
        divElement.classList.add("bg-aboggreen-100", "border-aboggreen-300", "text-aboggreen-300", ...alertsharedclasses);
        showAlert(divElement, content, true);
    }

    static error(content) {
        var divElement = document.createElement("div");
        divElement.classList.add("bg-abogred-100", "border-abogred-600", "text-abogred-600", ...alertsharedclasses);
        showAlert(divElement, content);
    }

    static prompt(content, initValue = "") {
        return new Promise((resolve, reject) => {
            const container = document.createElement("div");
            container.classList.add(...containerclasses);
            container.onclick = (e) => {
                if (e.target === container) {
                    document.body.removeChild(container);
                    reject;
                }
            };
            const msgbox = document.createElement("div");
            msgbox.classList.add(...msgboxclasses);
            msgbox.style = msgboxcustomstyles;
            const msg = document.createElement("p");
            msg.textContent = content;
            const input = document.createElement("input");
            input.classList.add("border", "m-4", "px-4", "py-2");
            input.type = "text";
            input.value = initValue;
            input.onkeyup = (e) => {
                if (e.key === "Enter") {
                    document.body.removeChild(container);
                    resolve(input.value);
                }
                if (e.key === "Escape") {
                    document.body.removeChild(container);
                    reject;
                }
            };
            const btnbox = document.createElement("div");
            btnbox.classList.add("text-right");
            const btnsubmit = document.createElement("button");
            btnsubmit.classList.add("purple-button", "m-2");
            btnsubmit.textContent = "Submit";
            btnsubmit.onclick = () => {
                document.body.removeChild(container);
                resolve(input.value);
            };
            const btncancel = document.createElement("button");
            btncancel.classList.add("blue-button", "m-2");
            btncancel.textContent = "Cancel";
            btncancel.onclick = () => {
                document.body.removeChild(container);
                reject;
            };
            msgbox.appendChild(msg);
            msgbox.appendChild(input);
            btnbox.appendChild(btnsubmit);
            btnbox.appendChild(btncancel);
            msgbox.appendChild(btnbox);
            container.appendChild(msgbox);
            document.body.appendChild(container);
            input.focus();
        });
    }

    static confirm(content) {
        return new Promise((resolve, reject) => {
            const container = document.createElement("div");
            container.classList.add(...containerclasses);
            container.onclick = (e) => {
                if (e.target === container) {
                    document.body.removeChild(container);
                    reject;
                }
            };
            const msgbox = document.createElement("div");
            msgbox.classList.add(...msgboxclasses);
            msgbox.style = msgboxcustomstyles;
            const msg = document.createElement("p");
            msg.textContent = content;
            const btnbox = document.createElement("div");
            btnbox.classList.add("text-right", "whitespace-nowrap");
            const btnsubmit = document.createElement("button");
            btnsubmit.classList.add("purple-button", "m-2");
            btnsubmit.textContent = "OK";
            btnsubmit.onclick = () => {
                document.body.removeChild(container);
                resolve("ok");
            };
            const btncancel = document.createElement("button");
            btncancel.classList.add("blue-button", "m-2");
            btncancel.textContent = "Cancel";
            btncancel.onclick = () => {
                document.body.removeChild(container);
                reject;
            };
            msgbox.appendChild(msg);
            btnbox.appendChild(btnsubmit);
            btnbox.appendChild(btncancel);
            msgbox.appendChild(btnbox);
            container.appendChild(msgbox);
            document.body.appendChild(container);
        });
    }
}

const alertsharedclasses = ["border", "rounded-lg", "px-6", "py-4", "ml-2", "mt-2", "text-lg", "items-center", "w-full", "flex", "justify-between"];
const containerclasses = ["fixed", "z-20", "w-full", "h-full", "top-0", "left-0", "bg-black/50", "p-4", "flex", "items-center", "justify-center"];
const msgboxclasses = ["w-max", "max-w-prose", "max-h-full", "border-2", "rounded-lg", "p-4", "bg-white", "flex", "flex-col", "justify-between", "overflow-auto"];
const msgboxcustomstyles = "min-width: 40ch; min-height: 12em;";

function showAlert(e, content, autoclose) {
    var paragraph = document.createElement("p");
    paragraph.innerHTML = content;

    var close = document.createElement("i");
    close.classList.add("fa-solid", "fa-xmark", "ml-4", "p-2", "cursor-pointer");
    close.onclick = () => {
        e.remove();
    };

    e.appendChild(paragraph);
    e.appendChild(close);

    if (autoclose) {
        setInterval(() => {
            if (e) {
                e.remove();
            } else {
                clearInterval();
            }
        }, 3000);
        addToContainer(e);
    } else {
        addToContainer(e);
    }
}

function addToContainer(e) {
    //add if to check if div is here
    var container = document.getElementById("Abog-Alert-Container");

    if (container === null) {
        container = document.createElement("div");
        container.id = "Abog-Alert-Container";
        container.classList.add("fixed", "z-30", "top-4", "right-24");
        container.style = "min-width: 40ch;";
        document.body.appendChild(container);
    }
    container.appendChild(e);
}
export default AbogAlert;
