<template>
    <div class="fixed top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center z-10" @click.self="close()">
        <div class="relative shadow pt-10 rounded-lg bg-white mx-6 max-h-full overflow-auto" :style="getCustomWidth()">
            <div class="absolute top-0 left-0 w-full h-10 p-2 font-bold align-left">
                <span class="whitespace-nowrap inline-block w-full overflow-hidden">{{ title }}</span>
                <div v-if="hasCloseListener" class="absolute top-0 right-0 text-center w-10 h-10 p-2 font-bold cursor-pointer bg-white" @click="close()"><i class="fa fa-times" /></div>
            </div>
            <div class="w-full h-full p-4"><slot /></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AbogModal",
    props: ["title", "width"],
    computed: {
        hasCloseListener() {
            // return this.$listeners && this.$listeners.close;
            return this.$attrs.onClose !== undefined;
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        getCustomWidth() {
            if (this.width != null && this.width > 0) return "width: " + this.width + "%;";
        },
    },
};
</script>
