<template>
    <div v-if="loading" class="text-center">
        <div class="text-2xl">Loading Cases</div>
        <AbogLoading class="w-40" />
    </div>
    <div v-else-if="loaderror" class="text-center p-4">
        <h3 class="text-lg font-bold">Error Loading Cases</h3>
        <p>Please reload to try again. If errors persist contact your exam administrator.</p>
    </div>
    <div v-if="hasCaseList" class="md:flex">
        <div class="md:w-1/5 mx-4">
            <span class="whitespace-nowrap select-arrow">
                <span class="inline-block w-24 md:w-auto">Case Type:</span>
                <select v-model="caselistShow" class="font-bold w-32 md:w-auto md:ml-2" @change="caseTypeChange">
                    <option :value="false" class="font-normal">Structured</option>
                    <option :value="true" class="font-normal">Case List</option>
                </select>
            </span>
        </div>
        <div v-if="caselistShow" class="md:w-1/5 mx-4">
            <span class="whitespace-nowrap select-arrow">
                <span class="inline-block w-24 md:w-auto">Sort:</span>
                <select v-model="caselistSortCol" class="font-bold w-32 md:w-auto md:ml-2">
                    <option value="sel" class="font-normal">Selection</option>
                    <option value="desc" class="font-normal">Slide ID</option>
                    <option value="view" class="font-normal">Viewed</option>
                    <option value="cat" class="font-normal">Category</option>
                </select>
            </span>
        </div>
        <div v-if="caselistShow" class="md:w-1/5 mx-4">
            <span class="whitespace-nowrap select-arrow">
                <span class="inline-block w-24 md:w-auto">View:</span>
                <select v-model="caselistDisplayMode" class="font-bold w-32 md:w-auto md:ml-2">
                    <option value="compact" class="font-normal">Compact</option>
                    <option value="detail" class="font-normal">Detailed</option>
                </select>
            </span>
        </div>
        <div v-if="caselistShow" class="md:w-2/5 w-full px-4 relative">
            <input v-model="caselistFilterText" class="outline-none w-full border-b -mb-2" type="text" placeholder="Filter..." />
            <div class="absolute inset-y-0 right-0 flex items-center px-4 text-gray-700" :class="caselistFilterText !== '' ? 'cursor-pointer' : 'pointer-events-none'" @click="caselistFilterText = ''">
                <i :class="caselistFilterText !== '' ? 'fas fa-xmark' : 'fas fa-search'"></i>
            </div>
        </div>
        <div v-if="caselistShow" class="px-4 relative text-right">
            <i class="fa cursor-pointer text-2xl" :class="caselistReview ? 'fa-angles-left' : 'fa-angles-right'" @click="caselistReview = !caselistReview" />
        </div>
    </div>
    <div v-if="!loading && !loaderror" class="flex">
        <div class="p-4 slide-w" :class="caselistReviewMode ? 'w-full' : 'xl:w-1/4 md:w-1/3 sm:w-1/2 w-full'">
            <Transition name="expand-h">
                <div v-if="!caselistReviewMode">
                    <AbogSwitch :label="`Enable Display: Room ${props.schedule.RoomNumber}`" :checked="presenting" @checked="setPresenting" />
                    <div class="flex justify-between">
                        <button class="hollow-blue-button" @click="prev"><i class="fa fa-chevron-left" /></button>
                        <button class="hollow-blue-button" :disabled="!presenting" @click="clearCase">Clear</button>
                        <button class="green-button" :disabled="!presenting || currentquestion.qid === null" @click="sendCase">Display</button>
                        <button class="hollow-blue-button" @click="next"><i class="fa fa-chevron-right" /></button>
                    </div>
                    <div class="font-bold text-lg text-center">Cases</div>
                </div>
            </Transition>
            <div class="rounded-lg border border-abogblue-400 mb-2">
                <div v-for="(g, i) in typefilteredcasegroups" :key="g.Section">
                    <div class="cursor-pointer p-2 bg-abogblue-400 text-white border-b" :class="i === 0 ? 'rounded-t-lg' : !g.show && i === typefilteredcasegroups.length - 1 ? 'rounded-b-lg' : ''" @click="g.show = !g.show">
                        {{ g.Section }}
                    </div>
                    <Transition name="expand-h">
                        <div v-if="g.show || typefilteredcasegroups.length === 1">
                            <table class="w-full">
                                <tr v-for="c in filteredcases.filter((c) => c.Section === g.Section)" :key="c.ID" @click="selectCase(c.ID)" class="cursor-pointer border-b">
                                    <td class="px-4 py-2">{{ c.Description }}</td>
                                    <td class="px-4 py-2 whitespace-nowrap">
                                        <i
                                            v-for="q in c.Questions"
                                            :key="q.ID"
                                            class="text-sm mx-1"
                                            :class="{
                                                'font-bold': currentquestion.cid === c.ID && currentquestion.qid === q.ID,
                                                'far fa-circle-check': q.HasBeenDisplayed,
                                                'far fa-circle': !q.HasBeenDisplayed,
                                                glow: q.Display,
                                            }"
                                            @click="selectQuestion(c.ID, q.ID, $event)"
                                        />
                                    </td>
                                    <td class="px-4 py-2 hidden sm:table-cell" v-if="caselistShow">
                                        <div>{{ c.Selection }}</div>
                                    </td>
                                    <template v-if="caselistReviewMode">
                                        <td class="px-4 py-2 hidden sm:table-cell">
                                            <div :class="caselistDisplayMode === 'compact' ? 'w-20 whitespace-nowrap overflow-hidden fade-gradient-right' : 'whitespace-pre-wrap'">{{ c.Questions[0].Notes }}</div>
                                        </td>
                                        <td class="px-4 py-2 hidden md:table-cell" v-for="field in caselistReviewFields(c.Questions[0].Question, c.Section)" :key="field">
                                            <div :class="caselistDisplayMode === 'compact' ? 'w-20 whitespace-nowrap overflow-hidden fade-gradient-right' : 'whitespace-pre-wrap'">{{ field }}</div>
                                        </td>
                                    </template>
                                </tr>
                            </table>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
        <Transition name="expand-w">
            <div v-if="selectedCaseQuestionData.Question && !caselistReviewMode" class="xl:w-3/4 md:w-2/3 sm:w-1/2 p-4 hidden sm:block">
                <div class="mb-4">
                    <component
                        :is="casetemplates[selectedTemplate]"
                        :data="selectedCaseQuestionData.Question"
                        :casetype="selectedCaseQuestionData.CaseType"
                        @sendfullscreenrequest="sendFullScreen"
                        :fullscreenmedia="fullscreenmedia"
                        :media="selectedCaseQuestionData.Media.filter(m => m.Answer === false).map((ele) => ({ ID: ele.ID, URL: `media/${ele.ID}` }))"
                    />
                </div>
                <div v-if="filteredGrades.length > 0" class="w-full bg-abogyellow-100 rounded-lg py-2 px-4 mb-4">
                    <GradeInput :grades="filteredGrades" :gradeKeys="gradeKeys" :editable="!submitted" @saveGrade="saveGrade" />
                </div>
                <div v-if="selectedCaseQuestionData.Answer" class="w-full bg-abogyellow-100 rounded-lg py-2 px-4 mb-4">
                        <div class="flex" :class="{ 'flex-col': fullscreenmedia }">
                            <div v-if="selectedCaseQuestionData.Answer" class="w-full pr-2 whitespace-pre-wrap" v-html="selectedCaseQuestionData.Answer"></div>

                            <div v-if="selectedCaseQuestionData.Media.filter(m => m.Answer === true).map((ele) => ({ ID: ele.ID, URL: `media/${ele.ID}` })).length > 0" class="relative pl-2" :class="{ 'order-first w-full': fullscreenmedia }">
                                <AuthMedia
                                    v-for="med in selectedCaseQuestionData.Media.filter(m => m.Answer === true).map((ele) => ({ ID: ele.ID, URL: `media/${ele.ID}` }))"
                                    :key="med.ID"
                                    :src="med.URL"
                                    @click="sendFullScreen(med.ID, med.ID !== fullscreenmedia, false)"
                                    class="object-contain"
                                    :class="{ 'w-full fullscreenhmax': med.ID === fullscreenmedia, 'w-40 h-40': !fullscreenmedia, hidden: fullscreenmedia && med.ID !== fullscreenmedia }"
                                />
                                <div v-if="fullscreenmedia" @click="sendFullScreen(fullScreenMedia, false, false)" class="z-20 absolute top-0 right-0 w-10 h-10 p-2 font-bold cursor-pointer"><i class="text-2xl fa fa-times" /></div>
                            </div>
                        </div>
                </div>
                <div v-if="selectedCaseQuestionData.CaseType === 'Case List'" class="w-full bg-abogyellow-100 rounded-lg py-2 px-4 mb-4">
                    <h3 class="flex justify-between">
                        <span>Notes</span>
                        <span>{{ noteStatus }}</span>
                        <span class="whitespace-nowrap select-arrow">
                            <span class="inline-block w-auto">Selection Order:</span>
                            <select
                                v-model="selectedCaseQuestionData.Selection"
                                class="font-bold w-auto ml-2"
                                :disabled="props.schedule.Assignment !== 'Case List' || caselistNoteSaving || props.schedule.Status === 1"
                                @change="caselistSelectionChange()"
                            >
                                <option class="font-normal" :value="null"></option>
                                <option class="font-normal" v-for="i in Array(15).keys()" :key="i + 1" :value="i + 1">
                                    {{ i + 1 }}
                                </option>
                            </select>
                        </span>
                    </h3>
                    <textarea
                        v-model="selectedCaseQuestionData.Notes"
                        ref="noteinput"
                        :disabled="props.schedule.Assignment !== 'Case List' || caselistNoteSaving || props.schedule.Status === 1"
                        class="w-full h-60 bg-white"
                        @keyup="caselistNoteKeyup()"
                        @change="caselistNoteChange()"
                    ></textarea>
                    <span class="whitespace-nowrap select-arrow flex">
                        <span class="inline-block w-auto">Blueprint:</span>
                        <select v-model="selectedCaseQuestionData.ID_B" class="font-bold w-full ml-2" :disabled="props.schedule.Assignment !== 'Case List' || caselistNoteSaving || props.schedule.Status === 1" @change="blueprintChange()">
                            <option v-for="bp in blueprint" :key="bp.ID" :value="bp.ID">{{ bp.Description }}</option>
                        </select>
                    </span>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script setup>
import { ref, onMounted, computed, inject, onUnmounted, nextTick } from "vue";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import AbogLoading from "@/pages/components/AbogLoading";
import AbogSwitch from "@/pages/components/AbogSwitch";
import Token from "@/token";
import GradeInput from "@/pages/components/GradeInput";
import StructuredCase from "@/pages/components/casetemplates/StructuredCase";
import PatientCase from "@/pages/components/casetemplates/PatientCase";
import PatientCaseNewborn from "@/pages/components/casetemplates/PatientCaseNewborn";
import AbogAlert from "@/pages/components/AbogAlert";
import AuthMedia from "@/pages/components/AuthMedia";

const props = defineProps(["schedule"]);
const axios = inject("axios");
const casetemplates = {
    StructuredCase,
    PatientCase,
    PatientCaseNewborn,
};
// page load
onMounted(() => {
    loadCases();
    loadBlueprint();
    if (props.schedule.CandidateID) loadGrades(); // only load grades if we have a candidate
    connectWebSocket();
    if (props.schedule.Status === 1) setPresenting(true); // default to presenting for active
    // consider adding an event listener to block navigation while notes are saving... leaving out for now
});
onUnmounted(() => {
    closeWebSocket();
});

/////////////////////////////////////////////////////////////////////
// case display stuff
/////////////////////////////////////////////////////////////////////
// cases
const cases = ref([]);
const casegroups = ref([]);
// state
const currentquestion = ref({ cid: null, qid: null });
const presenting = ref(false);
const fullscreenmedia = ref(null);
const loading = ref(true);
const loaderror = ref(false);

const selectedCaseQuestionData = computed(() => {
    const cc = filteredcases.value.find((c) => c.ID === currentquestion.value.cid);
    if (cc) {
        const cq = cc.Questions.find((q) => q.ID === currentquestion.value.qid);
        if (cq) {
            return { ...cq, CaseType: cc.CaseType, Section: cc.Section, Selection: cc.Selection };
        }
    }
    return {};
});
const filteredcases = computed(() => {
    const prefilteredcases = cases.value.filter((c) => {
        return (
            caselistFilterText.value === "" ||
            c.Questions.some((q) => {
                return q.Notes.toLowerCase().includes(caselistFilterText.value.toLowerCase()) || q.Question.toLowerCase().includes(caselistFilterText.value.toLowerCase());
            })
        );
    });
    if (caselistShow.value) {
        const sortfield = caselistSortCol.value;
        prefilteredcases.sort((a, b) => {
            if (sortfield === "sel") {
                if (a.Selection === null && b.Selection !== null) return 1;
                if (!a.Selection !== null && b.Selection === null) return -1;
                if (a.Selection > b.Selection) return 1;
                if (a.Selection < b.Selection) return -1;
                return 0;
            } else if (sortfield === "cat") {
                const qa = a.Questions[0];
                const qb = b.Questions[0];
                if (qa.CategoryDescription === "" && qb.CategoryDescription !== "") return 1;
                if (qa.CategoryDescription !== "" && qb.CategoryDescription === "") return -1;
                if (qa.CategoryDescription > qb.CategoryDescription) return 1;
                if (qa.CategoryDescription < qb.CategoryDescription) return -1;
                return 0;
            } else if (sortfield === "view") {
                const qa = a.Questions[0];
                const qb = b.Questions[0];
                if (qa.HasBeenDisplayed < qb.HasBeenDisplayed) return 1;
                if (qa.HasBeenDisplayed > qb.HasBeenDisplayed) return -1;
                return 0;
            } else {
                // desc is default
                if (a.Description > b.Description) return 1;
                if (a.Description < b.Description) return -1;
                return 0;
            }
        });
        return prefilteredcases.filter((c) => c.CaseType === "Case List");
    } else return prefilteredcases.filter((c) => c.CaseType !== "Case List");
});
const typefilteredcasegroups = computed(() => {
    return casegroups.value.filter((g) => filteredcases.value.some((c) => g.Section === c.Section));
});
const selectedTemplate = computed(() => {
    if (selectedCaseQuestionData.value.CaseType === "Case List") {
        return caselistComponentLookup[selectedCaseQuestionData.value.Section].component;
    } else {
        return "StructuredCase";
    }
});

function loadCases() {
    loading.value = true;
    loaderror.value = false;
    axios
        .get(`schedule/${props.schedule.ID}/cases`)
        .then((response) => {
            response.data.sort((a, b) => (a.Section < b.Section ? -1 : a.Section > b.Section ? 1 : 0));
            response.data.forEach((c) => {
                c.Questions.sort((a, b) => (a.Index < b.Index ? -1 : a.Index > b.Index ? 1 : 0));
                c.Questions.forEach((q) => {
                    q.Display = false;
                });
                if (!casegroups.value.some((g) => g.Section === c.Section)) {
                    casegroups.value.push({ Section: c.Section, show: false });
                }
            });
            if (casegroups.value.length) casegroups.value[0].show = true;
            cases.value = response.data;
        })
        .catch(() => {
            loaderror.value = true;
        })
        .finally(() => {
            loading.value = false;
        });
}

function prev() {
    currentquestion.value = calcPrevQuestion();
}
function calcPrevQuestion() {
    const firstcase = filteredcases.value[0];
    let cid = firstcase.ID;
    let qid = firstcase.Questions[0].ID;
    if (currentquestion.value.cid === null && currentquestion.value.qid === null) {
        return { cid: cid, qid: qid };
    }

    for (let ci = 0; ci < filteredcases.value.length; ci++) {
        const c = filteredcases.value[ci];
        for (let qi = 0; qi < c.Questions.length; qi++) {
            const q = c.Questions[qi];
            if (currentquestion.value.cid === c.ID && currentquestion.value.qid === q.ID) {
                return { cid: cid, qid: qid };
            }
            cid = c.ID;
            qid = q.ID;
        }
    }
    return { cid: cid, qid: qid };
}
function next() {
    currentquestion.value = calcNextQuestion();
}
function calcNextQuestion() {
    const lastcase = filteredcases.value[filteredcases.value.length - 1];
    let cid = lastcase.ID;
    let qid = lastcase.Questions[lastcase.Questions.length - 1].ID;

    for (let ci = filteredcases.value.length - 1; ci >= 0; ci--) {
        const c = filteredcases.value[ci];
        for (let qi = c.Questions.length - 1; qi >= 0; qi--) {
            const q = c.Questions[qi];
            if (currentquestion.value.cid === c.ID && currentquestion.value.qid === q.ID) {
                return { cid: cid, qid: qid };
            }
            cid = c.ID;
            qid = q.ID;
        }
    }
    return { cid: cid, qid: qid };
}
function selectCase(cid) {
    caselistReview.value = false; // clicking a case will collapse the case list menu (if it's open)
    const selectedCase = cases.value.find((c) => c.ID === cid);
    if (selectedCase && selectedCase.Questions.length > 0) {
        currentquestion.value = { cid: cid, qid: selectedCase.Questions[0].ID };
    }
}
function selectQuestion(cid, qid, event) {
    event.stopPropagation();
    caselistReview.value = false; // clicking a case will collapse the case list menu (if it's open)
    const selectedCase = cases.value.find((c) => c.ID === cid);
    if (selectedCase) {
        const selectedQuestion = selectedCase.Questions.find((q) => q.ID === qid);
        if (selectedQuestion) {
            currentquestion.value = { cid: cid, qid: qid };
        }
    }
}
function clearCase() {
    currentquestion.value = { cid: null, qid: null };
    cases.value.forEach((c) =>
        c.Questions.forEach((q) => {
            q.Display = false;
        })
    );
    sendWebSocket({ command: "clear" });
}
function sendCase() {
    if (currentquestion.value.qid === null) return;
    cases.value.forEach((c) =>
        c.Questions.forEach((q) => {
            if (c.ID === currentquestion.value.cid && q.ID === currentquestion.value.qid) {
                q.HasBeenDisplayed = true;
                q.Display = true;
            } else {
                q.Display = false;
            }
        })
    );
    axios.post(`schedule/${props.schedule.ID}/question/${currentquestion.value.qid}/viewed`);
    const tokenpayload = Token.payload();
    sendWebSocket({ command: "case", exam: tokenpayload.ExamName, id: currentquestion.value.qid, template: selectedTemplate.value });
}
function sendFullScreen(mediaID, full, socket = true) {
    if (socket === true) {
        sendWebSocket({ command: "fullscreen", mediaID: full ? mediaID : null });
    }
    fullscreenmedia.value = full ? mediaID : null;
}
function setPresenting(checked) {
    presenting.value = checked;
}

/////////////////////////////////////////////////////////////////////
// blueprint specific stuff
/////////////////////////////////////////////////////////////////////
const blueprint = ref();
function loadBlueprint() {
    axios.get(`blueprint`).then((response) => {
        blueprint.value = response.data;
    });
}

function blueprintChange() {
    const cid = selectedCaseQuestionData.value.CaseID;
    const qid = selectedCaseQuestionData.value.ID;
    const bpid = selectedCaseQuestionData.value.ID_B;
    const selC = cases.value.find((c) => c.ID === cid);
    const selQ = selC.Questions.find((q) => q.ID === qid);
    // only save if there are changes in the blueprint
    if (bpid !== selQ.ID_B) {
        noteStatus.value = "saving...";
        caselistNoteSaving.value = true;
        axios
            .put(`question/${qid}/blueprint/${bpid}`)
            .then(() => {
                selQ.ID_B = bpid;
                AbogAlert.success("Blueprint updated.");
                noteStatus.value = "saved!";
                noteDebounceTimeout = setTimeout(() => {
                    noteStatus.value = "";
                }, 3000);
            })
            .catch(() => {
                selectedCaseQuestionData.value.ID_B = selQ.ID_B;
                AbogAlert.error("Something went wrong saving your blueprint!");
                noteStatus.value = "";
            })
            .finally(() => {
                caselistNoteSaving.value = false;
            });
    } else {
        noteStatus.value = "";
    }
}

/////////////////////////////////////////////////////////////////////
// caselist specific stuff
/////////////////////////////////////////////////////////////////////
const caseListInit = props.schedule.Assignment.includes("Case List");
const caselistShow = ref(caseListInit); // hide case lists behind this toggle
const caselistReview = ref(true); // special menu view for "Case List" CaseType
const caselistSortCol = ref("sel"); // sel, desc, view, cat
const caselistDisplayMode = ref("compact"); // compact, detail
const caselistFilterText = ref(""); // free text search
const caselistNoteSaving = ref(false);
const caselistComponentLookup = {
    Gynecology: { component: "PatientCaseNewborn", textFields: ["Text1", "Text2", "Text3", "Text4"] }, // OBGYN - 1
    Obstetrics: { component: "PatientCaseNewborn", textFields: ["Text1", "Text4", "Text2"] }, // OBGYN - 2
    Office: { component: "PatientCaseNewborn", textFields: ["Text1", "Text3", "Text2", "Text4"] }, // OBGYN - 3
    "Medical Complications of Pregnancy": { component: "PatientCaseNewborn", textFields: ["Text1", "Text2", "Text3"] }, // MFM - 1
    "Obstetrical and Surgical Complications": { component: "PatientCaseNewborn", textFields: ["Text1", "Text2", "Text3"] }, // MFM - 2
    "Genetics/Fetal Disorders/Fetal Anomalies": { component: "PatientCaseNewborn", textFields: ["Text1", "Text2", "Text3"] }, // MFM - 3
    "Reconstructive Surgery": { component: "PatientCase", textFields: ["Text1", "Text2", "Text3"] }, // URPS - 1
    "Office Practice": { component: "PatientCase", textFields: ["Text1", "Text2", "Text3"] }, // URPS - 2
    "Urinary Incontinence": { component: "PatientCase", textFields: ["Text1", "Text2", "Text3"] }, // URPS - 3
    "Ovarian, Peritoneal, and Fallopian Tube Cancer (including Chemotherapy)": { component: "PatientCase", textFields: ["Text1", "Text2", "Text3", "Text4"] }, // ONC - 1
    "Uterine Malignancies: Endometrial Cancer, Sarcoma, GTD, and Other": { component: "PatientCase", textFields: ["Text1", "Text2", "Text3", "Text4"] }, // ONC - 2
    "Cervical, Vulvar, and Vaginal Cancer and Radiation Therapy": { component: "PatientCase", textFields: ["Text1", "Text2", "Text3", "Text4"] }, // ONC - 3
    "Reproductive Endocrinology": { component: "PatientCase", textFields: ["Text1", "Text2", "Text3", "Text4"] }, // REI - 1
    "Reproductive Surgery": { component: "PatientCase", textFields: ["Text1", "Text2", "Text3", "Text4"] }, // REI - 2
    "Infertility/IVF": { component: "PatientCase", textFields: ["Text1", "Text2", "Text3", "Text4"] }, // REI - 3
};
const noteStatus = ref("");
const noteinput = ref(null);
let noteDebounceTimeout = null;
function caseTypeChange() {
    // reset current question on type change
    currentquestion.value = { cid: null, qid: null };
}
function caselistReviewFields(question, section) {
    // #, Viewed, Notes - are in both views
    // PatientAge, Text1, Text2, Text3, Text4, CategoryDescription - are only in review mode
    let qdata;
    try {
        qdata = JSON.parse(question);
    } catch {
        return [];
    }
    let fields = [qdata.PatientAge];
    caselistComponentLookup[section].textFields.forEach((t) => {
        fields.push(qdata[t]);
    });
    fields.push(qdata.CategoryDescription);
    return fields;
}
function caselistSelectionChange() {
    const cid = selectedCaseQuestionData.value.CaseID;
    const sel = selectedCaseQuestionData.value.Selection;
    const selC = cases.value.find((c) => c.ID === cid);
    // only save if there are changes in the selection
    if (sel !== selC.Selection) {
        noteStatus.value = "saving...";
        caselistNoteSaving.value = true;
        axios
            .put(`case/${cid}/selection`, JSON.stringify(sel))
            .then(() => {
                loadCases(); // need to refresh list becuase this may re-order other selections
                loadGrades(); // changing selections will change which cases require grading
                AbogAlert.success("Selection updated.");
                noteStatus.value = "saved!";
                noteDebounceTimeout = setTimeout(() => {
                    noteStatus.value = "";
                }, 3000);
            })
            .catch(() => {
                selectedCaseQuestionData.value.Selection = selC.Selection;
                AbogAlert.error("Something went wrong saving your selection!");
                noteStatus.value = "";
            })
            .finally(() => {
                caselistNoteSaving.value = false;
                // reset focus after input is re-enabled
            });
    } else {
        noteStatus.value = "";
    }
}
function caselistNoteKeyup() {
    noteStatus.value = "typing...";
    // will only save after the user has stopped typing
    clearTimeout(noteDebounceTimeout);
    noteDebounceTimeout = setTimeout(() => {
        caselistSaveNotes();
    }, 2000);
}
function caselistNoteChange() {
    clearTimeout(noteDebounceTimeout);
    caselistSaveNotes();
}
function caselistSaveNotes() {
    const cid = selectedCaseQuestionData.value.CaseID;
    const qid = selectedCaseQuestionData.value.ID;
    const note = selectedCaseQuestionData.value.Notes;
    const selC = cases.value.find((c) => c.ID === cid);
    const selQ = selC.Questions.find((q) => q.ID === qid);
    // only save if there are changes in the note
    if (note !== selQ.Notes) {
        noteStatus.value = "saving...";
        caselistNoteSaving.value = true;
        axios
            .put(`question/${qid}/notes`, JSON.stringify(note))
            .then(() => {
                selQ.Notes = note;
                AbogAlert.success("Note updated.");
                noteStatus.value = "saved!";
                noteDebounceTimeout = setTimeout(() => {
                    noteStatus.value = "";
                }, 1000);
            })
            .catch(() => {
                AbogAlert.error("Something went wrong saving your note!");
            })
            .finally(() => {
                caselistNoteSaving.value = false;
                // reset focus after input is re-enabled
                nextTick(() => {
                    noteinput.value.focus();
                });
            });
    } else {
        noteStatus.value = "";
    }
}
const caselistReviewMode = computed(() => {
    return caselistReview.value && caselistShow.value;
});
const hasCaseList = computed(() => {
    return cases.value.some((c) => c.CaseType === "Case List");
});

/////////////////////////////////////////////////////////////////////
// websocket stuff
/////////////////////////////////////////////////////////////////////
const client = new WebPubSubClient({
    getClientAccessUrl: async () => {
        return (await axios.get("authentication/pubsub")).data;
    },
});
// const ws = ref(null);

function connectWebSocket() {
    client.start();
    // axios.get("authentication/pubsub").then((result) => {
    //     ws.value = new WebSocket(result.data, "json.webpubsub.azure.v1");
    // });
}

function sendWebSocket(wsdata) {
    if (!presenting.value) return; // don't send messages if we aren't presenting
    client.sendToGroup(props.schedule.RoomNumber, wsdata, "json");
    // ws.value.send(
    //     JSON.stringify({
    //         type: "sendToGroup", // if we don't want to join a room
    //         group: props.schedule.RoomNumber,
    //         dataType: "json",
    //         data: wsdata,
    //     })
    // );
}

function closeWebSocket() {
    client.stop();
    // if (ws.value) ws.value.close();
}

/////////////////////////////////////////////////////////////////////
// grading stuff
/////////////////////////////////////////////////////////////////////
const gradeKeys = ref([]);
const grades = ref([]);
const submitted = computed(() => props.schedule.GradesSubmittedOn !== null);
const filteredGrades = computed(() => {
    return grades.value.filter((g) => {
        if (g.CaseID === currentquestion.value.cid) {
            if (g.CaseQuestionID === currentquestion.value.qid) return true;
            const thiscase = cases.value.find((c) => c.ID === g.CaseID);
            const maxquestionindex = thiscase.Questions.reduce((maxidx, q) => (q.Index > maxidx ? q.Index : maxidx), 0);
            const lastquestion = thiscase.Questions.find((q) => q.Index === maxquestionindex);
            if (currentquestion.value.qid === lastquestion.ID && g.CaseQuestionID === null) return true;
        }
        return false;
    });
});
function loadGrades() {
    axios.get("grades/scale").then((response) => {
        gradeKeys.value = response.data;
    });
    axios.get(`schedule/${props.schedule.ID}/grades`).then((response) => {
        // sort by id, with nulls at bottom
        response.data.sort((a, b) => {
            if (a.CaseID === null && b.CaseID !== null) return 1;
            if (a.CaseID !== null && b.CaseID === null) return -1;
            if (a.CaseID > b.CaseID) return 1;
            if (a.CaseID < b.CaseID) return -1;
            if (a.CaseQuestionID === null && b.CaseQuestionID !== null) return 1;
            if (a.CaseQuestionID !== null && b.CaseQuestionID === null) return -1;
            if (a.CaseQuestionID > b.CaseQuestionID) return 1;
            if (a.CaseQuestionID < b.CaseQuestionID) return -1;
            return 0;
        });
        // init UI flags
        response.data.forEach((g) => {
            g.firstCaseRow = false; // not needed for inline grading
            g.caseColor = false;
            g.hasErrors = false;
            g.isSaving = false;
        });
        grades.value = response.data;
    });
}
function saveGrade(grade) {
    const gradeToUpdate = grades.value.find((g) => g.CaseID === grade.CaseID && g.CaseQuestionID === grade.CaseQuestionID);
    if (!gradeToUpdate) {
        AbogAlert.error("Unable to update grade.");
        return;
    }
    gradeToUpdate.hasErrors = false;
    gradeToUpdate.isSaving = true;
    axios
        .put(`schedule/${props.schedule.ID}/grade`, grade)
        .then(() => {
            gradeToUpdate.Score = grade.Score;
            gradeToUpdate.Notes = grade.Notes;
        })
        .catch((error) => {
            AbogAlert.error(error.response.data);
            gradeToUpdate.hasErrors = true;
            // gradeToUpdate.Score = null;
            // gradeToUpdate.Note = "";
        })
        .finally(() => {
            gradeToUpdate.isSaving = false;
        });
}
</script>
<style scoped>
.glow {
    /* #3fb2a4 = aboggreen-300 */
    text-shadow: #3fb2a4 0 0 5px;
}

.expand-h-leave-active,
.expand-h-enter-active {
    transition: all 0.5s ease-in-out;
    overflow: hidden;
}
.expand-h-enter-from,
.expand-h-leave-to {
    /* transform: scaleY(0); */
    max-height: 0;
}
.expand-h-enter-to,
.expand-h-leave-from {
    /*max-height causes a delay in elements less than this height and a snap in elements greater*/
    max-height: 250px;
}

.expand-w-leave-active,
.expand-w-enter-active {
    transition: all 0.5s ease-in-out;
}
.expand-w-enter-from,
.expand-w-leave-to {
    width: 0;
}
.expand-w-enter-to,
.expand-w-leave-from {
    width: 75%;
}

.slide-w {
    transition: width 0.5s ease-in-out;
}

.fade-gradient-right {
    position: relative;
    mix-blend-mode: hard-light;
}
.fade-gradient-right::after {
    position: absolute;
    content: "";
    right: 0px;
    height: 100%;
    width: 10%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
}
</style>
