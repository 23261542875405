<template>
    <!-- <div>{{ formattedCountDown }}</div> -->
    <div v-if="examstate === examstates.loading">Loading Exam Timer...</div>
    <div v-else-if="examstate === examstates.before" class="text-abogyellow-600">Exam starts in {{ formattedCountDown }}</div>
    <div v-else-if="examstate === examstates.during">Exam ends in {{ formattedCountDown }}</div>
    <div v-else class="text-abogred-600">Exam Completed</div>
</template>
<script setup>
import { ref, computed, watch, onMounted, onUnmounted, inject } from "vue";
import AbogDate from "@/pages/components/AbogDate";
const emit = defineEmits(["timerCompleted"]);
const props = defineProps(["id_sb"]);
const axios = inject("axios");
const examstates = { loading: 0, before: 1, during: 2, after: 3 };
const countdown = ref(0); // milliseconds remaining
const examstate = ref(examstates.loading); // 0: loading, 1: before: 2: during, 3: after
let resync = 270 + Math.floor(Math.random() * 60); // counter to resync the timer every 5 minutes with some rng
let serveroffset = 0; // difference between server time and local computer time
let timerid = null; // store the setTimeout id for disposal
let duedate = new AbogDate();

const formattedCountDown = computed(() => {
    const h = parseInt(countdown.value / (60 * 60 * 1000)),
        m = parseInt((countdown.value / (60 * 1000)) % 60),
        s = parseInt((countdown.value / 1000) % 60);
    return `${h}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
});
watch(props, () => {
    syncTimer();
});
onMounted(() => {
    syncTimer();
});
onUnmounted(() => {
    clearTimeout(timerid);
});
function syncTimer() {
    const callstart = new AbogDate();
    axios
        .get(`schedule/${props.id_sb}/timer`)
        .then((response) => {
            const callend = new AbogDate();
            const callduration = callend - callstart;
            const pingoffset = (callduration - response.data.ProcessingTime) / 2;
            // ping offset should account for the time it took the response to travel from the server
            const serverdate = new AbogDate(response.data.ServerDate);
            serveroffset = serverdate - callend + pingoffset;
            // server offset should be the difference between the client date and the server date
            const startdate = new AbogDate(response.data.StartDate);
            const enddate = new AbogDate(response.data.EndDate);
            // store the client end date and then countdown to that date locally
            if (serverdate < startdate) {
                examstate.value = examstates.before;
                duedate = startdate;
                startCountdown();
            } else if (serverdate > enddate) {
                examstate.value = examstates.after;
                countdown.value = 0;
            } else {
                examstate.value = examstates.during;
                duedate = enddate;
                startCountdown();
            }
        })
        .catch(() => {
            // sync errors aren't breaking, so keep timer running
            if (examstate.value !== examstates.loading) startCountdown();
        });
}

function startCountdown() {
    resync--;
    countdown.value = duedate - new AbogDate() - serveroffset;
    const timeout = countdown.value % 1000;
    if (resync <= 0) {
        resync = 270 + Math.floor(Math.random() * 60);
        syncTimer();
    } else if (countdown.value > 0) {
        timerid = setTimeout(() => {
            startCountdown();
        }, timeout);
    } else {
        if (examstate.value === examstates.before) {
            examstate.value = examstates.loading;
            syncTimer();
            emit("timerCompleted");
        } else if (examstate.value === examstates.during) {
            playAlarm();
            examstate.value = examstates.loading;
            syncTimer();
            emit("timerCompleted");
        } else {
            examstate.value = examstates.loading;
        }
    }
}

function playAlarm() {
    Promise.resolve()
        .then(() => beep(150, 294))
        .then(() => beep(150, 392))
        .then(() => beep(150, 294))
        .then(() => beep(450, 392))
        .then(() => beep(150, 294))
        .then(() => beep(150, 392))
        .then(() => beep(150, 294))
        .then(() => beep(450, 392));
}
function beep(duration, frequency, volume) {
    const myAudioContext = new AudioContext();
    return new Promise((resolve, reject) => {
        // Set default duration if not provided
        duration = duration || 200;
        frequency = frequency || 440;
        volume = volume || 100;

        try {
            let oscillatorNode = myAudioContext.createOscillator();
            let gainNode = myAudioContext.createGain();
            oscillatorNode.connect(gainNode);

            // Set the oscillator frequency in hertz
            oscillatorNode.frequency.value = frequency;

            // Set the type of oscillator
            oscillatorNode.type = "sine"; // sine, square, sawtooth, triangle
            gainNode.connect(myAudioContext.destination);

            // Set the gain to the volume
            gainNode.gain.value = volume * 0.01;

            // Start audio with the desired duration
            oscillatorNode.start(myAudioContext.currentTime);
            oscillatorNode.stop(myAudioContext.currentTime + duration * 0.001);

            // Resolve the promise when the sound is finished
            oscillatorNode.onended = () => {
                resolve();
            };
        } catch (error) {
            reject(error);
        }
    });
}
</script>
