<template>
    <div>
        <BreadCrumb class="inline-block" :links="[{ Url: '/', Name: 'Schedule' }]" />
        <span class="select-arrow">
            <select :value="route.name" class="font-bold text-abogblue-300" @change="goToSchedule">
                <option class="text-aboggray-700 font-normal" value="Cases">Cases</option>
                <option class="text-aboggray-700 font-normal" value="Grades">Grades</option>
                <option class="text-aboggray-700 font-normal" value="Attestation">Attestation</option>
            </select>
        </span>
        <div v-if="schedule.ID" class="md:flex md:justify-between mb-4 bg-aboggray-100 p-2 text-lg sticky top-0 z-40">
            <div class="m-2">
                Candidate:
                {{ schedule.CandidateName }}
            </div>
            <div class="m-2">{{ schedDesc }}</div>
            <div class="m-2"><AbogTimer :id_sb="schedule.ID" /></div>
            <div class="m-2 whitespace-nowrap">
                <div v-if="currentblock" class="whitespace-normal sm:float-right md:inline-block md:float-none">
                    <i
                        class="text-2xl cursor-pointer md:mr-4 lg:mr-12"
                        title="Message Options"
                        :class="showHelpMenu ? 'fas fa-times' : !helpMessagePending ? 'fa-solid fa-triangle-exclamation' : helpMessagePending.ID_HMS == 1 ? 'fa-solid fa-person text-abogred-600' : 'fa-solid fa-person text-abogyellow-400'"
                        @click="helpMenuSwitch()"
                    />
                    <Transition name="slide-fade">
                        <div v-if="showHelpMenu" class="absolute w-44 h-56 rounded-b-lg z-40 bg-aboggray-100 sm:right-0 md:mt-8 lg:mt-4 lg:right-16">
                            <div class="float-right"></div>
                            <div v-if="!helpMessagePending" class="text-center items-center">
                                <div class="inline-block font-bold">What is the Issue?</div>
                                <button v-for="option in filteredOptions" @click="writeMessage(option.ID_HMO)" :key="option.ID_HMO" class="w-32 blue-button mt-3">
                                    {{ option.Options }}
                                </button>
                            </div>
                            <div v-if="helpMessagePending" class="text-center items-center">
                                <div class="inline-block">Need to Cancel?</div>
                                <button v-for="option in filteredOptions" @click="cancelMessage(option.ID_HMO)" :key="option.ID_HMO" class="blue-button mt-3">{{ option.Options }}</button>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div class="inline-block">Room: {{ schedule.RoomNumber }}</div>
            </div>
        </div>
        <RouterView v-if="schedule.ID" :schedule="schedule" />
    </div>
    <div v-if="showHelpMenu" class="fixed left-0 top-0 w-full h-full" @click="showHelpMenu = false"></div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, inject } from "vue";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import { useRoute, useRouter } from "vue-router";
import { useExamStore } from "@/pages/components/ExamStore";
import AbogDate from "@/pages/components/AbogDate";
import AbogTimer from "@/pages/components/AbogTimer";
import BreadCrumb from "@/pages/components/BreadCrumb";
import AbogAlert from "../components/AbogAlert";
import Token from "@/token";

// inject globals
const axios = inject("axios");
const route = useRoute();
const router = useRouter();
const exam = useExamStore();
// data
const schedule = ref({});
let helpMessageOptions = ref([]);
let helpMessagePending = ref();

const showHelpMenu = ref(false);

let currentblock = ref(false);

const id_sb = computed(() => route.params.id_sb);
const schedDesc = computed(() => {
    if (schedule.value && schedule.value.Date) {
        const date = new AbogDate(schedule.value.Date).toABOGDateString();
        return `${date} ${schedule.value.StartTime} - ${schedule.value.Assignment}`;
    }
    return "";
});
const filteredOptions = computed(() => {
    if (!helpMessagePending.value) {
        return helpMessageOptions.value.filter((obj) => {
            return obj.ID_HMO !== 4;
        });
    } else {
        return helpMessageOptions.value.filter((obj) => {
            return obj.ID_HMO == 4;
        });
    }
});
onMounted(() => {
    loadSchedule();
    loadMessageOptions();
});
onUnmounted(() => {
    closeWebSocket();
});

function helpMenuSwitch() {
    showHelpMenu.value = !showHelpMenu.value;
}

function loadSchedule() {
    Promise.all([axios.get(`schedule/${id_sb.value}`), axios.get(`schedule/${id_sb.value}/timer`)]).then((results) => {
        schedule.value = results[0].data;
        const now = new AbogDate(results[1].data.ServerDate);
        const scheddate = new AbogDate(schedule.value.Date).toDateString();
        const localdate = now.toDateString();
        const schedtime = schedule.value.StartTime;
        const timeoffset = 60 * 60 * 1000; // 1 hour = 60 min * 60 sec * 1000 ms
        const localtime = new AbogDate(now.getTime() + timeoffset).toLocaleTimeString("en-GB"); // HH:mm
        currentblock.value = results[1].data.StartDate.replace(/\D/g, "") < results[1].data.ServerDate.replace(/\D/g, "") && results[1].data.ServerDate.replace(/\D/g, "") < results[1].data.EndDate.replace(/\D/g, "");
        if (scheddate === localdate && schedtime < localtime) checkCandidateAttestation();
        connectWebSocket();
        loadCurrentMessage();
    });
}
function goToSchedule(event) {
    router.push({ name: event.target.value });
}
function checkCandidateAttestation() {
    if (schedule.value.CandidateID) {
        axios.get(`candidate/${schedule.value.CandidateID}`).then((response) => {
            if (response.data.AttestationDate === null) {
                router.push({ name: "Attestation" });
            }
        });
    }
}
function loadMessageOptions() {
    axios.get("HelpMessages/options").then((response) => {
        helpMessageOptions.value = response.data;
    });
}
function loadCurrentMessage() {
    axios.get(`HelpMessages/${exam.UserName}/${schedule.value.RoomNumber}`).then((response) => {
        helpMessagePending.value = response.data;
    });
}

const client = new WebPubSubClient({
    getClientAccessUrl: async () => {
        return (await axios.get("authentication/pubsub")).data;
    },
});

function writeMessage(message) {
    let data = {
        ID_HMO: message,
        ID_HMS: 1,
        FullName: exam.UserName,
        RoomNumber: schedule.value.RoomNumber,
    };
    const tokenpayload = Token.payload();
    axios.post("HelpMessage", data).then(() => {
        showHelpMenu.value = false;
        sendWebSocket({ command: "message", exam: tokenpayload.ExamName, room: data.RoomNumber, FullName: data.FullName });
        loadCurrentMessage();
    });
}

function cancelMessage(message) {
    let data = JSON.parse(JSON.stringify(helpMessagePending.value));
    data.ID_HMS = message;
    const tokenpayload = Token.payload();
    axios.post("HelpMessage", data).then(() => {
        showHelpMenu.value = false;
        sendWebSocket({ command: "canceled", exam: tokenpayload.ExamName, room: data.RoomNumber, FullName: data.FullName });
        loadCurrentMessage();
    });
}

function sendWebSocket(wsdata) {
    client.sendToGroup("admin", wsdata, "json");
}

function connectWebSocket() {
    client.start();
    client.on("group-message", (event) => {
        const wsdata = event.message.data;
        if (wsdata.command == "1") {
            AbogAlert.success("Request Pending");
            loadCurrentMessage();
        } else if (wsdata.command == "2") {
            AbogAlert.success("Assistance is on the way");
            loadCurrentMessage();
        } else if (wsdata.command == "3") {
            AbogAlert.success("Issue Resolved");
            loadCurrentMessage();
        }
    });
    client.joinGroup(exam.UserName.concat(" ", schedule.value.RoomNumber));
}
function closeWebSocket() {
    client.stop();
}
</script>
