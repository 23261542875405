<template>
    <ol class="switches">
        <li>
            <input type="checkbox" :id="randid" :checked="checked" @input="inputhandler" />
            <label :for="randid">
                <span>{{ label }}</span>
                <span></span>
            </label>
        </li>
    </ol>
</template>

<script>
export default {
    name: "AbogSwitch",
    props: ["label", "checked"],
    computed: {
        randid() {
            return Math.random().toString(36).substring(2);
        },
    },
    methods: {
        inputhandler(event) {
            this.$emit("checked", event.target.checked);
        },
    },
};
</script>

<style scoped>
ol {
    list-style: none;
}

label {
    cursor: pointer;
}

[type="checkbox"] {
    position: absolute;
    left: -9999px;
}

.switches label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.switches span:last-child {
    position: relative;
    width: 50px;
    height: 26px;
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
    background: #999;
    transition: all 0.3s;
}

.switches span:last-child::before,
.switches span:last-child::after {
    content: "";
    position: absolute;
}

.switches span:last-child::before {
    left: 1px;
    top: 1px;
    width: 24px;
    height: 24px;
    background: #e8e9ed;
    border-radius: 50%;
    z-index: 1;
    transition: transform 0.3s;
}

.switches span:last-child::after {
    top: 50%;
    right: 8px;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/uncheck-switcher.svg);
    background-size: 12px 12px;
}

.switches [type="checkbox"]:checked + label span:last-child {
    @apply bg-abogblue-200;
}

.switches [type="checkbox"]:checked + label span:last-child::before {
    transform: translateX(24px);
}

.switches [type="checkbox"]:checked + label span:last-child::after {
    width: 14px;
    height: 14px;
    right: auto;
    left: 8px;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/checkmark-switcher.svg);
    background-size: 14px 14px;
}

@media screen and (max-width: 600px) {
    .switches li::before {
        display: none;
    }
}
</style>
