<template>
    <ckeditor :editor="ClassicEditor" :config="editorConfig" />
</template>
<script setup>
// v-model will come from parent as a "fallthrough attribute"
import CKEditor from "@ckeditor/ckeditor5-vue";
import { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic";

import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { Bold, Italic, Underline, Superscript, Subscript } from "@ckeditor/ckeditor5-basic-styles";
// import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
// import { CloudServices } from "@ckeditor/ckeditor5-cloud-services";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import { Font } from "@ckeditor/ckeditor5-font";
// import { Heading } from "@ckeditor/ckeditor5-heading";
// import { Image, ImageCaption, ImageStyle, ImageToolbar, ImageUpload } from "@ckeditor/ckeditor5-image";
import { Indent, IndentBlock } from "@ckeditor/ckeditor5-indent";
// import { Link } from "@ckeditor/ckeditor5-link";
import { List } from "@ckeditor/ckeditor5-list";
// import { MediaEmbed } from "@ckeditor/ckeditor5-media-embed";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import { PasteFromOffice } from "@ckeditor/ckeditor5-paste-from-office";
import { Table, TableToolbar } from "@ckeditor/ckeditor5-table";
import { TextTransformation } from "@ckeditor/ckeditor5-typing";

const ckeditor = CKEditor.component;

const editorConfig = {
    plugins: [
        Autoformat,
        // BlockQuote,
        Bold,
        // CloudServices,
        Essentials,
        Font,
        // Heading,
        // Image,
        // ImageCaption,
        // ImageStyle,
        // ImageToolbar,
        // ImageUpload,
        Indent,
        Superscript,
        Subscript,
        IndentBlock,
        Italic,
        // Link,
        List,
        // MediaEmbed,
        Paragraph,
        PasteFromOffice,
        Table,
        TableToolbar,
        TextTransformation,
        Underline,
    ],
    toolbar: {
        items: ["bold", "italic", "underline", "|", "fontSize", "fontColor", "|", "bulletedList", "numberedList", "|", "outdent", "indent", "superscript", "subscript", "|", "blockQuote", "insertTable", "undo", "redo"],
    },
    language: "en",
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    fontColor: {
        colorPicker: false,
        documentColors: false,
        colors: [
            { label: "Blue", color: "#2e6498" },
            { label: "Green", color: "#1e8688" },
            { label: "Red", color: "#ac3535" },
            { label: "Yellow", color: "#9e6f00" },
        ],
    },
};
</script>
