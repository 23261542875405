<template>
    <div>
        <h2 class="font-bold text-2xl mb-4">Blueprint</h2>
        <button class="blue-button mr-2" @click="showEditDialog(true)">
            <i class="fa fa-plus-circle" />
            Add
        </button>
        <button class="blue-button mx-2" :disabled="!currentBlueprint" @click="showEditDialog(false)">
            <i class="fa fa-pencil" />
            Edit
        </button>
        <button class="blue-button mx-2" :disabled="!currentBlueprint" @click="deleteBlueprint()">
            <i class="fa-solid fa-trash" />
            Delete
        </button>
        <label class="blue-button inline-block cursor-pointer select-none mx-2">
            <i class="fa-solid fa-file-import" />
            Import
            <input type="file" accept=".csv" @change="importBlueprint" hidden :disabled="importDialog.importing" />
        </label>
        <div class="my-4">
            <AbogTable :data="blueprint" :columns="tableColumns" initialKey="ID" @selected="selectBlueprint" />
        </div>
        <!-- edit dialog -->
        <AbogEditDialog v-if="editDialog.show" :isAdd="editDialog.isAdd" entity="Candidate" @close="closeEditDialog" :saveCallback="saveBlueprint">
            <div class="mb-2">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">Full Name</span>
                    <input type="text" v-model="currentBlueprint.Description" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
            <div class="mb-2">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">ID</span>
                    <input type="text" v-model.trim="currentBlueprint.ExternalID" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
        </AbogEditDialog>
        <!-- import dialog -->
        <AbogModal :title="importDialog.title" v-if="importDialog.show" :width="40" @close="closeImportDialog()">
            <label v-if="importDialog.importing" class="flex items-center justify-center whitespace-nowrap my-6">
                Importing...
                <AbogLoading class="w-12 mx-2" />
            </label>
            <div v-if="!importDialog.importing">
                <div class="mb-2 whitespace-pre-wrap">
                    {{ importDialog.message }}
                </div>
                <div class="text-right py-4">
                    <button @click="closeImportDialog()" class="blue-button mx-2">OK</button>
                </div>
            </div>
        </AbogModal>
    </div>
</template>
<script setup>
import { inject, ref, onMounted } from "vue";
import AbogLoading from "@/pages/components/AbogLoading";
import AbogModal from "@/pages/components/AbogModal";
import AbogTable from "@/pages/components/AbogTable";
import AbogEditDialog from "@/pages/components/AbogEditDialog";
import AbogAlert from "@/pages/components/AbogAlert";
const axios = inject("axios");
const blueprint = ref([]);
const currentBlueprint = ref(null);
const editDialog = ref({ show: false, isAdd: false });
const importDialog = ref({ show: false, importing: false, title: "", message: "" });
const tableColumns = [
    { prop: "Description", display: "Description", align: "left" },
    { prop: "ExternalID", display: "ID", align: "left" },
    { prop: "ID", hide: true, key: true },
];

onMounted(() => {
    loadBlueprint();
});

function loadBlueprint() {
    axios.get("blueprint").then((response) => {
        blueprint.value = response.data;
    });
}

function selectBlueprint(id) {
    currentBlueprint.value = blueprint.value.find((e) => e.ID === id);
}

function showEditDialog(add) {
    if (add) currentBlueprint.value = { ID: 0, FullName: "", ExternalID: "" };
    editDialog.value = { show: true, isAdd: add };
}

function closeEditDialog() {
    editDialog.value = { show: false, isAdd: false };
}

async function saveBlueprint() {
    let result = null;
    await axios
        .put("blueprint", currentBlueprint.value)
        .then((response) => {
            loadBlueprint();
            currentBlueprint.value = response.data;
        })
        .catch((error) => {
            result = error.response.data;
        });
    return result;
}

function deleteBlueprint() {
    axios.get(`blueprint/${currentBlueprint.value.ID}/assignedquestioncount`).then((response) => {
        const caseCount = response.data;
        const msg =
            caseCount === 0
                ? `Delete blueprint "${currentBlueprint.value.Description}?"`
                : `Blueprint "${currentBlueprint.value.Description}" is assigned to ${caseCount} cases.
                Deleting it will permanently remove it from all cases. Continue?`;
        AbogAlert.confirm(msg).then(() => {
            axios
                .delete(`blueprint/${currentBlueprint.value.ID}`)
                .then(() => {
                    loadBlueprint();
                })
                .catch((error) => {
                    console.log("Err");
                    console.log(error);
                    AbogAlert.error(error.response.data);
                });
        });
    });
}

function closeImportDialog() {
    importDialog.value = { show: false, importing: false, title: "", message: "" };
}

function importBlueprint(e) {
    importDialog.value = { show: true, importing: true, title: "Import Blueprint", message: "" };
    const file = e.target.files[0];
    axios
        .post("ImportBlueprint", file)
        .then((response) => {
            let titleMessage = "";
            switch (response.data.Result) {
                case 0:
                    titleMessage = "Blueprint Import Success!";
                    break;
                case 1:
                    titleMessage = "Blueprint Import Completed with Errors";
                    break;
                case 2:
                    titleMessage = "Blueprint Import Failed";
                    break;
            }
            importDialog.value = { show: true, importing: false, title: titleMessage, message: response.data.Message };
            e.target.value = null;
            loadBlueprint();
        })
        .catch((error) => {
            importDialog.value = { show: true, importing: false, title: "Error", message: error.response.data };
        });
}
</script>
