<template>Logging you in...</template>
<script setup>
import { onMounted, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useExamStore } from "@/pages/components/ExamStore";
import Token from "@/token";
const axios = inject("axios");
const router = useRouter();
const route = useRoute();
const exam = useExamStore();
const emits = defineEmits(["login"]);

onMounted(() => {
    const token = route.query.t;
    router.replace({ query: null });
    if (token) {
        Token.set(token);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        exam.refresh();
        emits("login");
        // router.push("/");
        router.replace("/");
    } else {
        // router.push("/login");
        router.replace("/login");
    }
});
</script>
