<template>
    <div class="flex justify-between items-center whitespace-nowrap">
        <span class="w-full" :class="loading ? 'opacity-50 pointer-events-none' : 'cursor-pointer'" @click="downloadFile">{{ props.file.FileName }}</span>
        <AbogLoading v-if="loading" class="w-6" />
        <i v-else-if="props.showdelete" :class="loading ? 'opacity-50 pointer-events-none' : 'cursor-pointer'" class="fa fa-xmark w-6 text-center p-1" @click="deleteFile(doc)" />
    </div>
</template>
<script setup>
import { inject, ref } from "vue";
import AbogLoading from "@/pages/components/AbogLoading";
import AbogAlert from "../components/AbogAlert";
const axios = inject("axios");
const props = defineProps(["file", "showdelete"]);
const emit = defineEmits(["deleted"]);
const loading = ref(false);
function downloadFile() {
    if (!loading.value) {
        loading.value = true;
        axios
            .get(`document/${props.file.ID}`, { responseType: "blob" })
            .then((response) => {
                const a = document.createElement("a");
                a.href = window.URL.createObjectURL(response.data);
                a.download = `${props.file.FileName}.${props.file.Extension}`;
                a.click();
            })
            .catch(() => {
                alert("Something went wrong.");
            })
            .finally(() => (loading.value = false));
    }
}

function deleteFile() {
    if (loading.value) return;
    AbogAlert.confirm(`Delete the file ${props.file.FileName}.${props.file.Extension}?`).then(() => {
        loading.value = true;
        axios
            .delete(`document/${props.file.ID}`)
            .then(() => {
                emit("deleted", props.file.ID);
            })
            .catch(() => {
                alert("Something went wrong.");
            })
            .finally(() => (loading.value = false));
    });
}
</script>
