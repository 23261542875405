<template>
    <div class="w-full flex justify-center">
        <form @submit.prevent="updatePassword">
            <h2 class="w-full font-head text-2xl mb-2">Change Your Password</h2>
            <label>
                <span class="w-full block mb-2">Enter your new password</span>
                <input v-model="password1" type="password" class="w-full border px-4 py-2 block mb-2" />
            </label>
            <label>
                <span class="w-full block mb-2">Re-enter the password</span>
                <input v-model="password2" type="password" class="w-full border px-4 py-2 block mb-2" />
            </label>
            <input type="submit" class="w-full purple-button mb-2" :disabled="!password1 || !password2" value="Save" />
        </form>
    </div>
</template>
<script setup>
import { inject, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
const axios = inject("axios");
const route = useRoute();
const router = useRouter();
const password1 = ref("");
const password2 = ref("");

// the special "password reset token" should be in a query parameter called t
const token = computed(() => {
    return route.query.t.replace(/ /g, "+"); // browser converts '+' to ' '
});

function updatePassword() {
    if (password1.value != password2.value) {
        alert("Your passwords do not match");
        return;
    }
    delete axios.defaults.headers.common["Authorization"];
    axios.defaults.headers.common["Authorization"] = "Bearer " + token.value;
    let bodydata = { NewPassword: password1.value };
    axios
        .put("Authentication/Password", bodydata)
        .then(() => {
            alert("Your password has been successfully updated.");
            router.push("/login");
        })
        .catch((error) => {
            if (error.response.status === 401) {
                alert("Your reset link is not valid.");
            } else {
                alert("Something went wrong.");
            }
        });
}
</script>
