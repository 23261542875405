<template>
    <div>
        <h2 class="font-bold text-2xl mb-4">Candidates</h2>
        <button class="blue-button mr-2" @click="showEditDialog(true)">
            <i class="fa fa-plus-circle" />
            Add
        </button>
        <button class="blue-button mx-2" :disabled="!currentCandidate" @click="showEditDialog(false)">
            <i class="fa fa-pencil" />
            Edit
        </button>
        <button class="blue-button mx-2" :disabled="!currentCandidate" @click="deleteCandidate()">
            <i class="fa-solid fa-trash" />
            Delete
        </button>
        <button class="blue-button mx-2" :disabled="!currentCandidate || !currentCandidate.AttestationDate" @click="resetAttestation()">
            <i class="fa-solid fa-rotate-left" />
            Reset Attestation
        </button>
        <label class="blue-button inline-block cursor-pointer select-none mx-2">
            <i class="fa-solid fa-file-import" />
            Import
            <input type="file" accept=".csv" @change="importCandidates" hidden :disabled="importDialog.importing" />
        </label>
        <div class="my-4">
            <AbogTable :data="candidates" :columns="tableColumns" initialKey="ID" @selected="selectCandidate" />
        </div>
        <!-- edit dialog -->
        <AbogEditDialog v-if="editDialog.show" :isAdd="editDialog.isAdd" entity="Candidate" @close="closeEditDialog" :saveCallback="saveCandidate">
            <div class="mb-2">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">Full Name</span>
                    <input type="text" v-model="currentCandidate.FullName" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
            <div class="mb-2">
                <label>
                    <span class="block text-left ml-5 md:inline-block w-11/12 whitespace-nowrap">ID</span>
                    <input type="text" v-model.trim="currentCandidate.ExternalID" class="w-11/12 ml-4 mr-4 border px-4 py-2" />
                </label>
            </div>
        </AbogEditDialog>
        <!-- import dialog -->
        <AbogModal :title="importDialog.title" v-if="importDialog.show" :width="40" @close="closeImportDialog()">
            <label v-if="importDialog.importing" class="flex items-center justify-center whitespace-nowrap my-6">
                Importing...
                <AbogLoading class="w-12 mx-2" />
            </label>
            <div v-if="!importDialog.importing">
                <div class="mb-2 whitespace-pre-wrap">
                    {{ importDialog.message }}
                </div>
                <div class="text-right py-4">
                    <button @click="closeImportDialog()" class="blue-button mx-2">OK</button>
                </div>
            </div>
        </AbogModal>
    </div>
</template>
<script setup>
import { inject, ref, onMounted } from "vue";
import AbogLoading from "@/pages/components/AbogLoading";
import AbogModal from "@/pages/components/AbogModal";
import AbogTable from "@/pages/components/AbogTable";
import AbogEditDialog from "@/pages/components/AbogEditDialog";
import AbogAlert from "../components/AbogAlert";
import AbogDate from "@/pages/components/AbogDate";
const axios = inject("axios");
const candidates = ref([]);
const currentCandidate = ref(null);
const editDialog = ref({ show: false, isAdd: false });
const importDialog = ref({ show: false, importing: false, title: "", message: "" });
const tableColumns = [
    { prop: "FullName", display: "Name", align: "left" },
    { prop: "ExternalID", display: "ID", align: "left" },
    { prop: "AttestationDate", display: "Attested On", align: "left" },
    { prop: "ID", hide: true, key: true },
];

onMounted(() => {
    loadCandidates();
});

function loadCandidates() {
    axios.get("candidates").then((response) => {
        candidates.value = response.data;
        candidates.value.forEach((e) => {
            if (e.AttestationDate !== null) {
                e.AttestationDate = new AbogDate(e.AttestationDate).toABOGDateTimeString();
            }
        });
    });
}

function selectCandidate(id) {
    currentCandidate.value = candidates.value.find((e) => e.ID === id);
}

function showEditDialog(add) {
    if (add) currentCandidate.value = { ID: 0, FullName: "", ExternalID: "" };
    editDialog.value = { show: true, isAdd: add };
}

function closeEditDialog() {
    editDialog.value = { show: false, isAdd: false };
}

async function saveCandidate() {
    let result = null;
    await axios
        .put("candidate", currentCandidate.value)
        .then((response) => {
            loadCandidates();
            currentCandidate.value = response.data;
        })
        .catch((error) => {
            result = error.response.data;
        });
    return result;
}

function resetAttestation() {
    AbogAlert.confirm("Reset the attestation for " + currentCandidate.value.FullName + "?").then(() => {
        axios
            .delete(`candidate/${currentCandidate.value.ID}/attestation`)
            .then(() => {
                loadCandidates();
            })
            .catch((error) => {
                alert(error.response.data);
            });
    });
}

function deleteCandidate() {
    axios.get(`candidate/${currentCandidate.value.ID}/assignedblockcount`).then((response) => {
        const blockCount = response.data;
        const msg =
            blockCount === 0
                ? `Delete candidate ${currentCandidate.value.FullName}?`
                : `Candidate ${currentCandidate.value.FullName} is assigned to ${blockCount} schedule blocks.
                Deleting them will permanently remove them from the schedule. Continue?`;
        AbogAlert.confirm(msg).then(() => {
            console.log("Deleting...");
            axios
                .delete(`candidate/${currentCandidate.value.ID}`)
                .then(() => {
                    console.log("deleted.");
                    loadCandidates();
                })
                .catch((error) => {
                    console.log("Err");
                    console.log(error);
                    AbogAlert.error(error.response.data);
                });
        });
    });
}

function closeImportDialog() {
    importDialog.value = { show: false, importing: false, title: "", message: "" };
}

function importCandidates(e) {
    importDialog.value = { show: true, importing: true, title: "Import Candidates", message: "" };
    const file = e.target.files[0];
    axios
        .post("ImportCandidates", file)
        .then((response) => {
            let titleMessage = "";
            switch (response.data.Result) {
                case 0:
                    titleMessage = "Candidate Import Success!";
                    break;
                case 1:
                    titleMessage = "Candidate Import Completed with Errors";
                    break;
                case 2:
                    titleMessage = "Candidate Import Failed";
                    break;
            }
            importDialog.value = { show: true, importing: false, title: titleMessage, message: response.data.Message };
            e.target.value = null;
            loadCandidates();
        })
        .catch((error) => {
            importDialog.value = { show: true, importing: false, title: "Error", message: error.response.data };
        });
}
</script>
